import Header from '@editorjs/header';
import Code from '@editorjs/code';
import InlineCode from '@editorjs/inline-code';
import Marker from '@editorjs/marker';
import Underline from '@editorjs/underline';
import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
import Checklist from '@editorjs/checklist';

import ImageTool from '@editorjs/image';
import NestedList from '@editorjs/nested-list';
import Paragraph from 'editorjs-paragraph-with-alignment';
import { imageUpload } from '@/data/services/imageService';
import { ImageFolderEnum } from '@/models/CkEditor';
import { useTranslation } from 'react-i18next';

type EditorTools = Omit<UploadByFile, 'file'>;

export type UploadByFile = {
  file: string | Blob;
  extraBody?: object;
  folder?: ImageFolderEnum;
  readOnly?: boolean;
};

export const useEditorJSTools = ({
  extraBody,
  folder = ImageFolderEnum.BOOK,
  readOnly,
}: EditorTools) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'editorJs',
  });
  async function uploadByFile(file: string | Blob) {
    const { image } = await imageUpload({
      file,
      folder,
      extraBody,
    });
    return {
      success: 1,
      file: {
        url: image,
      },
    };
  }

  return {
    header: {
      class: Header,
      inlineToolbar: true,
      config: {
        placeholder: !readOnly ? t('placeholders.header') : undefined,
        levels: [1, 2, 3, 4, 5, 6],
        defaultLevel: 5,
      },
    },
    paragraph: {
      class: Paragraph,
      inlineToolbar: true,
      config: {
        placeholder: !readOnly ? t('placeholders.paragraph') : undefined,
        preserveBlank: true,
      },
    },

    image: {
      class: ImageTool,
      inlineToolbar: true,
      config: {
        uploader: {
          uploadByFile,
        },
      },
    },
    code: Code,
    underline: Underline,
    inlineCode: InlineCode,
    Marker,
    embed: Embed,
    table: Table,
    nestedList: { class: NestedList, inlineToolbar: true },
    checklist: {
      class: Checklist,
      inlineToolbar: true,
    },
  };
};
