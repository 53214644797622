import React from 'react';
import ConditionalRenderer from '../common/ConditionalRenderer';
import { useTranslation } from 'react-i18next';
import { DifficultyStage } from '@/models/StudentTagScore';
import Tag from '../common/dataDisplay/Tag';
import { twMerge } from 'tailwind-merge';

type StageTagProps = {
  difficultyStage: DifficultyStage | undefined;
  className?: string;
};

export default function StageTag({
  difficultyStage,
  className,
}: StageTagProps) {
  const [tTag] = useTranslation('translation', {
    keyPrefix: 'topics.tags',
  });
  function generateDifficultyText(
    difficultyStage: DifficultyStage | undefined,
  ) {
    if (!difficultyStage) return '';

    const { difficulty, level } = difficultyStage;
    const difficultyValue = tTag(`difficulty.values.${difficulty}`);
    const levelValue = tTag(`level.values.${level}`);

    return `${difficultyValue} ${levelValue}`;
  }
  return (
    <ConditionalRenderer condition={difficultyStage}>
      <Tag
        className={twMerge(
          'rounded-lg bg-secondary-content text-accent py-1 px-1.5',
          className,
        )}
        color="custom"
        text={generateDifficultyText(difficultyStage)}
      />
    </ConditionalRenderer>
  );
}
