import { ResourceFields } from '@/data/services/resourceServices';

export default class Resource {
  id!: number;
  title!: string;
  description?: string | null;
  lessonId!: number;
  scheduledLessonId?: number;
  authorId!: number;
  resourceFile?: File | string | null;
  resourceLink?: string | null;
  isMandatory!: boolean;
  activitiesIds!: number[];
  uploadingProgress?: number;
  uploading?: boolean;

  constructor(init?: Partial<Resource>) {
    Object.assign(this, init);
  }
}

export type ResourceBaseProps = {
  editing?: boolean;
};

export enum ResourceTypeEnum {
  LINK = 'LINK',
  FILE = 'FILE',
  NONE = 'NONE',
}

export type ResourceFormProps = {
  resource?: Resource;
  onSubmit: (body: ResourceFields) => Promise<void>;
  onCancel?(): void;
  loading?: boolean;
  resourceType: ResourceTypeEnum;
};

export enum ResourceFileExtension {
  'image/jpeg' = 'JPEG',
  'image/jpg' = 'JPG',
  'image/png' = 'PNG',
  'application/pdf' = 'PDF',
  'application/zip' = 'ZIP',
  'application/x-zip-compressed' = 'ZIP',
}

export const imageExtensions = [
  ResourceFileExtension['image/jpeg'],
  ResourceFileExtension['image/jpg'],
  ResourceFileExtension['image/png'],
];

export const pdfExtensions = [
  ResourceFileExtension['application/x-zip-compressed'],
  ResourceFileExtension['application/zip'],
];
