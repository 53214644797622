import { ScheduledLessonFilterEnum } from '@/enums/ScheduledLessonFilter';
import Klass from '@/models/Klass';
import ScheduledLesson from '@/models/ScheduledLesson';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ComponentGuard from '@/components/common/ComponentGuard';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Text from '@/components/common/dataDisplay/Text';
import InfinityList from '@/components/common/InfinityList';
import { LoadingIcon } from '@/components/icons';
import useAuth from '@/data/hook/useAuth';
import useInfiniteService from '@/data/hook/useInfiniteService';
import {
  klassesQueryKeys,
  scheduledLessonsQueryKeys,
} from '@/data/services/querykeys';
import {
  ScheduledLessonFilters,
  ScheduledLessonOrdering,
} from '@/data/services/scheduledLessonsServices';
import { isTeacher } from '@/functions/auth';
import { UserTypeEnum } from '@/models/User';
import { handleScheduledLessonFilters } from '@/utils/handleScheduledLessonFilters';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import ManageScheduledLesson from '../../calendar/ManageScheduledLesson/ManageScheduledLesson';
import KlassStartDate from './KlassStartDate';
import {
  ScheduledLessonHeaderDefaultValues,
  ScheduledLessonProgressViewHeader,
} from './ScheduledLessonProgressViewHeader';

interface ScheduledLessonProgressViewProps {
  scheduledLessons?: ScheduledLesson[];
  klass: Klass;
}

export const ScheduledLessonProgressView = ({
  klass,
}: ScheduledLessonProgressViewProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageKlasses.dataKlassTable.scheduledLessonProgressView',
  });

  const { user } = useAuth();

  const teacherView = isTeacher(user?.userType);

  const initialFilters: ScheduledLessonFilters = {
    klassId: klass.id,
    ordering: 'datetime',
  };

  const pinnedFilters: ScheduledLessonFilters = {
    teacher: teacherView ? user?.id : undefined,
  };

  const { control } = useForm<ScheduledLessonHeaderDefaultValues>({
    defaultValues: {
      isDescending: false,
      filter: ScheduledLessonFilterEnum.ALL_SCHEDULED_LESSONS,
    },
  });
  const [filters, setFilters] = useState(initialFilters);

  const {
    results: scheduledLessons,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isPreviousData,
    isLoading,
    invalidate,
  } = useInfiniteService({
    ...scheduledLessonsQueryKeys.list(filters)._ctx.infinity,
    keepPreviousData: true,
  });

  const queryClient = useQueryClient();

  const handleFilters = (filters: Omit<ScheduledLessonFilters, 'klass'>) => {
    setFilters(({ klassId, ordering }) => ({
      ...filters,
      ordering,
      klassId,
    }));
  };

  const onChangeOrder = (ordering: ScheduledLessonOrdering) => {
    setFilters(prev => ({ ...prev, ordering }));
  };

  const onFilterScheduledLessons = (mode: ScheduledLessonFilterEnum) => {
    const options = {
      initialFilters,
      pinnedFilters:
        mode === ScheduledLessonFilterEnum.PENDING_SCHEDULED_LESSONS
          ? pinnedFilters
          : undefined,
    };

    const filter = handleScheduledLessonFilters(mode, options);
    handleFilters(filter);
  };

  if (isLoading) {
    return (
      <div className="flex w-full justify-center p-3.5">
        <LoadingIcon className="text-primary/40 w-10" />
      </div>
    );
  }
  const klassessQueryKey = klassesQueryKeys.list._def;

  const updateKlasses = () => {
    queryClient.invalidateQueries(klassessQueryKey);
  };

  const updateLessons = async () => {
    await invalidate();
    updateKlasses();
  };

  const handleOnSuccess = async () => {
    await queryClient.invalidateQueries(klassessQueryKey);
    await invalidate();
  };

  return (
    <div className="flex pr-5 pl-8 py-3.5 flex-col gap-y-2.5 w-full">
      <ScheduledLessonProgressViewHeader
        onSelectFilter={onFilterScheduledLessons}
        onToggleSort={onChangeOrder}
        control={control}
        actions={
          <ComponentGuard
            roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
          >
            <KlassStartDate klass={klass} onSucess={handleOnSuccess} />
          </ComponentGuard>
        }
      />
      <ConditionalRenderer condition={!scheduledLessons?.length}>
        <div className="flex justify-center p-5 w-full">
          <ConditionalRenderer
            condition={isPreviousData}
            fallback={<Text text={t('noScheduledLessons')} />}
          >
            <LoadingIcon className="text-primary/40 w-10" />
          </ConditionalRenderer>
        </div>
      </ConditionalRenderer>
      <InfinityList
        className="h-[30rem] gap-4"
        scroll
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        onReachEnd={fetchNextPage}
      >
        {scheduledLessons.map(scheduledLesson => (
          <ManageScheduledLesson
            key={scheduledLesson.id}
            updateLessons={updateLessons}
            scheduledLesson={scheduledLesson}
          />
        ))}
      </InfinityList>
    </div>
  );
};
