import {
  ChapterRouteParams,
  createSection,
  SectionDTO,
  updateSection,
  deleteSection,
} from '@/data/services/sectionServices';
import { Section } from '@/models/Book';
import { buildChangedObject } from '@/utils/buildChangedObject';
import { getErrorMessage } from '@/utils/getErrorMessage';
import alert from '@/utils/UseAlert';
import { useMutation } from '@tanstack/react-query';
import { cloneDeep, debounce, isEqual } from 'lodash';
import SectionForm from './form/SectionForm';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type SectionHocProps = {
  section: Section;
  onSubmit?: (section: Section) => void;
  onDelete?: (section: Section) => void;
  isUnique?: boolean;
} & ChapterRouteParams;

export default function SectionService({
  onSubmit,
  isUnique,
  onDelete: onDeleteSection,
  section: { id: sectionId, ...section },
  ...chapterParams
}: SectionHocProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'section.form.alerts',
  });
  const [hasChanges, setHasChanges] = useState(false);

  const handleCreate = async (section: SectionDTO) => {
    return createSection(chapterParams, section);
  };

  const handleUpdate = async (changes: Partial<Section>) => {
    const sectionChanges = buildChangedObject(section, changes);
    return updateSection(
      {
        ...chapterParams,
        sectionId,
      },
      sectionChanges,
    );
  };

  const handleEdit = async ({
    section: newSection,
    validated,
  }: {
    section: Partial<Section>;
    validated: boolean;
  }) => {
    if (!validated) return;
    setHasChanges(false);
    return sectionId
      ? handleUpdate(newSection)
      : handleCreate({
          ...section,
          ...newSection,
        });
  };

  const { mutate: onEdit, isLoading: isLoadingEdit } = useMutation(handleEdit, {
    onSuccess: data => {
      if (!data) return;
      onSubmit?.(data);
      setHasChanges(!isEqual(data, section));
    },
    onError: error => {
      const errorMessage = getErrorMessage(error);
      alert.error(errorMessage);
      onSubmit?.({ id: sectionId, ...section });
    },
  });

  const handleDelete = async () => {
    if (isUnique) {
      alert.warning(t('deleteUnique'));
      return Promise.reject();
    }

    if (!sectionId) {
      onDeleteSection?.({ ...section, id: sectionId });
      return;
    }

    return deleteSection({
      ...chapterParams,
      sectionId,
    });
  };

  const { mutate: onDelete, isLoading: isLoadingDelete } = useMutation(
    handleDelete,
    {
      onSuccess: () => onDeleteSection?.({ ...section, id: sectionId }),
      onError: error => {
        if (isUnique) return;
        const errorMessage = getErrorMessage(error);
        alert.error(errorMessage);
      },
    },
  );

  return (
    <SectionForm
      isLoading={isLoadingEdit}
      isDeleting={isLoadingDelete}
      hasChanges={hasChanges}
      onSubmit={debounce(onEdit, 500)}
      onDelete={onDelete}
      section={cloneDeep({ id: sectionId, ...section })}
      {...chapterParams}
    />
  );
}
