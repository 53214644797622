import requester from '@/axios';
import JsonApiResponse from '@/models/JsonApiResponse';
import { BaseFilters } from '@/models/Service';
import { StudentTagScore } from '@/models/StudentTagScore';

const BASE_URL = 'student-tag-score';

export type ListStudentTagScoreParams = BaseFilters & {
  studentId?: number | number[];
  latestOnly?: boolean;
  parentAreaOnly?: boolean;
  areaId?: number | number[];
  dateFrom?: string;
  dateTo?: string;
  parentAreaId?: number | number[];
};

export async function listStudentTagScore(
  params: ListStudentTagScoreParams = {},
) {
  const { data } = await requester().get<JsonApiResponse<StudentTagScore>>(
    `${BASE_URL}/`,
    {
      params,
    },
  );
  return data;
}
