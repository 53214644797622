import { ResourcePreview } from '@/components/admin/lessons/resource/ResourcePreview';
import Text from '@/components/common/dataDisplay/Text';
import Resource from '@/models/Resource';
import ResourceIcon from './ResourceIcon';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';

type ResourceDetailsProps = {
  resource: Resource;
};
export default function ResourceDetails(props: ResourceDetailsProps) {
  const {
    resource: { title },
  } = props;

  return (
    <section className="flex gap-2 items-center text-primary overflow-x-hidden">
      <ResourceIcon {...props} />

      <Tooltip
        text={title}
        classNameContainer="overflow-x-hidden"
        color="lightBlue"
      >
        <Text text={title} className="font-500 truncate" />
      </Tooltip>
      <ResourcePreview {...props} />
    </section>
  );
}
