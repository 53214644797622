import IconButton from '@/components/common/buttons/IconButton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Text from '@/components/common/dataDisplay/Text';
import SpanInput from '@/components/common/dataInput/SpanInput';
import { PencilIcon, XIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { Control, Controller, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SectionTitleContainer } from '../view/SectionTitle';
import IconSelect from './IconSelect';
import { SectionFormFields } from './SectionForm';

type SectionTitleFormProps = {
  control: Control<SectionFormFields>;
  onClose: () => void;
};

const SectionTitleForm = ({ control, onClose }: SectionTitleFormProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'section.form',
  });
  const {
    field: titleField,
    fieldState: { error },
  } = useController({
    control,
    name: 'title',
    rules: {
      required: t('title.required'),
    },
  });
  const [editingTitle, setEditingTitle] = useState(false);
  return (
    <div className="flex items-center gap-2">
      <SectionTitleContainer>
        <SpanInput
          {...titleField}
          className={{ input: 'w-28' }}
          isEditing={editingTitle}
          errorLabelText={error?.message}
          onKeyDown={event => {
            if (error?.message) return;
            event.key === 'Enter' && setEditingTitle(false);
            event.stopPropagation();
          }}
        >
          {value => (
            <Text text={value} className="font-poppins font-600 text-16" />
          )}
        </SpanInput>

        <Controller
          name="icon"
          control={control}
          render={({ field }) => <IconSelect {...field} />}
        />
      </SectionTitleContainer>
      <ConditionalRenderer condition={!editingTitle}>
        <IconButton
          type="button"
          testId="editTitle"
          icon={<PencilIcon className="text-primary w-4 h-4" />}
          onClick={() => setEditingTitle(prev => !prev)}
        />
      </ConditionalRenderer>
      <ConditionalRenderer condition={editingTitle}>
        <IconButton
          type="button"
          testId="editTitle"
          disabled={!!error?.message}
          icon={<XIcon className="text-error w-4 h-4" />}
          onClick={() => {
            setEditingTitle(prev => !prev);
            onClose();
          }}
        />
      </ConditionalRenderer>
    </div>
  );
};

export default SectionTitleForm;
