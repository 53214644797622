import { enUS, ptBR } from 'date-fns/locale';
import moment from 'moment';
import {
  DayPicker,
  DayPickerDefaultProps,
  DayPickerMultipleProps,
  DayPickerRangeProps,
  DayPickerSingleProps,
} from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { twJoin } from 'tailwind-merge';

type DayPickerProps =
  | DayPickerDefaultProps
  | DayPickerSingleProps
  | DayPickerMultipleProps
  | DayPickerRangeProps;

type CustomStyles = Pick<DayPickerProps, 'classNames' | 'modifiersClassNames'>;

const dayPickerMultipleStyles: CustomStyles = {
  classNames: {
    day_disabled: 'hover:bg-transparent hover:text-neutral',
    head: 'text-primary',
    day: twJoin(
      'rounded rounded-full w-full aspect-square transition-all duration-300',
      'hover:bg-primary-content hover:text-primary focus:bg-base-100',
    ),
    caption: 'capitalize flex flex-row justify-between',
    day_selected: 'bg-primary text-base-100 focus:bg-primary',
    day_today: 'bg-secondary-content text-secondary',
    months: 'grid grid-cols-2 lg:grid-cols-3 h-fit',
  },
};

const dayPickerSingleStyles: CustomStyles = {
  classNames: {
    head: 'text-primary',
    day: 'text-base-content rounded rounded-full hover:bg-secondary-content hover:text-secondary focus:bg-base-100 w-full aspect-square',
    caption: 'capitalize flex flex-row justify-between',
    day_selected:
      'bg-secondary-content text-secondary focus:bg-secondary-content',
    day_today: 'bg-primary-content text-primary',
  },
};

const dayPickerRangeStyles: CustomStyles = {
  modifiersClassNames: {
    selected: 'bg-secondary/40 text-base-100',
    today: 'bg-primary text-base-100 font-600',
  },
};
const dayPickerModesStyles: Record<Required<DayPickerProps>['mode'], any> = {
  default: dayPickerSingleStyles,
  range: dayPickerRangeStyles,
  single: dayPickerSingleStyles,
  multiple: dayPickerMultipleStyles,
};

export const LocaleDayPicker = (props: DayPickerProps) => {
  const {
    i18n: { language },
  } = useTranslation();

  const localeLanguage = language === 'pt_BR' ? ptBR : enUS;

  return (
    <DayPicker
      locale={localeLanguage}
      captionLayout="dropdown-buttons"
      className="bg-base-content rounded-lg p-4 shadow-default shadow-neutral-content"
      toYear={moment().get('year') + 1}
      fromYear={1900}
      {...dayPickerModesStyles[props.mode || 'default']}
      {...props}
    />
  );
};
