import TextWithTooltip from '@/components/common/dataDisplay/TextWithTooltip';
import { SectionIconsType } from '@/models/Book';
import { ClassNameComponent } from '@/utils/ClassNameComponent';
import { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';
import SectionIcon from './SectionIcon';

type SectionTitleProp = {
  title: string;
  icon: SectionIconsType;
  className?: ClassNameComponent;
} & Omit<ComponentProps<'div'>, 'className'>;

const SectionTitle = ({
  title,
  icon,
  className,
  ...props
}: SectionTitleProp) => {
  return (
    <SectionTitleContainer className={className?.base} {...props}>
      <TextWithTooltip
        text={title}
        format="poppins-600"
        className={twMerge('text-16 truncate', className?.text)}
      />

      <SectionIcon icon={icon} />
    </SectionTitleContainer>
  );
};

export const SectionTitleContainer = ({
  children,
  className,
  ...props
}: ComponentProps<'div'>) => {
  return (
    <div
      className={twMerge(
        'flex gap-2.5 items-center px-2.5 py-1 bg-secondary/50 rounded-md w-fit max-w-full',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default SectionTitle;
