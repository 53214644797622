import { GetBookParams } from '@/data/services/bookServices';
import { GetChapter } from '@/data/services/chapterServices';
import { Section } from '@/models/Book';
import Chapter from '@/models/Chapter';
import { PlusIcon, TrashIcon, ViewGridIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SectionService from '../sections/SectionService';
import ChapterFormTitle from './ChapterFormTitle';

import useCourseEditing from '@/data/hook/useCourseEditing';
import { useTranslation } from 'react-i18next';
import ConditionalRenderer from '../../../ConditionalRenderer';
import IconButton from '../../../buttons/IconButton';
import ChapterLayoutForm from './layout/ChapterLayoutForm';
import {
  dropHandler,
  DragDropItem,
  DragDropList,
} from '@/components/common/dataInput/DragDrop';
import { OnDragEndResponder } from '@hello-pangea/dnd';
import { DragIcon } from '@/components/icons';

export type ChapterFormFields = Pick<Chapter, 'title' | 'layout'>;
type ChapterFormProps = {
  chapter: Chapter;
  onAddSection: () => void;
  isDeletingChapter?: boolean;
  isAddingSection?: boolean;
  onDeleteSection: (section: Section) => void;
  onUpdateSection: (section: Section) => void;
  onDeleteChapter: () => void;
  onReorderSections: (sections: Section[]) => void;
  onSubmit: (params: {
    chapter: Partial<ChapterFormFields>;
    validated: boolean;
  }) => void;
} & GetBookParams;

export default function ChapterForm({
  chapter,
  onSubmit,
  onAddSection,
  onUpdateSection,
  isAddingSection,
  onDeleteChapter,
  isDeletingChapter,
  onDeleteSection,
  ...bookparams
}: ChapterFormProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chapter.form',
  });
  const { t: tPage } = useTranslation('translation', {
    keyPrefix: 'editMaterialPage',
  });
  const { allowBigChanges } = useCourseEditing();
  const { control, watch, trigger } = useForm<ChapterFormFields>({
    defaultValues: {
      title: chapter.title,
      layout: chapter.layout,
    },
  });

  const [layout, setLayout] = useState(false);

  useEffect(() => {
    const subscribe = watch(chapter =>
      trigger().then(validated =>
        onSubmit({ chapter: chapter as ChapterFormFields, validated }),
      ),
    );
    return () => {
      subscribe.unsubscribe();
    };
  }, [onSubmit, watch, trigger]);

  return (
    <form className="flex flex-col gap-6 w-full" data-testid="chapterForm">
      <div className="flex justify-between items-center w-full flex-wrap gap-2.5">
        <ChapterFormTitle control={control} />

        <div className="flex gap-2.5">
          <IconButton
            color="custom"
            type="button"
            tooltip={t('editLayout')}
            testId="editLayout"
            onClick={() => setLayout(prev => !prev)}
            className="text-primary w-5 h-5 font-500 gap-1.5"
            icon={<ViewGridIcon />}
          />
          <IconButton
            color="custom"
            type="button"
            loading={isAddingSection}
            onClick={onAddSection}
            tooltip={!allowBigChanges ? tPage('cantModify') : t('addSection')}
            disabled={!allowBigChanges}
            testId="addSection"
            className="text-primary font-500  gap-1.5 w-5 h-5"
            icon={<PlusIcon />}
          />
          <IconButton
            color="custom"
            type="button"
            tooltip={
              !allowBigChanges ? tPage('cantModify') : t('deleteChapter')
            }
            disabled={!allowBigChanges}
            onClick={onDeleteChapter}
            testId="deleteChapter"
            loading={isDeletingChapter}
            className="text-error font-500 w-5 h-5 gap-1.5"
            icon={<TrashIcon />}
          />
        </div>
      </div>
      <ConditionalRenderer condition={layout}>
        <Controller
          name="layout"
          control={control}
          render={({ field }) => (
            <ChapterLayoutForm
              sections={chapter.sections || []}
              layout={field.value || { type: 'layout1' }}
              onChange={field.onChange}
              onFinish={() => setLayout(false)}
            />
          )}
        />
      </ConditionalRenderer>
      <ConditionalRenderer condition={!layout}>
        <SectionsService
          key={JSON.stringify(chapter.sections?.map(section => section.id))}
          sections={chapter.sections || []}
          onDeleteSection={onDeleteSection}
          onUpdateSection={onUpdateSection}
          {...bookparams}
          chapterId={chapter.id}
        />
      </ConditionalRenderer>
    </form>
  );
}

type SectionsListProps = {
  sections: Section[];
  onDeleteSection: (section: Section) => void;
  onUpdateSection: (section: Section) => void;
  onReorderSections: (sections: Section[]) => void;
} & GetChapter;

const SectionsService = ({
  sections: chapterSections,
  onDeleteSection,
  onUpdateSection,
  onReorderSections,
  ...props
}: SectionsListProps) => {
  const [sections, setSections] = useState(chapterSections);
  const { allowBigChanges } = useCourseEditing();

  const handleDragEnd: OnDragEndResponder = res => {
    const reordenedResult = dropHandler(res, chapterSections);
    setSections(reordenedResult);
    onReorderSections(reordenedResult);
  };
  return (
    <DragDropList
      className="relative flex flex-col gap-2.5 w-full"
      groupId="sections"
      onDragEnd={handleDragEnd}
    >
      {sections.map((section, index) => (
        <DragDropItem
          key={section.id}
          id={'section' + section.id}
          index={index}
          disabled={!allowBigChanges}
          dragIconHandle={
            <div className="relative h-full flex items-center top-1.5">
              <DragIcon className="w-5" />
            </div>
          }
        >
          <SectionService
            section={section}
            isUnique={sections.length === 1}
            onSubmit={onUpdateSection}
            {...props}
            onDelete={() => onDeleteSection(section)}
          />
        </DragDropItem>
      ))}
    </DragDropList>
  );
};
