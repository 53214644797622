import { ClassNameComponent } from '@/utils/ClassNameComponent';
import {
  Fragment,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import { twJoin, twMerge } from 'tailwind-merge';
import Text from './dataDisplay/Text';
import IconButton from './buttons/IconButton';
import { XIcon } from '@heroicons/react/outline';

export type InfoCardModalProps = PropsWithChildren<{
  title: string;
  subtitle?: string;
  open?: boolean;
  onClose?(): void;
  className?: Pick<ClassNameComponent, 'base' | 'header'>;
  trigger?: ReactNode;
}>;

export default function InfoSideModal({
  title,
  subtitle,
  onClose,
  open,
  children,
  className,
  trigger,
}: InfoCardModalProps) {
  const cardRef = useRef<HTMLDivElement>(null);
  const triggerContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function onClickBody(e: MouseEvent) {
      const target = e.target;
      if (!target || !(target instanceof Node)) return;
      const triggerContainerElement = triggerContainerRef?.current;
      const cardElement = cardRef.current;

      if (
        !cardElement?.contains(target) &&
        !triggerContainerElement?.contains(target)
      ) {
        onClose?.();
      }
    }
    document.addEventListener('click', onClickBody);

    return () => {
      document.removeEventListener('click', onClickBody);
    };
  }, [onClose, open]);

  return (
    <Fragment>
      <div ref={triggerContainerRef}>{trigger}</div>
      <div
        ref={cardRef}
        className={twMerge(
          twJoin(
            'flex flex-col gap-5 bg-base-200 border transition-all ease-in-out duration-100 border-neutral-content rounded-md px-3 py-4 fixed top-0 z-50 h-full md:h-[calc(100vh-40px)] max-w-[80vw] xs:w-3/5 md:w-2/5 xl:w-1/4 md:min-w-96 md:max-w-[480px] md:my-5 md:mr-5',
            open ? 'right-0' : '-right-full',
          ),
          className?.base,
        )}
      >
        <div
          className={twMerge(
            'flex text-primary justify-between items-center',
            className?.header,
          )}
        >
          <div className="flex gap-2.5 items-center">
            <Text text={title} className="text-18" format="rubik-500" />
            <Text
              text={subtitle}
              format="rubik-400"
              className="text-16 text-base-content"
            />
          </div>
          <IconButton icon={<XIcon className="h-5 w-5" />} onClick={onClose} />
        </div>
        {children}
      </div>
    </Fragment>
  );
}
