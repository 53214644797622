import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import chapterFormatter from '@/functions/chapterFormatter';
import Chapter from '@/models/Chapter';
import { stringToStringArray } from '@/utils/stringToStringArray';
import { HtmlPreview } from './dataDisplay/HtmlPreview';
import Text from './dataDisplay/Text';
import ChapterView from './material/slides/chapters/ChapterView';

interface ChapterRenderProps {
  chapter?: Chapter;
}

export default function ChapterRender({ chapter }: ChapterRenderProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'klassView' });

  if (!chapter) return <Text text={t('noneMaterial')} />;

  return (
    <div>
      <Background bottom />
      <Background />
      <ChapterSectionHandler chapter={chapter} />
    </div>
  );
}

interface ChapterHandlerProps {
  chapter: Chapter;
}

export const ChapterSectionHandler = ({ chapter }: ChapterHandlerProps) => {
  if (!chapter.content) return <ChapterView chapter={chapter} />;

  return <ChapterContentHandler chapter={chapter} />;
};

const ChapterContentHandler: FC<ChapterHandlerProps> = ({ chapter }) => {
  const isOldBook = chapter.content.at(0) === '[';

  const chapterContent = isOldBook
    ? chapterFormatter(chapter.title, stringToStringArray(chapter.content))
    : chapter.content;

  return <HtmlPreview format="book" html={chapterContent} />;
};

function Background({ bottom }: { bottom?: boolean }) {
  return (
    <div
      className={`content-none pointer-events-none w-9/12 h-96 bg-cover opacity-10 absolute 
          ${bottom ? 'bottom-0 right-0 -scale-x-100' : 'top-0 left-0'} `}
      style={{
        backgroundImage: 'url(/images/background-book.png)',
        transform: `scale(${bottom ? -1 : 1})`,
      }}
    />
  );
}
