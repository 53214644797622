import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XIcon,
} from '@heroicons/react/outline';
import { enUS, ptBR } from 'date-fns/locale';
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import {
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Matcher } from 'react-day-picker';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import IconButton from '@/components/common/buttons/IconButton';
import MainButton from '@/components/common/buttons/MainButton';
import Text from '@/components/common/dataDisplay/Text';
import { BaseInputProps } from '@/components/common/dataInput/BaseInput';
import { LocaleDayPicker } from '@/components/common/dataInput/LocaleDayPicker';
import { dropdown, fadeInVariants } from '@/utils/animations/commom';
import { scrollBarYClassName } from '@/utils/scrollBarClassName';
import { timezonedToBrowserDate } from '@/utils/datetimes';

type CalendarProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'className'> &
  Omit<BaseInputProps, 'register' | 'value'> & {
    disabledDates?: Matcher[];
    onDatesChange?(values: string[]): void;
    register?: Omit<UseFormRegisterReturn, 'min' | 'max'>;
    value?: string[];
    timezone?: string;
  };

const Calendar = forwardRef<HTMLElement, CalendarProps>(CalendarComponent);

export default Calendar;

function CalendarComponent(
  props: CalendarProps,
  ref: ForwardedRef<HTMLElement>,
) {
  const {
    value: values = [],
    disabled,
    onDatesChange,
    timezone = 'America/Sao_Paulo',
  } = props;

  const [t] = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const currentDate = moment();

  const currentYear = moment().year();
  const [year, setYear] = useState(currentYear);

  const formatDate = useCallback(
    (date: Date | string) => moment.tz(date, timezone).format('YYYY-MM-DD'),
    [timezone],
  );

  const datesRef = useRef<string[]>(values.map(value => formatDate(value)));

  const buildDates = useCallback(
    (dates: string[]) => {
      const yearDates = dates.filter(
        date => moment.tz(date, timezone).year() === year,
      );
      const momentDates = yearDates.map(date => moment.tz(date, timezone));
      datesRef.current = values.map(value => formatDate(value));
      return momentDates.map(value => timezonedToBrowserDate(value.toDate()));
    },
    [values, timezone, year, formatDate],
  );

  const [currentYearDates, setCurrentYearDates] = useState<Date[]>(
    buildDates(values),
  );

  useEffect(() => {
    const newDates = buildDates(values);
    if (JSON.stringify(newDates) !== JSON.stringify(currentYearDates)) {
      setCurrentYearDates(newDates);
    }
  }, [buildDates, values, currentYearDates]);

  const {
    i18n: { language },
  } = useTranslation();

  const locale = language === 'pt_BR' ? ptBR : enUS;

  const removeDate = (date: string) => {
    const formatedDate = formatDate(date);
    const filterDate = datesRef.current.filter(
      current => formatDate(current) !== formatedDate,
    );
    onDatesChange?.(filterDate);
    datesRef.current = filterDate;
  };

  const addDate = (date: string) => {
    const formatedDate = formatDate(date);
    const updatedDates = [...datesRef.current, formatedDate];
    onDatesChange?.(updatedDates);
    datesRef.current = updatedDates;
  };

  const onSelect = (date: Date) => {
    const correctDate = moment
      .tz(moment(timezonedToBrowserDate(date)), timezone)
      .clone()
      .format();

    const selected = currentYearDates.some(
      currentDate => formatDate(currentDate) === formatDate(correctDate),
    );
    if (selected) removeDate(correctDate);
    else addDate(correctDate);
  };

  const isBeforeNovember = currentDate.isBefore(
    moment().month(10).startOf('month'),
    'month',
  );

  const enabledNextYearButton =
    !(isBeforeNovember && currentYear === year) && year !== currentYear + 1;

  return (
    <section className="flex grow gap-4" ref={ref}>
      <div className="flex flex-col border border-neutral-content p-3.5 rounded-xl shadow-default">
        <div className="grid grid-cols-3 justify-between items-center text-center">
          <MainButton
            dataTestId="previousYearButton"
            color="custom"
            className="text-primary"
            text={String(year - 1)}
            type="button"
            icon={<ChevronLeftIcon />}
            onClick={() => setYear(prev => prev - 1)}
          />

          <Text text={year} className="text-primary text-[2rem] font-500" />
          <ConditionalRenderer condition={enabledNextYearButton}>
            <MainButton
              dataTestId="nextYearButton"
              color="custom"
              className="text-primary flex flex-row-reverse justify-self-end"
              text={String(year + 1)}
              type="button"
              icon={<ChevronRightIcon />}
              onClick={() => setYear(prev => prev + 1)}
            />
          </ConditionalRenderer>
        </div>
        <LocaleDayPicker
          onDayClick={date => onSelect(date)}
          className={disabled ? 'pointer-events-none select-none' : ''}
          numberOfMonths={12}
          selected={currentYearDates}
          mode="multiple"
          month={moment().year(year).month(0).toDate()}
          fixedWeeks
          components={{
            Caption: ({ displayMonth }) => (
              <div className="flex justify-center">
                <span className="capitalize text-primary font-500">
                  {moment(displayMonth)
                    .locale(locale.code || 'pt_BR')
                    .format('MMMM')}
                </span>
              </div>
            ),
          }}
        />
      </div>

      <AnimatePresence>
        <motion.div
          animate={disabled ? 'hidden' : 'show'}
          variants={fadeInVariants}
          className="flex flex-col gap-1 border border-neutral-content p-3.5 rounded-xl shadow-default grow"
        >
          <Text
            text={t('dates')}
            className="bg-transparent text-primary font-500"
          />

          <ul
            className={twMerge(
              'flex flex-col gap-1 pr-4 min-h-8',
              scrollBarYClassName,
            )}
          >
            <ConditionalRenderer
              condition={currentYearDates.length}
              fallback={
                <Text text={t('noDates')} className="text-[0.875rem]" />
              }
            >
              {currentYearDates.map(date => {
                const formated = moment.tz(date, timezone).format('DD/MM/YYYY');

                return (
                  <motion.li
                    {...dropdown}
                    key={formated}
                    className="text-secondary bg-secondary-content rounded-md px-2 py-1 font-rubik font-500 flex 
                  items-center gap-1 text-[0.875rem] w-32 justify-between shadow-sm"
                  >
                    {formated}
                    <IconButton
                      type="button"
                      icon={<XIcon className="w-4 cursor-pointer text-error" />}
                      onClick={() => onSelect(date)}
                    />
                  </motion.li>
                );
              })}
            </ConditionalRenderer>
          </ul>
        </motion.div>
      </AnimatePresence>
    </section>
  );
}
