import TextWithTooltip from '@/components/common/dataDisplay/TextWithTooltip';
import Chapter from '@/models/Chapter';
import { OnDragEndResponder, OnDragStartResponder } from '@hello-pangea/dnd';
import { PlusIcon } from '@heroicons/react/outline';
import { ComponentProps, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import ConditionalRenderer from '../../../ConditionalRenderer';
import MainButton from '../../../buttons/MainButton';
import Text from '../../../dataDisplay/Text';
import {
  dropHandler,
  DragDropItem,
  DragDropList,
} from '../../../dataInput/DragDrop';
import SectionTitle from '../sections/view/SectionTitle';

type ChaptersProps = {
  chapters: Chapter[];
  active?: Chapter;
  onReorder: (chapters: Chapter[]) => void;
  onAddChapter: () => void;
  isAddingChapter: boolean;
  onClickChapter?: (chapter: Chapter) => void;
  readOnly?: boolean;
};

export default function Chapters({
  chapters: bookChapters,
  active,
  readOnly,
  isAddingChapter,
  onReorder,
  onAddChapter,
  onClickChapter,
}: ChaptersProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chapter.list',
  });
  const [chapters, setChapters] = useState(bookChapters);

  const handleDragEnd: OnDragEndResponder = res => {
    const reordenedResult = dropHandler(res, chapters);
    setChapters(reordenedResult);
    onReorder(reordenedResult);
  };

  const handleDragStart: OnDragStartResponder = res => {
    const chapter = chapters.at(res.source.index);
    if (!chapter) return;
    onClickChapter?.(chapter);
  };

  return (
    <div className="relative flex flex-col gap-6 w-64 flex-shrink-0">
      <ChaptersTitle />
      <div className="relative flex flex-col gap-3">
        <DragDropList
          groupId="chapters"
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          className="relative"
          renderClone={(provided, _, rublic) => {
            const chapter = chapters[rublic.source.index];
            return (
              <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
              >
                <ChapterItem
                  chapter={chapter}
                  isActive={chapter.id === active?.id}
                  onClick={() => onClickChapter?.(chapter)}
                  className="cursor-pointer bg-base-200 p-2 rounded-lg border border-neutral-content"
                />
              </div>
            );
          }}
        >
          {chapters.map((chapter, index) => (
            <DragDropItem
              id={'chapter' + chapter.id}
              index={index}
              key={'chapter' + chapter.id}
              disabled={readOnly}
            >
              <ChapterItem
                chapter={chapter}
                isActive={chapter.id === active?.id}
                onClick={() => onClickChapter?.(chapter)}
                className="cursor-pointer"
              />
            </DragDropItem>
          ))}
        </DragDropList>
        <ConditionalRenderer condition={!readOnly}>
          <MainButton
            color="custom"
            className="relative text-primary font-600 gap-1 "
            text={t('add')}
            icon={<PlusIcon />}
            loading={isAddingChapter}
            onClick={onAddChapter}
          />
        </ConditionalRenderer>
      </div>
    </div>
  );
}

const ChapterItem = ({
  chapter,
  isActive,
  className,
  ...props
}: {
  chapter: Chapter;
  isActive: boolean;
} & ComponentProps<'div'>) => {
  return (
    <div className={twMerge('flex flex-col gap-2', className)} {...props}>
      <div className="flex gap-1 items-center">
        <Text
          text={isActive ? '>>' : '>'}
          className="text-secondary font-poppins font-700"
        />
        <TextWithTooltip
          text={chapter.title}
          className="font-poppins text-16 font-600"
        />
      </div>
      <ul className="flex flex-col gap-1.5 mx-5">
        {chapter.sections?.map(section => (
          <SectionTitle
            key={section.id}
            title={section.title}
            icon={section.icon}
            className={{ base: 'w-fit max-w-full' }}
          />
        ))}
      </ul>
    </div>
  );
};

const ChaptersTitle = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chapter.list',
  });
  return (
    <div className="relative flex justify-center w-full">
      <Text
        text={t('title')}
        size="text-20"
        className="relative font-poppins font-600 z-10"
      />
      <div className="h-1/2 w-full bg-secondary-content absolute bottom-0 z-0" />
    </div>
  );
};
