import {
  QueryKey,
  UseQueryOptions,
  useQueryClient,
} from '@tanstack/react-query';

type TSingle<
  Model,
  TError = unknown,
  TQueryKey extends QueryKey = QueryKey,
> = UseQueryOptions<Model, TError, Model, TQueryKey>;

export const useSingleQuery = <
  Model,
  TError = unknown,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: TSingle<Model, TError, TQueryKey>,
) => {
  const queryClient = useQueryClient();

  const setData = (data: Model) => {
    queryClient.setQueryData(options.queryKey as QueryKey, data);
  };

  const getData = () => {
    return queryClient.getQueryData<Model>(options.queryKey as QueryKey);
  };

  return { setData, getData };
};
