import useInfiniteService from '@/data/hook/useInfiniteService';
import { resourceQuerykeys } from '@/data/services/querykeys';
import { useTranslation } from 'react-i18next';

import { MANAGEMENT_PAGE_SIZE as pageSize } from '@/constants';

import { ResourceProgress } from '@/models/ResourceProgress';
import InfinityList from '@/components/common/InfinityList';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import {
  CircleIcon,
  DashedCircleIcon,
  LoadingIcon,
  SolidGradientCheckCircleIcon,
} from '@/components/icons';
import Text from '@/components/common/dataDisplay/Text';
import { getErrorMessage } from '@/utils/getErrorMessage';
import Resource from '@/models/Resource';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import RoundedButton from '@/components/common/buttons/RoundedButton';

type LessonResourcesProps = {
  resourceIds?: number[];
  isStaff?: boolean;
  resourceProgress?: ResourceProgress[];
};

export default function LessonResources(props: LessonResourcesProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const { resourceIds = [], isStaff, resourceProgress = [] } = props;

  const filters = {
    id: resourceIds,
    pageSize,
  };

  const {
    results: resources,
    isInitialLoading: loadingResources,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    error: resourcesError,
  } = useInfiniteService({
    ...resourceQuerykeys.list(filters)._ctx.infinity,
    enabled: !!resourceIds.length,
  });

  return (
    <section className="flex flex-col gap-2.5 w-full">
      <Text
        text={t('resources')}
        className="self-center text-primary text-[1.75rem]"
        format="poppins-600"
      />
      <InfinityList
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        onReachEnd={fetchNextPage}
        className="gap-2 max-h-[20rem] overflow-x-hidden mt-4 w-full self-start"
        scroll
      >
        <ConditionalRenderer condition={loadingResources}>
          <LoadingIcon className="w-40 self-center text-secondary-content mt-4" />
        </ConditionalRenderer>
        <ConditionalRenderer condition={resourcesError}>
          <Text text={getErrorMessage(resourcesError)} className="mt-4" />
        </ConditionalRenderer>
        <ConditionalRenderer
          condition={resources.length && !loadingResources && !resourcesError}
        >
          {resources.map((resource, index) => (
            <LessonResource
              isStaff={isStaff}
              index={index}
              key={resource.id}
              resource={resource}
              resourceProgress={resourceProgress.find(
                progress => progress.resourceId === resource.id,
              )}
            />
          ))}
        </ConditionalRenderer>
      </InfinityList>
    </section>
  );
}

type LessonResourceProps = {
  resource: Resource;
  resourceProgress?: ResourceProgress;
  index: number;
  isStaff?: boolean;
};

export function LessonResource(props: LessonResourceProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const { resource, resourceProgress } = props;

  const baseUrl = 'resources';

  return (
    <li className="flex flex-col xs:flex-row gap-2.5 justify-between w-full">
      <span className="flex gap-2.5 overflow-x-hidden items-center">
        <ResourceProgressIcon {...resourceProgress} {...props} />
        <Tooltip
          text={resource.title}
          classNameContainer="overflow-x-hidden"
          color="lightBlue"
        >
          <Text
            text={resource.title}
            className="font-500 text-[1.125rem] text-primary truncate"
          />
        </Tooltip>
      </span>

      <RoundedButton
        href={`${baseUrl}/${resource.id}`}
        text={t('open')}
        className="text-[0.75rem] md:text-[0.875rem]"
      />
    </li>
  );
}

type ResourceProgressIconProps = {
  isStaff?: boolean;
  hasOpened?: boolean;
};

function ResourceProgressIcon(props: ResourceProgressIconProps) {
  const { isStaff, hasOpened } = props;

  if (isStaff) return <CircleIcon className="w-4 h-4 text-primary" />;

  return hasOpened ? (
    <SolidGradientCheckCircleIcon className="w-4 h-4 shrink-0" />
  ) : (
    <DashedCircleIcon className="w-4 h-4 shrink-0 text-secondary" />
  );
}
