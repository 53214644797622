import { ReactNode } from 'react';

import Loader from '@/pages/courses/LoadingView';
import Card from '@/components/common/Card';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';

interface BookContainerProps {
  children: ReactNode;
  isLoading?: boolean;
}

function ChapterContainer({ isLoading, children }: BookContainerProps) {
  return (
    <Card className="rounded-2xl block shadow-default md:w-full md:h-fit p-8 bg-no-repeat relative overflow-hidden">
      <ConditionalRenderer condition={!isLoading} fallback={<Loader />}>
        {children}
      </ConditionalRenderer>
    </Card>
  );
}

export default ChapterContainer;
