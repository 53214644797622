import { twMerge } from 'tailwind-merge';
import Text, { TextProps } from './Text';
import { Tooltip } from './Tooltip';

type TextWithCopyProps = TextProps;

export default function TextWithTooltip({ ...textProps }: TextWithCopyProps) {
  return (
    <Tooltip
      text={textProps.text}
      placement="bottom"
      classNameContainer="relative truncate"
    >
      <Text
        {...textProps}
        className={twMerge('truncate', textProps.className)}
      />
    </Tooltip>
  );
}
