import { Section } from '@/models/Book';
import requester from '../../axios';

export type ChapterRouteParams = {
  bookId: number;
  lessonId: number;
  chapterId: number;
};

type SectionRouteParams = ChapterRouteParams & {
  sectionId: number;
};
export const getSection = async ({
  lessonId,
  bookId,
  chapterId,
  sectionId,
}: SectionRouteParams) => {
  const { data } = await requester().get<Section>(
    `lessons/${lessonId}/books/${bookId}/chapters/${chapterId}/sections/${sectionId}/`,
  );
  return data;
};

export type SectionDTO = Omit<Section, 'id'>;
export const createSection = async (
  { lessonId, bookId, chapterId }: ChapterRouteParams,
  section: SectionDTO = new Section(),
) => {
  const { data } = await requester().post<Section>(
    `lessons/${lessonId}/books/${bookId}/chapters/${chapterId}/sections/`,
    section,
  );
  return data;
};

export const updateSection = async (
  { lessonId, bookId, chapterId, sectionId }: SectionRouteParams,
  section: Partial<SectionDTO>,
) => {
  const { data } = await requester().patch<Section>(
    `lessons/${lessonId}/books/${bookId}/chapters/${chapterId}/sections/${sectionId}/`,
    section,
  );
  return data;
};
export const deleteSection = async ({
  lessonId,
  bookId,
  chapterId,
  sectionId,
}: SectionRouteParams) => {
  await requester().delete(
    `lessons/${lessonId}/books/${bookId}/chapters/${chapterId}/sections/${sectionId}/`,
  );
};
export type UpdateChaptersOrder = {
  params: ChapterRouteParams;
  sections: { id: number }[];
};
export async function updateSectionsOrder({
  params,
  sections,
}: UpdateChaptersOrder) {
  const { lessonId, chapterId, bookId } = params;

  await requester().patch(
    `lessons/${lessonId}/books/${bookId}/chapters/${chapterId}/sections/order/`,
    sections,
  );
}
