import useManualQuery from '@/data/hook/query/useManualQuery';
import { updateBook } from '@/data/services/bookServices';
import { updateChapter } from '@/data/services/chapterServices';
import { updateLesson } from '@/data/services/lessonServices';
import { lessonsQueryKeys } from '@/data/services/querykeys';
import chapterFormatter from '@/functions/chapterFormatter';
import { isPulished } from '@/functions/handleCourseStatusIcon';
import Book from '@/models/Book';
import Chapter from '@/models/Chapter';
import CoursePath from '@/models/Course';
import { ApiError } from '@/models/Errors';
import { Lesson } from '@/models/Lesson';
import { anOldChapter, stringToStringArray } from '@/utils/stringToStringArray';
import alert from '@/utils/UseAlert';
import { useMutation } from '@tanstack/react-query';

export type onEditBookFn = () => Promise<{
  lesson: Lesson;
  book: Book;
}>;

type useMaterialVersioningReturn = {
  onEditBook: onEditBookFn;
  isLoading: boolean;
};

type useMaterialVersioningProps = {
  lesson: Lesson;
  book?: Book;
  course?: CoursePath;
};

export const useMaterialVersioning = ({
  lesson,
  course,
  book,
}: useMaterialVersioningProps): useMaterialVersioningReturn => {
  const { update } = useManualQuery(
    lessonsQueryKeys.nestedList(course?.slug || '', {
      pageSize: 50,
    })._ctx.infinity,
  );

  const bookType = book?.bookType || 'STUDENT';

  const editBook = async (lessonId: number, bookId: number) => {
    const params = {
      bookId,
      lessonId,
      bookType,
    };
    const book = await updateBook(params, {});
    return book;
  };

  const editLesson = async (lesson: Lesson) => {
    if (isPulished(lesson.status) && course) {
      const updatedLesson = await updateLesson(
        {
          slugCourseName: course.slug,
          lessonId: lesson.id,
        },
        {},
      );
      return updatedLesson;
    }
    return lesson;
  };

  const formatOldChapter = async (chapter: Chapter, lessonId: number) => {
    if (!chapter.book) throw new Error('book not found');

    const content = chapterFormatter(
      chapter.title,
      stringToStringArray(chapter.content),
    );

    await updateChapter({
      params: {
        chapterId: chapter.id,
        bookId: chapter.book,
        bookType,
        lessonId: lessonId,
      },
      body: {
        content,
      },
    });
  };

  const formatChapter = async (chapter: Chapter, lessonId: number) => {
    try {
      if (!anOldChapter(chapter)) return Promise.resolve();
      await formatOldChapter(chapter, lessonId);
    } catch (err) {}
  };

  const handleEditBook = async () => {
    if (!book) throw new Error('Not editing book');

    let currentLesson = lesson;

    if (!isPulished(book.status))
      return {
        lesson,
        book,
      };

    currentLesson = await editLesson(currentLesson);

    const updatedBook = await editBook(currentLesson.id, book.id);

    const chaptersFormatPromises = updatedBook.chapters.map(chapter =>
      formatChapter(chapter, currentLesson.id),
    );

    await Promise.all(chaptersFormatPromises);

    return { lesson: currentLesson, book: updatedBook };
  };

  const { mutateAsync: onEditBook, isLoading } = useMutation(handleEditBook, {
    onSuccess: data =>
      update(
        {
          ...data.lesson,
          id: lesson.id,
          book: data.book.id,
        },
        data.lesson.id,
      ),
    onError(error: any) {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    },
  });

  return { onEditBook, isLoading };
};
