import { EditorConfig, OutputData } from '@editorjs/editorjs';

import { useEditorJS } from '@/data/hook/editorjs/useEditorJS';
import { useEditorJSTools } from '@/data/hook/editorjs/useEditorTools';
import { ImageFolderEnum } from '@/models/CkEditor';
import { useState, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import { delay } from '@/utils/delay';

type ReadOnly = {
  readOnly: true;
  folder?: undefined;
};

type Writable = {
  readOnly?: false;
  folder: ImageFolderEnum;
};
type EditorProps = {
  value?: OutputData;
  onChange?: (value: OutputData) => void;
  extraBody?: object;
} & Omit<EditorConfig, 'onChange' | 'data' | 'tools'> &
  (Writable | ReadOnly);

export default function EditorJs({
  value,
  onChange,
  folder,
  extraBody,
  readOnly,
  ...editorProps
}: EditorProps) {
  const tools = useEditorJSTools({
    folder,
    extraBody,
    readOnly,
  });

  const { setElement, element } = useEditorJS({
    data: value,
    onChange,
    tools,
    readOnly,
    ...editorProps,
  });

  const [openedToolbar, setOpenedToolbar] = useState(false);

  const checkToolbarOpened = useCallback(async () => {
    await delay(50);
    const toolbar1 = element?.querySelector('.codex-editor--toolbox-opened');
    const toolbar2 = element?.querySelector('.ce-popover--opened');

    setOpenedToolbar(!!toolbar1 || !!toolbar2);
  }, [element]);

  return (
    <div
      className={twMerge(
        'relative ml-auto text-left',
        !readOnly ? 'w-full sm:w-[calc(100%-60px)] ' : 'w-full',
        openedToolbar ? 'z-10' : 'z-0',
      )}
      onClick={e => {
        e.stopPropagation();
        checkToolbarOpened();
      }}
      onFocus={checkToolbarOpened}
      onMouseLeave={checkToolbarOpened}
    >
      <div ref={ref => setElement(ref)} />
    </div>
  );
}
