import QuizElement from './QuizElement';
import TextElement from './TextElement';
import VideoElement from './VideoElement';
import TrueOrFalseElement from './TrueOrfalseElement';
import { ActivityTypeEnum } from '@/models/Activity';
import ActivityProgress, {
  ActivityMode,
  AttemptAnswer,
  QuestionProgress,
} from '@/models/ActivityProgress';
import React from 'react';
import Text from '@/components/common/dataDisplay/Text';
import { useTranslation } from 'react-i18next';
import { HtmlPreview } from '@/components/common/dataDisplay/HtmlPreview';
import { getAnswerByType, questionHasAnswer } from '@/utils/activities';
import QuizFeedback from './quiz/QuizFeedback';
import TrueOrFalseFeedback from './trueOrFalse/TrueOrFalseFeedback';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import getKlassStudents from '@/functions/enrollment';
import StudentAnswer from '@/components/activities/student/StudentAnswer';
import User from '@/models/User';
import DNDOrdenationElement, {
  DNDOrdenationElementProps,
} from './DNDOrdenationElement/DNDOrdenationElement';
import DNDPositionalElement, {
  DNDPositionalElementProps,
} from './DNDPositionalElement/DNDPositionalElement';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import useAuth from '@/data/hook/useAuth';
import { isStudent } from '@/functions/auth';
import {
  activityProgressAttemptsQueryKeys,
  activityProgressesQueryKeys,
  enrollmentsQueryKeys,
} from '@/data/services/querykeys';
import CodeEditorActivityElement from './codeEditor/CodeEditorActivityElement';

export interface ElementPreviewFactoryProps extends QuestionProgress {
  attemptElement?: any;
  activityId?: number;
  activityMode: ActivityMode;
  isAdmin?: boolean;
  hiddenStudents?: boolean;
}

export default function ElementPreviewFactory(
  props: ElementPreviewFactoryProps,
) {
  const { user } = useAuth();
  const { klassId: klassIdParam = '' } = useParams();
  const klassId = Number(klassIdParam);
  const { type, answer, activityMode, order } = props;
  const isFeedbackView = activityMode === 'answerkey';
  const shouldShowFeedback = isFeedbackView && questionHasAnswer(type);

  const studentView = user && isStudent(user.userType);

  const { data: enrollments } = useQuery({
    enabled: !!klassId && !studentView,
    ...enrollmentsQueryKeys.list({ klassId }),
  });

  const students = getKlassStudents(enrollments?.results) || [];

  const feedbackViewHandler =
    shouldShowFeedback &&
    type !== ActivityTypeEnum.DND_ORDENATION &&
    type !== ActivityTypeEnum.DND_POSITIONAL;

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col xl:flex-row gap-4 w-full justify-center">
        <ElementHandler {...props} />
        <StudentAnswers questionOrder={order} students={students} />
      </div>

      <ConditionalRenderer condition={!!answer}>
        <ConditionalRenderer condition={feedbackViewHandler && !!answer}>
          {answer && typeof answer === 'object' && 'reason' in answer && (
            <FeedbackContainer reason={answer.reason}>
              <FeedbackHandler {...props} />
            </FeedbackContainer>
          )}
        </ConditionalRenderer>
      </ConditionalRenderer>
    </div>
  );
}

interface StudentAnswersProps {
  students: User[];
  questionOrder: number;
}

function StudentAnswers({ students, questionOrder }: StudentAnswersProps) {
  const { activityIdParam } = useParams();
  const activityId = Number(activityIdParam);
  const studentsQueries = useQueries({
    queries: students.map(student => ({
      ...activityProgressesQueryKeys.list({
        activityId: [activityId],
        userId: [student.id],
      }),
      enabled: !!students.length && !isNaN(Number(activityId)),
    })),
  });

  return (
    <ConditionalRenderer condition={!!students.length}>
      <div className="flex flex-col gap-2 self-end xl:self-start">
        {studentsQueries.map(({ data: progress }, index) => {
          const student = students[index];
          return (
            <StudentAttempt
              questionOrder={questionOrder}
              student={student}
              activityProgress={progress?.results.at(0)}
              key={student.id}
            />
          );
        })}
      </div>
    </ConditionalRenderer>
  );
}

interface StudentAttemptProps {
  student: User;
  activityProgress?: ActivityProgress;
  questionOrder: number;
}

function StudentAttempt({
  student,
  activityProgress,
  questionOrder,
}: StudentAttemptProps) {
  const { data: attempt, fetchStatus } = useQuery({
    refetchOnWindowFocus: false,
    enabled: !!activityProgress && !!activityProgress.bestAttempt,
    ...activityProgressAttemptsQueryKeys.get({
      activityProgressId: activityProgress?.id ?? 0,
      attemptId: activityProgress?.bestAttempt?.id ?? 0,
    }),
  });
  const attemptContent = attempt?.content?.find(
    ({ order }) => order === questionOrder,
  );
  const attemptAnswer: AttemptAnswer | undefined =
    attemptContent && getAnswerByType(attemptContent);

  return (
    <StudentAnswer
      isLoadingAnswer={fetchStatus === 'fetching'}
      attempt={attemptAnswer}
      student={student}
    />
  );
}

function ElementHandler(props: ElementPreviewFactoryProps) {
  switch (props.type) {
    case ActivityTypeEnum.TEXT:
      return <TextElement {...props} />;

    case ActivityTypeEnum.QUIZ:
      return <QuizElement {...props} />;

    case ActivityTypeEnum.VIDEO:
      return <VideoElement {...props} />;

    case ActivityTypeEnum.TRUE_OR_FALSE:
      return <TrueOrFalseElement {...props} />;

    case ActivityTypeEnum.DND_ORDENATION:
      return <DNDOrdenationElement {...(props as DNDOrdenationElementProps)} />;

    case ActivityTypeEnum.DND_POSITIONAL:
      return <DNDPositionalElement {...(props as DNDPositionalElementProps)} />;

    case ActivityTypeEnum.CODE_EDITOR:
      return <CodeEditorActivityElement {...props} />;

    default:
      return <React.Fragment />;
  }
}

interface FeedbackContainerProps {
  reason: string;
  children: React.ReactNode;
}
function FeedbackContainer({ reason, children }: FeedbackContainerProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.activity.quiz',
  });
  return (
    <div className="flex flex-col w-full gap-7 justify-center items-center xl:col-span-4">
      <hr className="h-px border-secondary-content w-full" />
      <div className="flex flex-col w-full gap-4 items-center">
        <Text
          format="rubik-500"
          text={t('correctAnswer')}
          className="text-secondary"
        />
        {children}
        <HtmlPreview format="book" html={reason} />
      </div>
    </div>
  );
}

function FeedbackHandler(props: ElementPreviewFactoryProps) {
  switch (props.type) {
    case ActivityTypeEnum.QUIZ:
      return <QuizFeedback {...props} />;

    case ActivityTypeEnum.TRUE_OR_FALSE:
      return <TrueOrFalseFeedback {...props} />;

    default:
      return <React.Fragment />;
  }
}
