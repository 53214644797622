import useConfigContext from './config';
import themes from '@/styles/themes';

export default function useTheme() {
  const { userSettings } = useConfigContext();
  const colorTheme = userSettings?.theme ?? 'light';
  const activeTheme = themes[colorTheme];

  return { activeTheme, themeName: colorTheme };
}
