import MainButton from '@/components/common/buttons/MainButton';
import SaveCancelGroup from '@/components/common/buttons/SaveCancelGroup';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Text from '@/components/common/dataDisplay/Text';
import { LocaleDayPicker } from '@/components/common/dataInput/LocaleDayPicker';
import ModalUpdates from '@/components/common/modals/ModalUpdates';
import Popup from '@/components/common/Popup';
import useToggle from '@/data/hook/useToggle';
import { updateKlassStartDate } from '@/data/services/klassServices';
import { unitsQueryKeys } from '@/data/services/querykeys';
import Klass, { weekDaysValues } from '@/models/Klass';
import { getErrorMessage } from '@/utils/getErrorMessage';
import alert from '@/utils/UseAlert';
import { CalendarIcon } from '@heroicons/react/outline';
import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useRef, useState } from 'react';
import { DayOfWeek } from 'react-day-picker';
import { useTranslation } from 'react-i18next';

type KlassStartDateProps = {
  klass: Klass;
  onSucess: () => Promise<void>;
};

const KlassStartDate = ({ klass, onSucess }: KlassStartDateProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix:
      'manageKlasses.dataKlassTable.scheduledLessonProgressView.antecipate',
  });
  const initialKlassStartDate = moment(klass.klassStartDate).toDate();
  const [klassStartDate, setKlassStartDate] = useState<Date | undefined>(
    initialKlassStartDate,
  );
  const klassStartDateMoment = moment(klassStartDate);

  const { close, toggle, isOpen } = useToggle();

  const { data: unit } = useQuery({
    ...unitsQueryKeys.get(klass.unitId),
  });

  const recessDates = unit?.recess?.map(date => moment(date).toDate()) ?? [];

  const klassWeekDays =
    klass.dayTimes?.reduce((acc, { day }) => {
      if (day) acc.push(weekDaysValues[day]);
      return acc;
    }, new Array<number>()) ?? [];

  const weekdays = Object.values(weekDaysValues);

  const weekDayBlocked: DayOfWeek = {
    dayOfWeek: weekdays.filter(value => !klassWeekDays.includes(value)),
  };

  const disableDatesMacther = [weekDayBlocked, ...recessDates];

  const handleSave = async () => {
    const klassStartDateFormated = moment(klassStartDate).format('YYYY-MM-DD');
    await updateKlassStartDate(klass.id, {
      klassStartDate: klassStartDateFormated,
    });
  };

  const { mutate: onSave, isLoading } = useMutation(handleSave, {
    onSuccess: async () => {
      alert.success(t('save'));
      close();
      await onSucess();
    },
    onError: err => {
      setKlassStartDate(initialKlassStartDate);
      alert.error(getErrorMessage(err));
    },
  });
  const hasChanges = initialKlassStartDate.toISOString();

  const klassStartDateDisplay = klassStartDateMoment.isValid()
    ? klassStartDateMoment.format('DD MMM YYYY')
    : t('noDate');

  const targetRef = useRef<HTMLDivElement>(null);

  const onCancel = () => {
    close();
    setKlassStartDate(initialKlassStartDate);
  };
  return (
    <div className="flex gap-2" ref={targetRef}>
      <div className="flex gap-1.5 items-center ">
        <Text
          text={t('start')}
          format="rubik-700"
          className="self-center h-fit"
        />
        <Text
          text={`${klassStartDateDisplay}`}
          format="rubik-400"
          className="self-center h-fit capitalize"
        />
      </div>
      <Popup
        opened={isOpen}
        hover
        placement="bottom-start"
        reference={
          <MainButton
            onClick={toggle}
            color="custom"
            className="text-primary gap-1 shrink"
            text={t('button')}
            icon={<CalendarIcon className="relative shrink w-4" />}
          />
        }
      >
        <ConditionalRenderer condition={isOpen}>
          <LocaleDayPicker
            mode="single"
            onSelect={setKlassStartDate}
            selected={klassStartDate}
            disabled={disableDatesMacther}
            footer={
              <SaveCancelGroup
                className="w-full justify-end mt-2"
                loading={isLoading}
                save={{
                  onClick: onSave,
                  type: 'button',
                  disabled: klassStartDate?.toISOString() === hasChanges,
                }}
                cancel={{
                  onClick: onCancel,
                }}
              />
            }
          />
        </ConditionalRenderer>
      </Popup>
      <ModalUpdates
        keyword="changeKlassStartDate"
        target={targetRef.current}
        placement="bottom-start"
      />
    </div>
  );
};

export default KlassStartDate;
