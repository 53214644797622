import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useAuth from '../../data/hook/useAuth';
import { UserTypeEnum } from '../../models/User';
import Skeleton from '../common/Skeleton';
import LessonItemsList from './LessonItemsList';
import Text from '../common/dataDisplay/Text';
import useLessonContext from '../../data/hook/lesson';

interface ListItem {
  testId: string;
  title: string;
  url: string;
  role: (UserTypeEnum | 'ALL')[];
  completed: boolean;
  hasItem: boolean;
}
const ListItemSkeleton = () => {
  return <Skeleton className="w-48 h-8 rounded-md bg-primary-content" />;
};

export default function InitialView() {
  const { user } = useAuth();
  const { lessonId, slugCourseName, klassId } = useParams();
  const { lesson, lessonProgress, scheduledLesson, resourceProgress } =
    useLessonContext();
  const { toolUrl, instructionsUrl, activities, resources } = lesson || {};

  const {
    hasAccessedTool,
    hasWatchedInstruction,
    homeworkProgress,
    bookProgress,
  } = lessonProgress || {};

  const hasHomework = !!scheduledLesson?.homeworkActivity;

  const hasActivities = !!activities && !!activities.length;

  const activityIconNotification = lessonProgress?.activitiesProgress.reduce(
    (acc, activity) => (!activity.hasDone ? ++acc : acc),
    0,
  );

  const hasDoneResources = resourceProgress?.reduce(
    (prev, { hasOpened }) => (hasOpened ? prev + 1 : prev),
    0,
  );

  const hasDoneActivity = activityIconNotification === 0;

  const hasDoneHomework =
    activityIconNotification === 0 &&
    !!homeworkProgress &&
    (!!homeworkProgress.activityProgress.grade ||
      homeworkProgress.activityProgress.grade === 0);

  const isChallengeDone = !!lessonProgress?.challengeProgress?.hasDone;

  const hasAccessAllToolInfo = hasAccessedTool && hasWatchedInstruction;
  const { t } = useTranslation('translation', { keyPrefix: 'klassView' });
  const klassUrl = klassId ? `/class/${klassId}` : '';
  const baseUrl = `/courses/${slugCourseName}/lesson/${lessonId}${klassUrl}`;

  const hasAccessTool = !!(instructionsUrl || toolUrl);
  const hasStudentMaterial = !!lesson?.book;
  const hasTeacherMaterial = !!lesson?.teacherBook;
  const hasChalenge = !!lesson?.challenge;
  const isCompletedBook = !!bookProgress && bookProgress.progress >= 1;
  const hasResources = !!resources?.length;

  const shouldRenderNoContent =
    !hasAccessTool &&
    !hasStudentMaterial &&
    !hasTeacherMaterial &&
    !hasActivities &&
    !hasChalenge &&
    !hasResources;

  const activitiesCompleted = () => {
    if (hasHomework && hasActivities) {
      return hasDoneActivity && hasDoneHomework;
    } else if (hasActivities && !hasHomework) return hasDoneActivity;
    else if (hasHomework && !hasActivities) return hasDoneHomework;
    else return false;
  };

  const items: ListItem[] = [
    {
      testId: 'toolAccessList',
      title: t('toolAccess'),
      url: baseUrl + '/help',
      completed: !!hasAccessAllToolInfo,
      role: ['ALL'],
      hasItem: hasAccessTool,
    },
    {
      testId: 'studentMaterialList',
      title:
        user?.userType === UserTypeEnum.STUDENT
          ? t('studentMaterial')
          : t('material'),
      url: baseUrl + '/book/chapter/1',
      completed: isCompletedBook,
      role: ['ALL'],
      hasItem: hasStudentMaterial,
    },
    {
      testId: 'teacherMaterialList',
      title: t('teacherMaterial'),
      url: baseUrl + '/t/book/chapter/1',
      completed: false,
      role: [
        UserTypeEnum.TEACHER,
        UserTypeEnum.SUPER_ADMIN,
        UserTypeEnum.UNIT_ADMIN,
      ],
      hasItem: hasTeacherMaterial,
    },
    {
      testId: 'activityList',
      title: t('activity'),
      url: baseUrl + '/activities',
      completed: activitiesCompleted(),
      role: ['ALL'],
      hasItem: hasActivities || hasHomework,
    },
    {
      testId: 'challengeList',
      title: t('challenge'),
      url: baseUrl + '/challenge',
      completed: isChallengeDone,
      role: ['ALL'],
      hasItem: hasChalenge,
    },
    {
      testId: 'resourceList',
      title: t('resources'),
      url: baseUrl + '/resources',
      completed: hasDoneResources === resourceProgress?.length,
      role: ['ALL'],
      hasItem: hasResources,
    },
  ];

  return (
    <div className="p-9 shadow-default shadow-primary-content rounded-xl flex flex-col gap-8">
      {shouldRenderNoContent ? (
        <Text text={t('noContent')} />
      ) : (
        <Text
          id="initialKlassView"
          text={t('initialView')}
          format="poppins-600"
          className="text-center text-primary text-20"
        />
      )}
      <ul className="list-none flex flex-col w-fit gap-6 p-6">
        {!isNaN(Number(lessonId)) ? (
          <React.Fragment>
            {items
              .filter(item => item.hasItem === true)
              .map(item => (
                <LessonItemsList
                  testId={item.testId}
                  key={item.title}
                  title={item.title}
                  url={item.url}
                  completed={item.completed}
                  role={item.role}
                />
              ))}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {items.map((_item, index) => (
              <li key={index}>
                <ListItemSkeleton />
              </li>
            ))}
          </React.Fragment>
        )}
      </ul>
    </div>
  );
}
