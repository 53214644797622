import React, { useEffect, useState } from 'react';
import useAuth from '@/data/hook/useAuth';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import Layout from '@/components/template/Layout';
import PageTitle from '@/components/common/PageTitle';
import ProfileBanner from '@/components/profile/ProfileBanner';
import { useTranslation } from 'react-i18next';
import { ProfileUserCard } from '@/components/profile/ProfileUserCard';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import CancelSaveGroup from '@/components/common/buttons/SaveCancelGroup';
import MainButton from '@/components/common/buttons/MainButton';
import { PencilIcon } from '@heroicons/react/outline';
import ProfileBio from '@/components/profile/ProfileBio';
import CheckList from '@/components/profile/ProfileCheckList';
import ProfileLinks from '@/components/profile/ProfileLinks';
import { SubmitHandler, useForm } from 'react-hook-form';
import UserProfile, { MediaName, SocialMedia } from '@/models/UserProfile';
import {
  updateUserProfile,
  updateUserProfileBanner,
} from '@/data/services/userProfileServices';
import { buildChangedObject } from '@/utils/buildChangedObject';
import { isStudent } from '@/functions/auth';
import LoadingView from './courses/LoadingView';
import alert from '@/utils/UseAlert';
import ErrorComponent from '@/components/common/ErrorComponent';
import {
  meQueryKeys,
  simplifiedCourseProgressesQueryKeys,
  usersQueryKeys,
} from '@/data/services/querykeys';
import useListService from '@/data/hook/useListService';
import SkillsCard from '@/components/profile/SkillsCard';

export interface ProfileInputs {
  bio: string;
  socialMedia: SocialMedia[];
  bannerUrl: string | null;
}

export default function ProfilePage() {
  const [isRequesting, setIsRequesting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const { t } = useTranslation('translation', { keyPrefix: 'profile' });
  const { user: authUser, isLoading } = useAuth();
  const { username } = useParams();
  const queryClient = useQueryClient();

  const isUser = username === authUser?.username;
  const { results: users, isInitialLoading: isLoadingVisit } = useListService({
    ...usersQueryKeys.list({ username }),
    enabled: !isUser && authUser !== undefined && !!username,
  });
  const visitUser = users.at(0);

  const user = isUser ? authUser : visitUser;
  const profile = user?.profile;

  const { handleSubmit, control, reset } = useForm<ProfileInputs>({
    defaultValues: {
      bio: profile?.bio || '',
      socialMedia: profile?.socialMedia || [
        { mediaUrl: '', mediaName: MediaName.OTHER },
      ],
    },
  });

  const isActive = isUser ? undefined : true;

  const { results: studentProgress, isInitialLoading: isLoadingProgress } =
    useListService({
      enabled: !!user && isStudent(user.userType),
      ...simplifiedCourseProgressesQueryKeys.list(user?.id ?? 0, {
        isActive,
      }),
    });

  useEffect(() => {
    if (profile)
      reset({
        bio: profile.bio || '',
        socialMedia: profile.socialMedia?.length
          ? profile.socialMedia
          : [{ mediaUrl: '', mediaName: MediaName.OTHER }],
      });
  }, [profile, reset]);

  const validateMediaUrls = (media: SocialMedia) => ({
    ...media,
    mediaUrl:
      `https://www.` +
      media.mediaUrl
        .replace('www.', '')
        .replace('http://', '')
        .replace('https://', '')
        .replace('https:/', '')
        .replace('htps:/', '')
        .replace('htp:/', '')
        .replace('http:/', ''),
  });

  const onSubmit: SubmitHandler<ProfileInputs> = async ({
    bio,
    socialMedia,
    bannerUrl,
  }) => {
    setIsRequesting(true);
    const parsedSocialMedia = socialMedia
      .filter(media => media.mediaUrl)
      .map(validateMediaUrls);

    try {
      if (user && profile) {
        let changedFields = buildChangedObject<Partial<UserProfile>>(profile, {
          bio,
          socialMedia: parsedSocialMedia,
        });

        if (bannerUrl) {
          await updateUserProfileBanner(user.profile.id, bannerUrl);
        }

        if (Object.keys(changedFields).length) {
          await updateUserProfile(user.profile.id, changedFields);
        }
      }
      alert.success(t('saveSuccess'));
      setIsEdit(false);
      setHasChanges(false);
      queryClient.invalidateQueries(meQueryKeys.get._def);
    } catch (error) {
      alert.error(t('saveError'));
    } finally {
      setIsRequesting(false);
    }
  };

  if (isLoading || isLoadingVisit) {
    return (
      <Layout>
        <LoadingView />
      </Layout>
    );
  }

  if (!isUser && !user) {
    return (
      <Layout>
        <ErrorComponent
          errorTextTitle={t('notFoundTitle')}
          errorTextSubTitle={t('notFoundSubtitle')}
        />
      </Layout>
    );
  }

  if (user) {
    return (
      <Layout>
        <PageTitle
          headingText={isUser ? t('title') : user.username}
          position="mb-2"
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ProfileBanner
            profile={profile}
            isLoading={isLoading || isLoadingVisit}
            isEdit={isEdit}
            setHasChanges={setHasChanges}
            control={control}
            name="bannerUrl"
          />
          <div className="flex flex-col lg:flex-row mx-1.5 mb-10 lg:mb-0 gap-3.5 lg:gap-10">
            <ProfileUserCard
              isUser={isUser}
              user={user}
              studentProgress={studentProgress}
              isLoadingProgress={isLoadingProgress}
              isLoading={isLoading || isLoadingVisit}
            />
            <div className="flex w-full flex-col lg:pt-3.5 lg:flex-wrap gap-3.5">
              {isUser ? (
                <div className="flex flex-row transition-all duration-150 ease-in justify-end gap-2.5">
                  <MainButton
                    dataTestId="editProfileButton"
                    onClick={() => {
                      setIsEdit(true);
                    }}
                    type="button"
                    disabled={isEdit}
                    className="md:order-first"
                    icon={<PencilIcon />}
                    text={t('editProfile')}
                  />
                </div>
              ) : (
                <div className="h-7" />
              )}
              <div className="flex flex-col md:flex-row gap-3.5">
                <ProfileBio
                  name="bio"
                  control={control}
                  isEdit={isEdit}
                  setHasChanges={setHasChanges}
                  bio={profile?.bio}
                />
                <SkillsCard user={user} isCurrentUser={isUser} />
              </div>
              <CheckList shouldRender={isUser && !isEdit} profile={profile} />
              <ProfileLinks
                control={control}
                setHasChanges={setHasChanges}
                shouldRender={isUser && isEdit}
              />
              <ConditionalRenderer condition={isEdit && isUser}>
                <CancelSaveGroup
                  cancel={{
                    onClick: () => {
                      setIsEdit(false);
                      reset();
                      setHasChanges(false);
                    },
                  }}
                  save={{ disabled: !hasChanges, testId: 'saveButtonProfile' }}
                  className="flex self-start"
                  loading={isRequesting}
                />
              </ConditionalRenderer>
            </div>
          </div>
        </form>
      </Layout>
    );
  }
  return <React.Fragment />;
}
