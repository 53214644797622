import { CourseCategoryEnum, CourseTypeEnum } from '@/models/Course';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import CoursePathItem from '@/components/admin/courses/CoursePathItem';
import CourseTypeBar from './CourseTypeBar';
import { LoadingIcon } from '@/components/icons';
import Skeleton from '@/components/common/Skeleton';
import InfinityList from '@/components/common/InfinityList';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { coursePathsQueryKeys } from '@/data/services/querykeys';

export type CoursePathListProps = {
  type: CourseTypeEnum;
  first?: boolean;
  category?: CourseCategoryEnum;
};

export default function CoursePathList({
  first,
  category,
  type,
}: CoursePathListProps) {
  const {
    results: coursePathList,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isLoading,
  } = useInfiniteService({
    ...coursePathsQueryKeys.list({
      category,
      courseType: type,
      status: [VersioningStatusEnum.PUBLISHED],
      uniqueCourse: true,
    })._ctx.infinity,
  });

  if (isLoading) {
    return (
      <div className="flex flex-col gap-4">
        <Skeleton className="w-full h-14 rounded-lg bg-secondary-content" />
        <div className="w-full flex justify-center items-center h-30">
          <LoadingIcon className="text-secondary h-8 w-8" />
        </div>
      </div>
    );
  }
  return (
    <ConditionalRenderer condition={coursePathList?.length}>
      <div className="flex flex-col gap-4">
        <CourseTypeBar type={type} first={first} />
        <InfinityList
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          onReachEnd={fetchNextPage}
          className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-2.5"
        >
          {coursePathList.map(coursePath => (
            <CoursePathItem key={coursePath.id} coursePath={coursePath} />
          ))}
        </InfinityList>
      </div>
    </ConditionalRenderer>
  );
}
