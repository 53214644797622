import { Section } from '@/models/Book';
import React, { Fragment } from 'react';
import ConditionalRenderer from '../../../../ConditionalRenderer';
import { fixedLayouts } from '@/utils/material/chapter/chapterLayout';
import FlexLayout from './FlexLayout';
import ResponsiveGridLayout from './ResponsiveGridLayout';
import { LayoutChapter } from '@/models/ChapterLayout';

type SectionsLayoutProps = {
  sections: Section[];
  layout: LayoutChapter;
};
const ChapterLayoutView: React.FC<SectionsLayoutProps> = ({
  sections,
  layout,
}) => {
  return (
    <Fragment>
      <ConditionalRenderer condition={layout.type === 'custom'}>
        {layout.type === 'custom' && (
          <ResponsiveGridLayout sections={sections} layout={layout} readOnly />
        )}
      </ConditionalRenderer>
      <ConditionalRenderer condition={layout.type !== 'custom'}>
        <FlexLayout
          sections={sections}
          cols={fixedLayouts[layout.type]}
          readOnly
        />
      </ConditionalRenderer>
    </Fragment>
  );
};

export default ChapterLayoutView;
