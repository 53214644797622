import ConditionalRenderer from './ConditionalRenderer';
import { UserTypeEnum } from '@/models/User';
import ComponentGuard from './ComponentGuard';
import { Tooltip } from './dataDisplay/Tooltip';
import Tag from './dataDisplay/Tag';

type ConectaTagProps = {
  render?: boolean;
};

export default function ConectaTag(props: ConectaTagProps) {
  const { render } = props;

  return (
    <ConditionalRenderer condition={render}>
      <ComponentGuard
        roles={[
          UserTypeEnum.SUPER_ADMIN,
          UserTypeEnum.UNIT_ADMIN,
          UserTypeEnum.TEACHER,
        ]}
      >
        <Tooltip text="Conecta" color="lightPurple">
          <Tag
            color="custom"
            text="C"
            className="aspect-square p-0 w-5 max-w-none flex justify-center items-center bg-secondary text-base-100 text-[0.875rem] pt-0.5"
          />
        </Tooltip>
      </ComponentGuard>
    </ConditionalRenderer>
  );
}
