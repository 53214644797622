import Activity, {
  ActivityMeta,
  implementsOfActivity,
} from '@/models/Activity';
import { Lesson, LessonMeta, implementsOfLesson } from '@/models/Lesson';
import React from 'react';
import { ExternalModalBankProps, UseFunctions } from '../ModalBank';
import { CreateActivityCard } from './CreateActivityCard';
import { CreateLessonCard } from './CreateLessonCard';
import { useShouldShowItem } from '../ListBankCards.hooks';

type LessonWithMeta = Lesson & LessonMeta;
type ActivityWithMeta = Activity & ActivityMeta;
export type Item = LessonWithMeta | ActivityWithMeta;

type CardsFactoryProps = {
  item: Item;
  checkable?: boolean;
  disabledAnimation?: boolean;
  loading?: boolean;
} & UseFunctions &
  ExternalModalBankProps;

export default function CardsFactory({
  item,
  onUseLesson,
  onUseActivity,
  ...rest
}: CardsFactoryProps) {
  const shouldShowBankItem = useShouldShowItem(item);

  if (!shouldShowBankItem) return <React.Fragment />;

  if (implementsOfActivity(item)) {
    return <CreateActivityCard item={item} onUse={onUseActivity} {...rest} />;
  }
  if (implementsOfLesson(item)) {
    return <CreateLessonCard item={item} onUse={onUseLesson} {...rest} />;
  }
  return <React.Fragment />;
}
