import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import requester from '../../axios';
import RoundedButton from '../../components/common/buttons/RoundedButton';
import Text from '../../components/common/dataDisplay/Text';
import PasswordInput from '../../components/common/dataInput/PasswordInput';
import HeadTitle from '../../components/common/HeadTitle';
import LinkText from '../../components/common/LinkText';
import ResetPasswordModal from '../../components/login/ResetPasswordModal';
import { ApiError, ErrorDetail } from '@/models/Errors';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { CircleIcon } from '@/components/icons';
import Popup from '@/components/common/Popup';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { camelCase } from 'lodash';

interface Inputs {
  newPassword: string;
  reNewPassword: string;
}

interface IRequestBody extends Inputs {
  uid: string;
  token: string;
}

function ResetPassword() {
  const { t: tResetPassword } = useTranslation('translation', {
    keyPrefix: 'login.resetPassword',
  });

  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const { token, uid } = useParams();
  const [isRequesting, setIsRequesting] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { register, handleSubmit, reset } = useForm<Inputs>();
  const navigate = useNavigate();

  const [errors, setErrors] = useState<ErrorDetail<string>[]>([]);

  function showResetPasswordError(
    errors: ErrorDetail<string>[],
    seconds = 5,
  ): void {
    setErrors(errors);
    setTimeout(() => setErrors([]), seconds * 1000);
  }

  const onSubmit: SubmitHandler<Inputs> = async ({
    newPassword,
    reNewPassword,
  }) => {
    try {
      setErrors([]);
      setIsRequesting(true);
      if (!token || !uid) {
        throw new Error('Token ou uid não encontrados');
      }
      if (newPassword !== reNewPassword) {
        return setErrors(prev => [
          ...prev,
          {
            code: 'invalid',
            detail: tResetPassword('differentPasswordError'),
          },
        ]);
      }

      await requester().post<void, null, IRequestBody>(
        'users/reset_password_confirm/',
        {
          newPassword,
          reNewPassword,
          token,
          uid,
        },
      );
      reset();
      setOpenModal(true);
    } catch (err: any) {
      const apiError = new ApiError(err);
      showResetPasswordError(apiError.errors);
    } finally {
      setIsRequesting(false);
    }
  };

  return (
    <div className="flex flex-col gap-2.5 w-[20rem]">
      <HeadTitle routeInfo={tResetPassword('redefinePassword')} />
      <Text
        className="text-primary text-center pb-1"
        text={tResetPassword('redefinePassword')}
        format="poppins-600"
        size="text-28"
      />
      <ConditionalRenderer condition={errors.length}>
        <ul className="flex flex-col gap-1 self-center rounded-md text-error bg-error-content text-[0.875rem] p-2.5">
          {errors.map((error, index) => (
            <li key={error.code + index} className="flex gap-1.5 items-center">
              <CircleIcon className="w-2 h-2 shrink-0" />
              <div className="flex gap-1">
                {error.detail}
                <ConditionalRenderer condition={error.field}>
                  <Popup
                    className={{ reference: 'self-center' }}
                    reference={
                      <InformationCircleIcon className="w-4 h-4 shrink-0 cursor-pointer transition-all duration-150 ease-in-out hover:opacity-40" />
                    }
                    disabled={!error.field}
                  >
                    <span className="flex flex-col bg-base-100 rounded-xl border-neutral-content border shadow-default p-3.5 gap-3.5 w-full text-base-content">
                      {t(`form.${camelCase(error.field)}.title`)}
                    </span>
                  </Popup>
                </ConditionalRenderer>
              </div>
            </li>
          ))}
        </ul>
      </ConditionalRenderer>

      <form
        className="flex flex-col gap-6"
        data-testid="resetPasswordForm"
        onSubmit={handleSubmit(onSubmit)}
      >
        <PasswordInput
          placeholder={tResetPassword('typeNewPassword')}
          register={register('newPassword')}
          aria-label="new-password"
          name="newPassword"
          label={tResetPassword('newPassword')}
          typeInput="login"
          className={{ input: 'mx-auto' }}
          fontSize="text-14"
        />
        <PasswordInput
          placeholder={tResetPassword('typeNewPassword')}
          register={register('reNewPassword')}
          aria-label="confirm-new-password"
          name="reNewPassword"
          typeInput="login"
          className={{ input: 'mx-auto' }}
          label={tResetPassword('confirmNewPassword')}
          fontSize="text-14"
        />
        <RoundedButton
          loading={isRequesting}
          className="mx-auto whitespace-nowrap"
          text={tResetPassword('confirmRedefine')}
          color="gradient"
          type="submit"
        />
      </form>
      <hr className="my-5 h-px border-neutral/50" />
      <div className="flex gap-2 items-center">
        <Text text={tResetPassword('alreadyRegister')} format="rubik-400" />
        <LinkText text={tResetPassword('login')} href="/login" />
      </div>
      {openModal && <ResetPasswordModal onClose={() => navigate('/login')} />}
    </div>
  );
}

export default ResetPassword;
