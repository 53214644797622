import Tag from '@/components/common/dataDisplay/Tag';
import { StudentAssessment } from '@/models/StudentAssessment';
import User, { UserTypeEnum } from '@/models/User';
import { fadeIn } from '@/utils/animations/commom';
import { DocumentTextIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';
import { useState } from 'react';
import ComponentGuard from '@/components/common/ComponentGuard';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import CommentAssessmentForm from './StudentCommentAssessmentForm/CommentAssessmentForm';
import BehaviorAssessmentForm from './StudentBehaviorAssessmentForm/BehaviorAssessmentForm';
import Assessments from './StudentAssessments/Assessments';
import { SheetTab } from '@/models/SheetTab';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import ToggleButtonGroup, {
  ToggleButton,
} from '@/components/common/dataInput/ToggleGroup';
import { getErrorMessage } from '@/utils/getErrorMessage';
import {
  pedagogicalSheetsQueryKeys,
  studentAssessmentsQuerykeys,
} from '@/data/services/querykeys';
import useInfiniteService from '@/data/hook/useInfiniteService';

export type EditAssessment = {
  tab: SheetTab;
  assessment: StudentAssessment;
};

export type PedagogicalSheetProps = {
  student: User;
};

export default function PedagogicalSheet({ student }: PedagogicalSheetProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pedagogicalSheet',
  });

  const [studentAssessment, setStudentAssessment] =
    useState<StudentAssessment>();

  const {
    data: pedagogicalSheetResponse,
    isInitialLoading: isLoadingSheet,
    error: pedagogicalSheetError,
  } = useQuery({
    ...pedagogicalSheetsQueryKeys.list({ studentId: student.id }),
    enabled: !!student.id,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const [pedagogicalSheet] = pedagogicalSheetResponse?.results ?? [];

  const sheetError = getErrorMessage(pedagogicalSheetError);

  const {
    results: assessments,
    isInitialLoading: isLoadingAssessments,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    error: studentAssessmentsError,
    invalidate: updateAssessments,
  } = useInfiniteService({
    ...studentAssessmentsQuerykeys.list({
      studentId: student.id,
      pageSize: 5,
    })._ctx.infinity,

    keepPreviousData: true,
    enabled: !!student.id && !!pedagogicalSheet,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const assessmentError = getErrorMessage(studentAssessmentsError);

  const isLoading = isLoadingSheet || isLoadingAssessments;

  type Tabs = {
    [key in SheetTab]: JSX.Element;
  };

  const [tab, setTab] = useState<SheetTab>('view');

  function changeTab(changed: SheetTab) {
    if (changed === tab) setTab('view');
    else setTab(changed);
  }

  const editAssessment = ({ assessment, tab }: EditAssessment) => {
    setStudentAssessment(assessment);
    changeTab(tab);
  };

  const tabs: Tabs = {
    view: (
      <Assessments
        student={student}
        assessments={assessments}
        isLoading={isLoading}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        editAssessment={editAssessment}
        error={sheetError || assessmentError}
        tab={tab}
      />
    ),
    comment: (
      <CommentAssessmentForm
        pedagogicalSheetId={pedagogicalSheet?.id}
        updateAssessments={updateAssessments}
        studentAssessment={studentAssessment}
        changeTab={changeTab}
        student={student}
      />
    ),
    behavior: (
      <BehaviorAssessmentForm
        student={student}
        pedagogicalSheetId={pedagogicalSheet?.id}
        updateAssessments={updateAssessments}
        studentAssessment={studentAssessment}
        changeTab={changeTab}
        tab={tab}
      />
    ),
  };

  const buttons: ToggleButton<SheetTab>[] = [
    {
      testId: 'addAssessmentButton',
      text: t('assessment'),
      value: 'behavior',
      disabled: isLoading,
    },
    {
      testId: 'addCommentButton',
      text: t('comment'),
      value: 'comment',
      disabled: isLoading,
    },
  ];

  return (
    <motion.div className="flex flex-col w-full gap-2 px-8 py-5" {...fadeIn}>
      <Tag text={t('title')} icon={<DocumentTextIcon className="w-6 h-6" />} />

      <ComponentGuard
        roles={[
          UserTypeEnum.SUPER_ADMIN,
          UserTypeEnum.TEACHER,
          UserTypeEnum.UNIT_ADMIN,
        ]}
      >
        <ToggleButtonGroup
          buttons={buttons}
          value={tab}
          onChange={tab => {
            setStudentAssessment(undefined);
            changeTab(tab);
          }}
          exclusive
        />
      </ComponentGuard>

      {tabs[tab]}
    </motion.div>
  );
}
