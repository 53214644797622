import { decamelizeKeys } from 'humps';
import requester from '@/axios';
import { Course, CourseTypeEnum, ICourse } from '@/models/Course';
import { BaseFilters, ListServiceFunc } from '@/models/Service';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';

export type CourseBody = Omit<Course, 'id' | 'bannerImg'> & {
  bannerImg: File | string | null;
};

export async function updateCourse(
  courseId: number,
  course: Partial<CourseBody>,
) {
  const formData = new FormData();

  Object.entries(course).forEach(([key, value]) => {
    if (key === 'bannerImg' && !value) {
      formData.set(key, '');
    }

    if (value) {
      formData.set(key, value instanceof File ? value : value.toString());
    }
  });

  await requester().patch<Course>(`courses-bases/${courseId}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    },
  });
}

export async function addCourse(
  course: Partial<CourseBody>,
  bannerImg: string | File | null,
): Promise<Course> {
  const formData = new FormData();

  Object.entries(course).forEach(([key, value]) => {
    if (key === 'isActive' || key === 'bannerImg') return;

    if (value) {
      formData.set(key, value instanceof File ? value : value.toString());
    }
  });

  if (bannerImg) formData.set('bannerImg', bannerImg);

  formData.set(
    'course',
    new Blob([JSON.stringify(decamelizeKeys(course))], {
      type: 'application/json',
    }),
  );

  const { data } = await requester().post<Course>('courses/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    },
  });

  return data;
}

export interface CourseBasesFilters extends BaseFilters {
  status?: VersioningStatusEnum;
}
export interface ListCourseBaseFilters {
  category?: string[];
  pageSize?: number;
  pageNumber?: number;
  search?: string;
  courseType?: CourseTypeEnum[];
  coursePathStatus?: VersioningStatusEnum[];
  courseId?: number[];
}

export const listCoursesBases: ListServiceFunc<
  ICourse,
  ListCourseBaseFilters
> = async params => {
  const { data } = await requester().get('courses-bases/', {
    params: { ...params, courseId: params?.courseId?.toString() },
  });
  return data;
};

export async function deleteCourse(id: number) {
  await requester().delete(`courses-bases/${id}/`);
}
