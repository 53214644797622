import { AlertTypeEnum } from '@/models/Alert';
import {
  CalendarIcon,
  ClipboardIcon,
  ClockIcon,
} from '@heroicons/react/outline';
import { TFunction } from 'i18next';
import { ElementType } from 'react';
import { useTranslation } from 'react-i18next';
import Tag from '../../common/dataDisplay/Tag';

type NotificationTagProps = {
  type: AlertTypeEnum;
};

type TagsStyles = Record<
  AlertTypeEnum,
  {
    className: {
      bg: string;
      text: string;
    };
    text: string;
    icon: ElementType;
  }
>;

export const getAlertTagsStyles = (
  t: TFunction<'translation', undefined>,
): TagsStyles => ({
  [AlertTypeEnum.ABSENCE]: {
    className: { bg: 'bg-warning', text: 'text-warning' },
    text: t(`alerts.types.${AlertTypeEnum.ABSENCE}`),
    icon: CalendarIcon,
  },
  [AlertTypeEnum.LATENESS]: {
    className: { bg: 'bg-info', text: 'text-info' },
    text: t(`alerts.types.${AlertTypeEnum.LATENESS}`),
    icon: ClockIcon,
  },
  [AlertTypeEnum.HOMEWORK_NOT_DONE]: {
    className: { bg: 'bg-accent', text: 'text-accent' },
    text: t(`alerts.types.${AlertTypeEnum.HOMEWORK_NOT_DONE}`),
    icon: ClipboardIcon,
  },
});

const NotificationTag = ({ type }: NotificationTagProps) => {
  const { t } = useTranslation('translation');
  const tagsStyles = getAlertTagsStyles(t);
  const { text, className, icon: Icon } = tagsStyles[type];

  return (
    <Tag
      icon={<Icon className="w-3.5 h-3.5" strokeWidth="3" />}
      text={text}
      testId="notificationTag"
      color="custom"
      className={`px-1.5 py-1 !text-14 gap-1 text-base-100 ${className.bg}`}
    />
  );
};

export default NotificationTag;
