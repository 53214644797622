import { onEditBookFn } from '@/components/admin/lessons/material/Material.hooks';
import {
  createBook,
  deleteBook,
  GetBookParams,
  updateBook,
} from '@/data/services/bookServices';
import {
  createChapter,
  updateChaptersOrder,
} from '@/data/services/chapterServices';
import { createSection } from '@/data/services/sectionServices';
import Book, { Section } from '@/models/Book';
import Chapter from '@/models/Chapter';
import { getErrorMessage } from '@/utils/getErrorMessage';
import alert from '@/utils/UseAlert';
import { useMutation } from '@tanstack/react-query';
import BookForm from './BookForm';

type BookServiceProps = {
  book: Book;
  onSubmit: (book: Book) => void;
  onDeleteBook?: () => void;
  onEditBook?: onEditBookFn;
  isEditing?: boolean;
  readOnly?: boolean;
} & GetBookParams;

type HandleEditArgs = {
  book: Partial<Book>;
  validated: boolean;
};

const BookService = ({
  book,
  readOnly,
  onSubmit,
  onDeleteBook: onDeleteBookEvent,
  onEditBook,
  isEditing,
  ...bookParams
}: BookServiceProps) => {
  const handleCreate = async () => {
    return createBook(bookParams);
  };

  const handleUpdate = (changes: Partial<Book>) => {
    return updateBook(bookParams, changes);
  };

  const handleEdit = async ({
    book: bookChanged,
    validated,
  }: HandleEditArgs) => {
    if (!validated) return;
    return book.id ? handleUpdate(bookChanged) : handleCreate();
  };

  const { mutate: onEdit } = useMutation(handleEdit, {
    onSuccess: book => {
      if (!book) return;
      onSubmit(book);
    },
    onError: error => {
      const errorMessage = getErrorMessage(error);
      alert.error(errorMessage);
    },
  });
  const handleAddChapter = async () => {
    const chapters = book.chapters || [];
    const newChapter = await createChapter(bookParams, {
      content: '',
    });
    const section = await createSection(
      { ...bookParams, chapterId: newChapter.id },
      new Section({ chapterId: undefined }),
    );
    newChapter.sections = [section];
    chapters.push(newChapter);
    onSubmit({ ...book, chapters: chapters });
    return newChapter;
  };

  const { mutateAsync: onAddChapter, isLoading: isAddingChapter } = useMutation(
    handleAddChapter,
    {
      onError: err => {
        const errMessage = getErrorMessage(err);
        alert.error(errMessage);
      },
    },
  );
  const handleDeleteChapter = (deleteChapter: Chapter) => {
    const chapters = book.chapters || [];
    const newSections = chapters.filter(
      chapter => chapter.id !== deleteChapter.id,
    );
    onSubmit({ ...book, chapters: newSections });
  };

  const handleUpdateChapter = (updatedChapter: Chapter) => {
    const chapters = book.chapters || [];
    const newChapters = chapters.map(chapter =>
      chapter.id === updatedChapter.id ? updatedChapter : chapter,
    );
    onSubmit({ ...book, chapters: newChapters });
  };

  const { mutate: onDeleteBook, isLoading: isDeletingBook } = useMutation(
    () => deleteBook(bookParams),
    {
      onSuccess: onDeleteBookEvent,
      onError: err => {
        const errMessage = getErrorMessage(err);
        alert.error(errMessage);
      },
    },
  );

  const handleOnReorderChapters = (chapters: Chapter[]) => {
    updateChaptersOrder({
      params: bookParams,
      chapters: chapters.map(({ id }) => ({ id })),
    });
    onSubmit({ ...book, chapters });
  };

  return (
    <BookForm
      book={book}
      onSubmit={onEdit}
      onDeleteChapter={handleDeleteChapter}
      readOnly={readOnly}
      onDeleteBook={onDeleteBook}
      onUpdateChapter={handleUpdateChapter}
      onAddChapter={onAddChapter}
      onEditBook={onEditBook}
      isEditingBook={isEditing}
      isAddingChapter={isAddingChapter}
      isDeletingBook={isDeletingBook}
      onReorderChapters={handleOnReorderChapters}
      {...bookParams}
    />
  );
};

export default BookService;
