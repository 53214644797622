import IconButton from '@/components/common/buttons/IconButton';
import MainButton from '@/components/common/buttons/MainButton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import SpanInput from '@/components/common/dataInput/SpanInput';
import useManualQuery from '@/data/hook/query/useManualQuery';
import useTreeArea from '@/data/hook/useTreeArea';
import { updateArea } from '@/data/services/areaServices';
import { areasQueryKeys } from '@/data/services/querykeys';
import Area from '@/models/Area';
import useFilterParams from '@/utils/UseFilterParams';
import { SaveIcon, XIcon } from '@heroicons/react/outline';
import React, {
  KeyboardEventHandler,
  MouseEventHandler,
  ReactElement,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

export type AreaNameProps = {
  area: Area;
  isRenaming?: boolean;
  setIsRenaming?: (isRenaming: boolean) => void;
  wrapper?: ReactElement;
};

const AreaName = ({
  area,
  setIsRenaming,
  isRenaming,
  wrapper,
}: AreaNameProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'topics.topbar',
  });
  const { tree } = useTreeArea();
  const [name, setName] = useState(area.name);
  const { deleteFilterParams } = useFilterParams();
  const { update } = useManualQuery(areasQueryKeys.list()._ctx.infinity);
  const { invalidateParent } = useTreeArea();

  const reset: MouseEventHandler<HTMLButtonElement> = e => {
    e?.stopPropagation();
    setName(area.name);
    setIsRenaming?.(false);
    deleteFilterParams('isRenaming');
  };

  const onSave: MouseEventHandler<HTMLButtonElement> = async e => {
    e?.stopPropagation();
    if (name === area.name || !name.length) {
      reset(e);
      return;
    }
    const newArea = await updateArea(area.id, { name });
    tree.current.update(newArea);
    await invalidateParent({ parentId: area.parentAreaId });
    update(newArea);
    setIsRenaming?.(false);
    deleteFilterParams('isRenaming');
  };

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {
    const keys: Record<string, Function> = {
      Enter: onSave,
      Escape: reset,
    };

    const action = keys[e.key];
    action?.();
  };

  return (
    <div className="flex items-center gap-2">
      <SpanInput
        isEditing={!!isRenaming}
        value={name}
        onChange={e => setName(e.target.value)}
        onKeyDown={onKeyDown}
      >
        {value => React.cloneElement(wrapper || <p />, { children: value })}
      </SpanInput>
      <ConditionalRenderer condition={isRenaming}>
        <MainButton
          text={t('save')}
          icon={<SaveIcon />}
          disabled={area.name === name}
          onClick={onSave}
        />
        <IconButton icon={<XIcon className="h-4 w-4" />} onClick={reset} />
      </ConditionalRenderer>
    </div>
  );
};

export default AreaName;
