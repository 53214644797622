import Book from '@/models/Book';
import Chapter from '@/models/Chapter';
import { useEffect, useRef, useState } from 'react';

export const useChapter = ({ book }: { book: Book }) => {
  const lastChapterRef = useRef<number>();
  const [chapter, setChapter] = useState(book.chapters.at(0));

  useEffect(() => {
    const lastChapter = book.chapters.find(
      chapter => chapter.id === lastChapterRef.current,
    );
    setChapter(lastChapter || book.chapters.at(0));
  }, [book]);

  const setChapterMiddleware = (chapter: Chapter) => {
    lastChapterRef.current = chapter.id;
    setChapter(chapter);
  };

  return { chapter, setChapter: setChapterMiddleware };
};
