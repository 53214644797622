import normalize from './normalize';

type Churn = {
  previousActiveEnrollments: number;
  canceledEnrollments: number;
};

export default function churnPercentage({
  canceledEnrollments,
  previousActiveEnrollments,
}: Churn) {
  if (previousActiveEnrollments === 0) {
    return 0;
  }
  const churn = (canceledEnrollments / previousActiveEnrollments) * 100;

  return normalize(churn);
}
