import { twMerge } from 'tailwind-merge';

export type LineCardColors =
  | 'base'
  | 'secondary'
  | 'secondary-accent'
  | 'accent-content'
  | 'accent'
  | 'custom';

type LineCardProps = {
  children: React.ReactNode;
  className?: string;
  color?: LineCardColors;
};

const styles: Record<LineCardColors, string> = {
  'secondary-accent': 'bg-secondary-content text-secondary',
  accent: 'bg-accent text-white',
  'accent-content': 'bg-accent-content text-accent',
  base: 'bg-base-100',
  secondary: 'bg-secondary text-white',
  custom: '',
};

export default function LineCard({
  className,
  children,
  color = 'base',
}: LineCardProps) {
  return (
    <div
      className={twMerge(
        'flex bg-base-100 w-full p-2.5 rounded-lg shadow-default',
        className,
        styles[color],
      )}
    >
      {children}
    </div>
  );
}
