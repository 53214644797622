import { KeyboardKeys } from '@/utils/Keyboard';
import { PencilIcon, XIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { Control, useController } from 'react-hook-form';
import IconButton from '../../../buttons/IconButton';
import ConditionalRenderer from '../../../ConditionalRenderer';
import Text from '../../../dataDisplay/Text';
import SpanInput from '../../../dataInput/SpanInput';
import { ChapterFormFields } from './ChapterForm';

type ChapterFormTitleProps = {
  control: Control<ChapterFormFields>;
};
export default function ChapterFormTitle({ control }: ChapterFormTitleProps) {
  const [editingTitle, setEditingTitle] = useState(false);
  const {
    field: titleField,
    fieldState: { error },
  } = useController({
    control,
    name: 'title',
    rules: {
      required: 'O titulo é obrigatório',
    },
  });

  return (
    <div className="flex items-center gap-2.5 justify-center flex-grow max-w-full">
      <SpanInput
        isEditing={editingTitle}
        {...titleField}
        errorLabelText={error?.message}
        errorLabelPosition="bottom"
        onKeyDown={event => {
          if (error?.message) return;
          event.key === KeyboardKeys.Enter && setEditingTitle(false);
        }}
      >
        {value => (
          <Text
            text={value}
            className="!font-poppins !text-24 text-base-content !font-700 underline decoration-secondary/50 decoration-2 underline-offset-4 !text-center text-wrap truncate"
          />
        )}
      </SpanInput>

      <ConditionalRenderer condition={!editingTitle}>
        <IconButton
          type="button"
          testId="editChapterTitle"
          icon={<PencilIcon className="text-accent w-4 h-4" />}
          onClick={() => setEditingTitle(true)}
        />
      </ConditionalRenderer>
      <ConditionalRenderer condition={editingTitle}>
        <IconButton
          type="button"
          testId="editChapterTitle"
          disabled={!!error?.message}
          icon={<XIcon className="text-error w-4 h-4" />}
          onClick={() => setEditingTitle(false)}
        />
      </ConditionalRenderer>
    </div>
  );
}
