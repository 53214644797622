import { GRID_ROW_HEIGHT } from '@/constants';
import { Section } from '@/models/Book';
import { Layouts } from '@/models/ChapterLayout';
import { Layout } from 'react-grid-layout';

export const fixedLayouts: Record<Layouts, number> = {
  layout1: 1,
  layout2: 2,
  layout3: 3,
  custom: 0,
};

export const mountKey = (section: Section) => {
  return 'section' + section.order;
};

export function mountLayout(
  section: Section,
  index: number,
  cols: number,
): Layout {
  return {
    i: mountKey(section),
    x: index % cols,
    y: Math.floor(index / cols),
    w: 1,
    h: 140 / GRID_ROW_HEIGHT,
    minH: 80 / GRID_ROW_HEIGHT,
  };
}

export function mountLayoutSystem(sections: Section[], cols: number): Layout[] {
  return sections.map((section, i) => ({
    i: mountKey(section),
    x: i % cols,
    y: Math.floor(i / cols),
    w: 1,
    h: 140 / GRID_ROW_HEIGHT,
    minH: 80 / GRID_ROW_HEIGHT,
  }));
}

export const findLayoutItem = (key: string, layoutSystem: Layout[]) => {
  return layoutSystem.find(layout => layout.i === key);
};

const getSection = (sections: Section[], key: string) => {
  return sections.find(section => mountKey(section) === key);
};

function buildMatrix(layoutSystem: Layout[], sections: Section[]) {
  const maxX = Math.max(...layoutSystem.map(obj => obj.x));
  const maxY = Math.max(...layoutSystem.map(obj => obj.y));
  const matrix: (Section | undefined)[][] = Array.from(
    { length: maxY + 1 },
    () => Array(maxX + 1).fill(null),
  );

  for (const obj of layoutSystem) {
    matrix[obj.y][obj.x] = getSection(sections, obj.i);
  }

  return matrix;
}

export function extractLinearOrder(
  layoutSystem: Layout[],
  sections: Section[],
): Section[] {
  const matrix = buildMatrix(layoutSystem, sections);

  const linearOrder: Section[] = [];
  for (let row of matrix) {
    for (let element of row) {
      if (element) {
        linearOrder.push(element);
      }
    }
  }
  return linearOrder;
}
