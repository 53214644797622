import { Section } from '@/models/Book';
import { motion } from 'framer-motion';
import SectionView from '../../sections/view/SectionView';
import { mountKey } from '@/utils/material/chapter/chapterLayout';
type FixedLayoutsProps = {
  sections: Section[];
  cols: number;
  readOnly?: boolean;
};
const FlexLayout = ({ sections, cols, readOnly }: FixedLayoutsProps) => {
  const flexBasis = Math.floor((1 / cols - 0.05) * 100) + '%';

  return (
    <div className="flex flex-wrap gap-4 w-full">
      {sections.map(section => (
        <motion.div
          key={mountKey(section)}
          layout
          data-testid={'flexLayoutItem' + section.id}
          transition={spring}
          className="flex-grow min-w-[300px] w-full"
          style={{
            flexBasis,
          }}
        >
          <SectionView
            section={section}
            className={{
              base: 'h-full z-10',
              main: !readOnly ? 'pointer-events-none' : '',
            }}
          />
        </motion.div>
      ))}
    </div>
  );
};

const spring = {
  type: 'spring',
  damping: 30,
  stiffness: 400,
};
export default FlexLayout;
