import Popup from '../Popup';
import MainButton from '../buttons/MainButton';
import { IconWithText } from '../dataDisplay/IconWithText';
import { ChevronDownIcon } from '@heroicons/react/outline';

type Option<T> = {
  label: string;
  value: T;
};

type OutlinedSelectProps<T> = {
  options: Option<T>[];
  onSelectValue?(value: T): void;
  selectedValue?: T;
};

export default function OutlinedSelect<T>({
  options,
  onSelectValue,
  selectedValue,
}: OutlinedSelectProps<T>) {
  const selectedOption = options.find(({ value }) => value === selectedValue);

  return (
    <Popup
      placement="bottom-end"
      offset={[-2, -2]}
      reference={
        <IconWithText
          icon={<ChevronDownIcon className="w-3" />}
          className={{
            base: 'gap-0.5 text-base-300 cursor-pointer flex-row-reverse',
            text: 'text-12 font-normal opacity-50',
          }}
          text={selectedOption?.label ?? ''}
        />
      }
      hover
    >
      <ul className="flex flex-col gap-3 border border-neutral-content bg-base-100 p-4 rounded-lg">
        {options.map(({ label, value }) => (
          <li key={label}>
            <MainButton
              className="text"
              color="custom"
              onClick={() => onSelectValue?.(value)}
              text={label}
            />
          </li>
        ))}
      </ul>
    </Popup>
  );
}
