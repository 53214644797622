import { useQuery, useQueryClient } from '@tanstack/react-query';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  resourceProgressQuerykeys,
  resourceQuerykeys,
} from '@/data/services/querykeys';
import { LoadingIcon } from '@/components/icons';
import ErrorComponent from '@/components/common/ErrorComponent';
import { getErrorMessage } from '@/utils/getErrorMessage';
import ResourceDetails from './ResourceDetails';
import ResourceActionButton from './ResourceActionButton';
import ResourceRenderer from './ResourceRenderer';
import ResourceActivityHub from './ResourceActivityHub';
import useAuth from '@/data/hook/useAuth';
import useListService from '@/data/hook/useListService';
import { isStudent } from '@/functions/auth';
import { updateResourceProgress } from '@/data/services/resourceProgressServices';
import alert from '@/utils/UseAlert';
import useLessonContext from '@/data/hook/lesson';
import LessonResources from './LessonResources';

export default function ResourceViewer() {
  const { resourceId: id } = useParams();

  const { user } = useAuth();

  const queryClient = useQueryClient();

  const { lesson } = useLessonContext();

  const resourceId = Number(id);

  const validResourceId = !isNaN(resourceId);

  const studentView = isStudent(user?.userType);

  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const {
    data: resource,
    isInitialLoading: loadingResource,
    isError: resourceError,
  } = useQuery({
    enabled: validResourceId,
    ...resourceQuerykeys.get(resourceId),
  });

  const {
    results: progress,
    isInitialLoading: loadingResourceProgress,
    error: resourceProgressError,
    isSuccess: successRequestProgress,
  } = useListService({
    ...resourceProgressQuerykeys.list({
      resourceId: validResourceId ? [resourceId] : lesson?.resources,
    }),
    enabled: studentView,
  });

  const resourceProgress = progress.at(0);

  const loading = loadingResource || loadingResourceProgress || loadingUpdate;

  const error = resourceError || resourceProgressError;

  useEffect(() => {
    async function openResource(resourceProgressId: number) {
      try {
        setLoadingUpdate(true);

        const body = {
          hasOpened: true,
        };

        await updateResourceProgress({
          resourceProgressId,
          body,
        });

        await queryClient.invalidateQueries(
          resourceProgressQuerykeys.list._def,
        );
      } catch (error) {
        alert.error(getErrorMessage(error));
      } finally {
        setLoadingUpdate(false);
      }
    }

    if (
      successRequestProgress &&
      resourceProgress &&
      !resourceProgress.hasOpened
    ) {
      openResource(resourceProgress.id);
    }
  }, [resourceProgress, queryClient, successRequestProgress]);

  if (!validResourceId) {
    return (
      <ResourceViewerContainer>
        <LessonResources
          resourceIds={lesson?.resources}
          resourceProgress={progress}
          isStaff={!studentView}
        />
      </ResourceViewerContainer>
    );
  }

  if (loading) {
    return (
      <ResourceViewerContainer>
        <LoadingIcon className="w-10 h-10 text-primary/40" />
      </ResourceViewerContainer>
    );
  }

  if (error) {
    return (
      <ResourceViewerContainer>
        <ErrorComponent
          statusCode={404}
          errorTextTitle={getErrorMessage(error)}
        />
      </ResourceViewerContainer>
    );
  }

  if (resource) {
    return (
      <ResourceViewerContainer>
        <section className="flex justify-between flex-wrap w-full gap-2">
          <ResourceDetails resource={resource} />
          <ResourceActionButton resource={resource} />
        </section>
        <ResourceRenderer resource={resource} />
        <ResourceActivityHub resource={resource} isStaff={!studentView} />
      </ResourceViewerContainer>
    );
  }

  return null;
}

function ResourceViewerContainer({ children }: PropsWithChildren) {
  return (
    <section className="rounded-xl bg-base-100 shadow-default flex flex-col w-full justify-center items-center p-9 transition-all duration-200 gap-4">
      {children}
    </section>
  );
}
