import { Breakpoints } from '@/utils/Breakpoint';
import { useMediaQuery } from 'react-responsive';
import { extractLinearOrder } from '@/utils/material/chapter/chapterLayout';
import FlexLayout from './FlexLayout';
import GridCustomLayout, { GridCustomLayoutProps } from './GridCustomLayout';

const ResponsiveGridLayout = (props: GridCustomLayoutProps) => {
  const { sections, layout, readOnly } = props;
  const isDesktop = useMediaQuery({
    minWidth: Breakpoints.lg,
  });
  if (!isDesktop)
    return (
      <FlexLayout
        cols={layout.cols}
        sections={extractLinearOrder(layout.system, sections)}
        readOnly={readOnly}
      />
    );
  return <GridCustomLayout {...props} />;
};

export default ResponsiveGridLayout;
