import { useState } from 'react';
import download from 'downloadjs';
import { useTranslation } from 'react-i18next';

import RoundedButton from '@/components/common/buttons/RoundedButton';
import Resource, { ResourceTypeEnum } from '@/models/Resource';
import { ROLES } from '@/utils/roles';
import { getResourceFile } from '@/data/services/resourceServices';
import alert from '@/utils/UseAlert';
import { getErrorMessage } from '@/utils/getErrorMessage';

type ResourceActionButtonProps = {
  resource: Resource;
};

export default function ResourceActionButton({
  resource,
}: ResourceActionButtonProps) {
  const [downloadResourceProgress, setDownloadResourceProgress] =
    useState(false);

  const resourceType = ROLES.RESOURCE.RESOURCE_TYPE(resource);

  const { resourceFile, resourceLink } = resource;

  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  async function makeResourceDownload() {
    try {
      setDownloadResourceProgress(true);
      const archive = await getResourceFile(String(resourceFile));

      if (archive) {
        download(archive.data, resource.title);
      }
    } catch (error) {
      alert.error(getErrorMessage(error));
    } finally {
      setDownloadResourceProgress(false);
    }
  }

  function openResource() {
    if (!resourceLink) return;
    window.open(resourceLink, '_blank');
  }

  type ResourceAction = {
    title: string;
    action?: () => void;
  };

  const resourceActions: {
    [key in ResourceTypeEnum]: ResourceAction;
  } = {
    [ResourceTypeEnum.FILE]: {
      title: t('download'),
      action: makeResourceDownload,
    },
    [ResourceTypeEnum.LINK]: {
      title: t('open'),
      action: openResource,
    },
    [ResourceTypeEnum.NONE]: {
      title: '',
    },
  };

  return (
    <RoundedButton
      isExternalLink={resourceType !== ResourceTypeEnum.NONE}
      loading={downloadResourceProgress}
      text={resourceActions[resourceType].title}
      disabled={resourceType === ResourceTypeEnum.NONE}
      onClick={resourceActions[resourceType].action}
    />
  );
}
