import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import {
  QuizActivityIcon,
  TextActivityIcon,
  VideoActivityIcon,
  TrueOrFalseIcon,
  HandDragging,
} from '@/components/icons';
import { ActivityTypeEnum } from '@/models/Activity';
import { CodeIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

type QuestionIconProps = {
  type: ActivityTypeEnum;
  hideText?: boolean;
};

export default function QuestionIcon({ type, hideText }: QuestionIconProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'activity',
  });

  type IconQuestion = {
    icon: JSX.Element;
    text: string;
  };

  const icons: Record<ActivityTypeEnum, IconQuestion> = {
    [ActivityTypeEnum.QUIZ]: {
      icon: <QuizActivityIcon />,
      text: t('quizLabel'),
    },
    [ActivityTypeEnum.TEXT]: {
      icon: <TextActivityIcon />,
      text: t('textLabel'),
    },
    [ActivityTypeEnum.VIDEO]: {
      icon: <VideoActivityIcon />,
      text: t('videoLabel'),
    },
    [ActivityTypeEnum.TRUE_OR_FALSE]: {
      icon: <TrueOrFalseIcon />,
      text: t('trueFalseLabel'),
    },
    [ActivityTypeEnum.DND_ORDENATION]: {
      icon: <HandDragging />,
      text: t('dragAndDropOrdenation'),
    },
    [ActivityTypeEnum.DND_POSITIONAL]: {
      icon: <HandDragging />,
      text: t('dragAndDropPositional'),
    },
    [ActivityTypeEnum.CODE]: {
      icon: <CodeIcon />,
      text: t('codeLabel'),
    },
    [ActivityTypeEnum.CODE_EDITOR]: {
      icon: <CodeIcon />,
      text: t('codeEditorLabel'),
    },
  };

  const info = icons[type];

  return (
    <section className="flex gap-4 items-center">
      {info.icon}
      <ConditionalRenderer condition={!hideText}>
        {info.text}
      </ConditionalRenderer>
    </section>
  );
}
