import Area from '@/models/Area';
import Skeleton from '../common/Skeleton';
import Card from '../common/Card';
import LineCard from '../common/LineCard';
import Text from '../common/dataDisplay/Text';
import { Fragment, PropsWithChildren, useEffect, useState } from 'react';
import { IconWithText } from '../common/dataDisplay/IconWithText';
import { studentTagScoreQueryKeys } from '@/data/services/querykeys';
import useAuth from '@/data/hook/useAuth';
import AreaChart from '../charts/AreaChart';
import { StudentTagScore } from '@/models/StudentTagScore';
import moment from 'moment';
import ConditionalRenderer from '../common/ConditionalRenderer';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import { twJoin, twMerge } from 'tailwind-merge';
import OutlinedSelect from '../common/dataInput/OutlinedSelect';
import { useTranslation } from 'react-i18next';
import useInfiniteService from '@/data/hook/useInfiniteService';
import AreaLineCard from './AreaLineCard';
import { AreaScore, useAreaScore } from '@/pages/skill/SkillPage.hooks';
import StageTag from './StageTag';

type AreaCardProps = {
  selectedAreaScore: AreaScore | undefined;
  isLoading?: boolean;
  className?: string;
};

export function AreaCard({
  selectedAreaScore,
  isLoading,
  className,
}: AreaCardProps) {
  if (isLoading) {
    return (
      <Skeleton className="max-w-3xl w-full bg-primary-content h-80 rounded-2xl" />
    );
  }

  if (!selectedAreaScore || !selectedAreaScore.tagScore) {
    return null;
  }

  return (
    <Card
      className={twMerge(
        `max-w-3xl md:w-full md:h-fit flex flex-col md:justify-start gap-4 m-0 md:mr-0 p-5 text-left border border-neutral-content`,
        className,
      )}
    >
      <AreaInfo
        area={selectedAreaScore.area}
        tagScore={selectedAreaScore.tagScore}
      />
      <ProgressSection area={selectedAreaScore.area} />
    </Card>
  );
}

function CardSection({ children }: PropsWithChildren) {
  return <div className="flex flex-col gap-2.5 w-full">{children}</div>;
}

type AreaInfoProps = {
  area: Area;
  tagScore: StudentTagScore;
};

function AreaInfo({ area, tagScore }: AreaInfoProps) {
  const { user } = useAuth();
  const { areaScores, isLoading } = useAreaScore({
    parentAreaId: area.id,
    studentId: user?.id,
    latestOnly: true,
  });

  return (
    <CardSection>
      <AreaLineCard
        title={area.name}
        score={tagScore.score}
        color="accent"
        difficultyStage={tagScore.difficultyStage}
      />
      <ul className="flex flex-wrap gap-1">
        <ConditionalRenderer condition={isLoading}>
          {Array.from({ length: 3 }).map((_, i) => (
            <li key={i}>
              <Skeleton className="rounded-lg bg-primary-content h-14" />
            </li>
          ))}
        </ConditionalRenderer>
        <ConditionalRenderer condition={areaScores.length}>
          {areaScores.map(({ area, tagScore }) => (
            <li key={area.id}>
              <LineCard className="flex-col border border-neutral-content items-center gap-1.5">
                <IconWithText
                  href={
                    area.isLeaf
                      ? undefined
                      : `/skills/${area.parentAreaId}?area_id=${area.id}`
                  }
                  className={{
                    base: twJoin(
                      'gap-0.5 w-full justify-center text-base-content flex-row-reverse',
                      !area.isLeaf &&
                        'hover:opacity-80 hover:text-secondary transition-all duration-200',
                    ),
                  }}
                  icon={
                    area.isLeaf ? undefined : (
                      <ChevronRightIcon className="h-3" strokeWidth={3} />
                    )
                  }
                  text={area.name}
                />
                <StageTag
                  className="w-full max-w-none justify-center"
                  difficultyStage={tagScore?.difficultyStage}
                />
              </LineCard>
            </li>
          ))}
        </ConditionalRenderer>
      </ul>
    </CardSection>
  );
}

type ProgressSectionProps = {
  area: Area | undefined;
};

function ProgressSection({ area }: ProgressSectionProps) {
  const [monthRange, setMonthRange] = useState(1);
  const [t] = useTranslation('translation', {
    keyPrefix: 'skillPage.areaCard',
  });
  const { user } = useAuth();
  const dateFormat = 'YYYY-MM-DD';
  const { queryFn, queryKey } = studentTagScoreQueryKeys.list({
    studentId: user?.id,
    areaId: area?.id,
    dateFrom: moment().subtract(monthRange, 'months').format(dateFormat),
    dateTo: moment().format(dateFormat),
    pageSize: 50,
  })._ctx.infinity;
  const {
    results: tagScores,
    isInitialLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteService({
    queryFn,
    queryKey,
    enabled: !!area,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const rangeOptions = Array.from({ length: 3 }, (_, i) => ({
    label: t('lastMonth', { count: i + 1 }),
    value: i + 1,
  }));

  function getPeriodProgress(values: number[]) {
    if (values.length < 2) {
      return 100;
    }
    const firstValue = values[0];
    const lastValue = values.at(-1) ?? firstValue;
    const difference = lastValue - firstValue;

    return (firstValue ? difference / firstValue : 1) * 100;
  }
  const progressPercentage = getPeriodProgress(
    tagScores.map(({ score }) => score),
  );

  useEffect(() => {
    if (!hasNextPage || isFetchingNextPage) return;
    (async () => {
      await fetchNextPage();
    })();
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  return (
    <CardSection>
      <LineCard className="items-center justify-between" color="accent-content">
        <Text text={t('progress')} format="rubik-500" size="text-16" />
      </LineCard>
      <div className="max-h-80 border border-neutral-content rounded-lg">
        <div className="px-2 flex justify-between py-1.5">
          <IconWithText
            className={{ base: 'text-green-500 gap-1' }}
            icon={
              <div className="w-0 h-0 border-l-[6px] border-l-transparent border-r-[6px] border-r-transparent border-b-[6px] border-b-success" />
            }
            text={`${progressPercentage}%`}
          />
          <OutlinedSelect
            selectedValue={monthRange}
            onSelectValue={setMonthRange}
            options={rangeOptions}
          />
        </div>
        <ChartHandler tagScores={tagScores} isLoading={isInitialLoading} />
      </div>
    </CardSection>
  );
}

type ChartHandlerProps = {
  isLoading?: boolean;
  tagScores: StudentTagScore[];
};

function ChartHandler({ tagScores, isLoading }: ChartHandlerProps) {
  function parseTagScoreToChartData(tagScore: StudentTagScore) {
    return {
      value: tagScore.score,
      label: moment(tagScore.updatedAt).format('L'),
    };
  }
  function buildChartData(tagScores: StudentTagScore[]) {
    const firstTagScore = tagScores.at(0);
    if (!firstTagScore) {
      return [];
    }
    const chartData = tagScores.map(parseTagScoreToChartData);
    const initialScore = {
      value: 0,
      label: moment(firstTagScore.createdAt).subtract(1, 'days').format('L'),
    };
    return chartData.length === 1
      ? [initialScore].concat(chartData)
      : chartData;
  }

  const chartData = buildChartData(tagScores);

  if (isLoading && !chartData.length) {
    return <Skeleton className="h-48 rounded-lg w-full bg-primary-content" />;
  }

  return (
    <Fragment>
      <div className="overflow-hidden">
        <AreaChart
          key={chartData.map(({ label }) => label).join(',')}
          options={{ seriesName: 'xp' }}
          data={chartData}
        />
      </div>
    </Fragment>
  );
}
