import { CalendarIcon } from '@heroicons/react/outline';
import { enUS, ptBR } from 'date-fns/locale';
import moment from 'moment-timezone';
import { InputHTMLAttributes } from 'react';
import { Matcher, useInput } from 'react-day-picker';
import { useTranslation } from 'react-i18next';

import { timezonedToBrowserDate } from '@/utils/datetimes';
import ConditionalRenderer from '../ConditionalRenderer';
import Popup from '../Popup';
import { BaseInputProps } from './BaseInput';
import { LocaleDayPicker } from './LocaleDayPicker';
import TextInput from './TextInput';

type DateInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'className' | 'type' | 'color'
> &
  BaseInputProps & {
    position?: string;
    onDateChange: (newValue: string) => void;
    disabled?: boolean;
    disabledDates?: Matcher[];
    value?: string;
    timezone?: string;
  };

export default function DateInput(props: DateInputProps) {
  const {
    fontSize,
    register,
    testId,
    position,
    onDateChange,
    disabled,
    disabledDates,
    value,
    timezone = 'America/Sao_Paulo',
    placeholder = '',
    ...rest
  } = props;
  const {
    i18n: { language },
  } = useTranslation();
  const localeLanguage = {
    locale: language === 'pt_BR' ? ptBR : enUS,
  };

  const { inputProps, dayPickerProps, setSelected } = useInput({
    fromYear: 1960,
    toYear: new Date().getFullYear(),
    locale: localeLanguage.locale,
    required: true,
    defaultSelected: value
      ? timezonedToBrowserDate(value, timezone)
      : undefined,
  });

  type SetDateParams = {
    dateDisplay: Date;
    dateValue: string;
  };

  const setDate = ({ dateDisplay, dateValue }: SetDateParams) => {
    onDateChange(dateValue);
    setSelected(dateDisplay);
  };

  const getCorrectDate = (dateSelected?: Date): SetDateParams | undefined => {
    if (dateSelected) {
      return {
        dateDisplay: dateSelected,
        dateValue: moment(dateSelected)
          .clone()
          .tz(timezone)
          .format('YYYY-MM-DD[T]HH:mm:ssZ'),
      };
    }
  };

  const onSelectDate = (selectedDate?: Date) => {
    const correctDate = getCorrectDate(selectedDate);
    if (correctDate) setDate(correctDate);
  };

  const onBlur = () => {
    if (dayPickerProps.selected) {
      onDateChange(dayPickerProps.selected.toISOString());
    }
  };

  const localeMoment = () => {
    const brwoserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment(dayPickerProps.selected).tz(brwoserTimezone).locale(language);
  };

  return (
    <Popup
      className={rest.className}
      reference={
        <TextInput
          {...rest}
          {...inputProps}
          placeholder={placeholder}
          disabled={disabled}
          icon={
            <ConditionalRenderer condition={!disabled}>
              <CalendarIcon data-testid="calendarButton" />
            </ConditionalRenderer>
          }
          value={value ? localeMoment().format('L') : inputProps.value}
          onBlur={onBlur}
        />
      }
    >
      <ConditionalRenderer condition={!disabled}>
        <LocaleDayPicker
          {...register}
          {...dayPickerProps}
          disabled={disabledDates}
          mode="single"
          className="bg-base-content rounded-lg p-4 shadow-default shadow-neutral-content"
          onSelect={onSelectDate}
        />
      </ConditionalRenderer>
    </Popup>
  );
}
