import { compact } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ClipboardListIcon, EyeIcon } from '@heroicons/react/outline';
import { Link, useParams } from 'react-router-dom';
import { useQueries, useQuery } from '@tanstack/react-query';

import Activity from '@/models/Activity';
import Card from '@/components/common/modals/ModalBank/BankCards';
import { modalColors } from '@/utils/modalBankUtils';
import { activityTypeOptions } from '@/utils/activityTypeOptions';
import { useActiveStudents } from '@/components/scheduledLessonReport/StudentReports.hook';
import useToggle from '@/data/hook/useToggle';
import useAuth from '@/data/hook/useAuth';
import { isSuperAdmin } from '@/functions/auth';
import {
  activityProgressAttemptsQueryKeys,
  activityProgressesQueryKeys,
} from '@/data/services/querykeys';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { LoadingIcon } from '@/components/icons';
import { getErrorMessage } from '@/utils/getErrorMessage';
import Text from '@/components/common/dataDisplay/Text';
import Enrollment from '@/models/Enrollment';
import ActivityProgress from '@/models/ActivityProgress';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import { activityProgressGenerator } from '@/functions/generators';
import Skeleton from '@/components/common/Skeleton';
import { handleUserFullName } from '@/functions/handleUserFullName';
import ActivityAttemptButton from '@/components/common/buttons/ActivityAttemptButton';
import Steps, { Step } from '@/components/common/Steps/Steps';
import { createStepByAttempt } from '@/utils/activitiesSteps';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import getKlassViewBaseUrl from '@/utils/getKlassViewBaseUrl';

type StaffResourceActivityProps = {
  resourceId: number;
  activity: Activity;
};

export default function StaffResourceActivity(
  props: StaffResourceActivityProps,
) {
  const { user } = useAuth();

  const { t: tActivityTypes } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityTypes',
  });

  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const { isOpen, toggle } = useToggle();

  const { lessonId, slugCourseName, klassId } = useParams();

  const { activity, resourceId } = props;

  const mountDescIcons = (activity: Activity) => {
    const activityTypes = activity.questions.map(question => {
      const activityIcon = activityTypeOptions.find(
        item => item.type === question.type,
      );
      return activityIcon;
    });

    const activityTypesNotNullable = compact(activityTypes);

    const iconsElement = activityTypesNotNullable.map(
      ({ type, icon: Icon }, index) => ({
        icon: Icon && (
          <Card.IconContainer key={'activityIcon' + index} className="p-1">
            <Icon className="text-primary" />
          </Card.IconContainer>
        ),
        tooltip: tActivityTypes(type),
      }),
    );
    return iconsElement;
  };

  const baseURL = `${getKlassViewBaseUrl({
    klassId: Number(klassId),
    slugCourseName: slugCourseName ?? '',
    lessonId: Number(lessonId),
  })}/resources/${resourceId}/activities/${activity.id}`;

  const requesting = !isNaN(Number(klassId)) && isOpen;

  if (!user) return null;

  const staffUnitView = !isSuperAdmin(user.userType);

  const content = staffUnitView ? (
    <ResourceActivityStudents
      activityId={activity.id}
      klassId={Number(klassId)}
      requesting={requesting}
    />
  ) : undefined;

  return (
    <li>
      <Card
        expansibleContent={content}
        color={modalColors.all}
        onToggle={toggle}
        opened={isOpen}
      >
        <Card.LeftContent className="lg:flex-row lg:items-center">
          <Card.Header
            className={{ base: 'gap-0 overflow-x-hidden' }}
            icon={ClipboardListIcon}
            title={activity.name}
            color={modalColors.all.color}
          />

          <Link
            to={baseURL}
            className="text-nowrap text-primary font-500 text-[0.75rem] md:text-[0.875rem] flex gap-1 hover:text-primary/40 transition-all duration-150 ease-in-out"
          >
            {t('seeAnswers')}
            <EyeIcon className="w-4" />
          </Link>
        </Card.LeftContent>
        <Card.DescContainer>
          <Card.IconsList icons={mountDescIcons(activity)} />
        </Card.DescContainer>
      </Card>
    </li>
  );
}

type ResourceActivityStudentsProps = {
  klassId: number;
  activityId: number;
  requesting?: boolean;
};

function ResourceActivityStudents(props: ResourceActivityStudentsProps) {
  const { klassId, activityId, requesting } = props;
  const {
    enrollments,
    isInitialLoading: loadingEnrollments,
    error: enrollmentsError,
    isSuccess,
  } = useActiveStudents({
    klassId,
    enabled: requesting,
  });

  const activityProgressRequest = useQueries({
    queries: enrollments.map(({ student }) => ({
      ...activityProgressesQueryKeys.list({
        activityId: [activityId],
        userId: [student.id],
      }),
      staleTime: REQUEST_STALE_TIME_IN_MS,
      enabled: !!enrollments.length && isSuccess,
      placeholderData: {
        results: [activityProgressGenerator({ id: student.id })],
      },
    })),
  });

  return (
    <ul className="flex flex-col gap-2.5 mt-4">
      <ConditionalRenderer condition={loadingEnrollments}>
        <LoadingIcon className="w-10 h-10 text-primary/40" />
      </ConditionalRenderer>
      <ConditionalRenderer condition={enrollmentsError}>
        <Text text={getErrorMessage(enrollmentsError)} />
      </ConditionalRenderer>

      <ConditionalRenderer
        condition={
          enrollments.length && !loadingEnrollments && !enrollmentsError
        }
      >
        {activityProgressRequest.map(({ data, isFetching, error }, index) => (
          <ResourceActivityStudent
            key={data?.results.at(0)?.id}
            activityProgress={data?.results.at(0)}
            loading={isFetching}
            error={error}
            enrollment={enrollments.at(index)}
          />
        ))}
      </ConditionalRenderer>
    </ul>
  );
}

type ResourceActivityStudentProps = {
  enrollment?: Enrollment;
  activityProgress?: ActivityProgress;
  loading?: boolean;
  error?: unknown;
};

function ResourceActivityStudent(props: ResourceActivityStudentProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const { loading, activityProgress, enrollment, error } = props;

  const { data: attempt, isInitialLoading: loadingAttempt } = useQuery({
    enabled: !!activityProgress && !!activityProgress.bestAttempt,
    ...activityProgressAttemptsQueryKeys.get({
      activityProgressId: activityProgress?.id ?? 0,
      attemptId: activityProgress?.bestAttempt?.id ?? 0,
    }),
  });

  if (loading) {
    return <Skeleton className="h-11 rounded-md bg-primary-content" />;
  }

  if (error) return <Text text={getErrorMessage(error)} />;

  if (activityProgress && enrollment) {
    const { student } = enrollment;
    const { bestAttempt } = activityProgress;

    function showGrade(score?: number) {
      if (score) return Math.round(score * 100);
      return 0;
    }

    const steps =
      attempt?.content?.map<Step>(step => createStepByAttempt(step)) ?? [];

    const userFullName = handleUserFullName(student);

    return (
      <li className="flex justify-between overflow-hidden gap-2.5 items-center border border-primary/25 p-1 px-2.5 rounded-lg shadow-default h-11">
        <Tooltip
          classNameContainer="overflow-hidden"
          color="lightBlue"
          text={userFullName}
        >
          <Text text={userFullName} className="truncate" />
        </Tooltip>
        <ConditionalRenderer condition={attempt}>
          <Steps steps={steps} hideIndicator loading={loadingAttempt} />
        </ConditionalRenderer>
        <ActivityAttemptButton
          text={`${t('grade')}: ${showGrade(bestAttempt?.grade)}%`}
        />
      </li>
    );
  }

  return null;
}
