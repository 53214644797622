import { FolderDownloadIcon, LinkIcon } from '@heroicons/react/outline';

import Resource from '@/models/Resource';

type ResourceIconProps = {
  resource: Pick<Resource, 'resourceFile' | 'resourceLink'>;
};

export default function ResourceIcon({ resource }: ResourceIconProps) {
  const { resourceFile, resourceLink } = resource;
  const iconClassName = 'w-5 h-5 text-primary shrink-0';
  if (resourceFile)
    return (
      <FolderDownloadIcon
        className={iconClassName}
        data-testid="folderDownloadIcon"
      />
    );
  if (resourceLink)
    return <LinkIcon className={iconClassName} data-testid="linkIcon" />;

  return null;
}
