import {
  CheckIcon,
  InformationCircleIcon,
  ShieldExclamationIcon,
  XIcon,
} from '@heroicons/react/outline';
import { twJoin, twMerge } from 'tailwind-merge';
import { IconWithText } from './IconWithText';

export enum TypeAlertEnum {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}

export enum PositionEnum {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

type VerticalPositionType = 'top' | 'bottom';
type HorizontalPositionType = 'left' | 'center' | 'right';

export interface AlertPosition {
  vertical: VerticalPositionType;
  horizontal: HorizontalPositionType;
}

interface AlertProps {
  testId?: string;
  message: string;
  className?: string;
  type: TypeAlertEnum;
  icon?: React.ReactNode | JSX.Element;
  position?: AlertPosition;
}
export const Alert = ({
  message,
  testId,
  type,
  position,
  className,
  icon,
}: AlertProps) => {
  const verticalPositionClassNames: Record<VerticalPositionType, string> = {
    bottom: 'bottom-3.5',
    top: 'top-3.5',
  };
  const horizontalPositionClassNames: Record<HorizontalPositionType, string> = {
    center: 'left-1/2 -translate-x-1/2',
    left: 'left-3.5',
    right: 'right-3.5',
  };

  type MessageType =
    | TypeAlertEnum.SUCCESS
    | TypeAlertEnum.ERROR
    | TypeAlertEnum.INFO
    | TypeAlertEnum.WARNING;

  const colorClasses: Record<MessageType, string> = {
    success: 'text-success bg-emerald-50',
    warning: 'text-warning bg-yellow-50',
    error: 'text-error bg-red-50',
    info: 'text-primary bg-blue-50',
  };

  const iconClasses: Record<MessageType, React.ReactNode | JSX.Element> = {
    success: (
      <CheckIcon
        className={`h-6 ${colorClasses[type] || TypeAlertEnum.INFO}`}
      />
    ),
    warning: (
      <ShieldExclamationIcon
        className={`h-6 ${colorClasses[type] || TypeAlertEnum.INFO}`}
      />
    ),
    error: (
      <XIcon className={`h-6 ${colorClasses[type] || TypeAlertEnum.INFO}`} />
    ),
    info: (
      <InformationCircleIcon
        className={`h-6 ${colorClasses[type] || TypeAlertEnum.INFO}`}
      />
    ),
  };

  return (
    <div
      role="alert"
      data-testid={testId}
      className={twJoin(
        'absolute z-50',
        verticalPositionClassNames[position?.vertical || 'top'],
        horizontalPositionClassNames[position?.horizontal || 'center'],
      )}
    >
      <IconWithText
        icon={icon || iconClasses[type]}
        text={message}
        className={{
          base: twMerge(
            'flex items-center py-3 px-4 rounded-full',
            className,
            colorClasses[type],
          ),
          text: 'font-rubik',
        }}
      />
    </div>
  );
};
