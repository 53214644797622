import { useTranslation } from 'react-i18next';

import Text from '@/components/common/dataDisplay/Text';
import useListService from '@/data/hook/useListService';
import {
  activitiesQueryKeys,
  activityProgressesQueryKeys,
} from '@/data/services/querykeys';
import Resource from '@/models/Resource';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { LoadingIcon } from '@/components/icons';
import { getErrorMessage } from '@/utils/getErrorMessage';
import StudentResourceActivity from './StudentResourceActivity';
import StaffResourceActivity from './StaffResourceActivity';

type ResourceActivityHubProps = {
  resource: Resource;
  isStaff?: boolean;
};

export default function ResourceActivityHub(props: ResourceActivityHubProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const {
    resource: { id: resourceId, activitiesIds },
    isStaff,
  } = props;

  const {
    results: activities,
    isInitialLoading: loadingActivities,
    error: activityError,
  } = useListService({
    ...activitiesQueryKeys.list({
      id: activitiesIds,
    }),
    enabled: isStaff && !!activitiesIds.length,
  });

  const {
    results: activityProgress,
    isInitialLoading: loadingActivityProgress,
    error: activityProgressError,
  } = useListService({
    ...activityProgressesQueryKeys.list({
      activityId: activitiesIds,
    }),
    enabled: !isStaff && !!activitiesIds.length,
  });

  const loading = loadingActivityProgress || loadingActivities;
  const error = activityProgressError || activityError;

  return (
    <ConditionalRenderer condition={activitiesIds.length}>
      <section className="flex flex-col w-full gap-4">
        <hr className="h-px border-secondary/20" />
        <section className="flex flex-col items-center gap-4">
          <Text
            format="poppins-600"
            text={t('activities')}
            className="text-primary text-[1.25rem]"
          />
          <ConditionalRenderer condition={loading}>
            <LoadingIcon className="w-10 h-10 text-primary/40" />
          </ConditionalRenderer>
          <ConditionalRenderer condition={error}>
            <Text text={getErrorMessage(error)} />
          </ConditionalRenderer>

          <ConditionalRenderer condition={activities.length}>
            <ul className="flex flex-col gap-2 w-full">
              {activities.map(activity => (
                <StaffResourceActivity
                  key={activity.id}
                  activity={activity}
                  resourceId={resourceId}
                />
              ))}
            </ul>
          </ConditionalRenderer>

          <ConditionalRenderer condition={activityProgress.length}>
            <ul className="flex flex-col gap-2 w-full">
              {activityProgress.map(activityProgress => (
                <StudentResourceActivity
                  key={activityProgress.id}
                  activityProgress={activityProgress}
                  resourceId={resourceId}
                />
              ))}
            </ul>
          </ConditionalRenderer>
        </section>
      </section>
    </ConditionalRenderer>
  );
}
