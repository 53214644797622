import { Themes } from '@/data/context/ConfigContext';
import { Monaco } from '@monaco-editor/react';
import { editor } from 'monaco-editor';

import themes from '@/styles/themes';
import { ASTRO_CODE_EDITOR_THEME } from '@/constants';

export const options: editor.IStandaloneEditorConstructionOptions = {
  minimap: { enabled: false },
};

export const setEditorTheme = (monaco: Monaco, theme: Themes) => {
  const currentTheme = themes[theme];

  monaco.editor.defineTheme(ASTRO_CODE_EDITOR_THEME, {
    base: theme === 'dark' ? 'vs-dark' : 'vs',
    inherit: true,
    rules: [
      {
        token: 'comment',
        foreground: currentTheme.secondary,
        fontStyle: 'italic',
      },
      {
        token: 'keyword',
        foreground: currentTheme.primary,
      },
      { token: 'string', foreground: currentTheme.primary },
      { token: 'number', foreground: currentTheme.primary },
      { token: 'tag', foreground: currentTheme.primary },
      {
        token: 'attribute.name',
        foreground: currentTheme.primary,
      },
    ],
    colors: {
      'editor.background': currentTheme['base-100'],
      'editor.foreground': currentTheme['base-content'],
      'editor.lineHighlightBackground': currentTheme['primary-content'],
      'editorCursor.foreground': currentTheme.primary,
      'editorLineNumber.foreground': currentTheme.neutral,
      'editorLineNumber.activeForeground': currentTheme.primary,
    },
  });
  monaco.editor.setTheme(ASTRO_CODE_EDITOR_THEME);
};

export const removeAutocomplete = (
  monaco: Monaco,
  editor: editor.IStandaloneCodeEditor,
) => {
  editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.Space, () => {});

  monaco.languages.getLanguages().forEach(language => {
    monaco.languages.registerCompletionItemProvider(language.id, {
      provideCompletionItems: () => ({ suggestions: [] }),
    });
  });
};
