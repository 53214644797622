import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Text from '@/components/common/dataDisplay/Text';
import { useTranslation } from 'react-i18next';
import ScheduledLesson from '@/models/ScheduledLesson';
import {
  isLessonBeforeToday,
  isLessonDelayed,
} from '@/utils/ScheduledLessonDateValidator';
import moment from 'moment';
import truncateString from '@/utils/truncateString';

interface KlassesHeaderCellProps {
  scheduledLesson: ScheduledLesson;
  courseSlug: string;
}

export default function KlassesHeaderCell(props: KlassesHeaderCellProps) {
  const { courseSlug, scheduledLesson } = props;

  const isBeforeToday = isLessonBeforeToday(scheduledLesson);
  const isDelayed = isLessonDelayed(scheduledLesson);

  const { t } = useTranslation('translation', {
    keyPrefix: 'klassProgressPage',
  });

  const to = `/courses/${courseSlug}/lesson/${scheduledLesson.lesson.id}/class/${scheduledLesson.klass}`;

  const Icon = () => {
    if (!isBeforeToday && !scheduledLesson.hasDone) {
      return <Fragment />;
    } else if (isDelayed) {
      return <ExclamationCircleIcon className="h-4 w-4 text-error" />;
    }
    return <CheckCircleIcon className="h-4 w-4 text-success" />;
  };

  return (
    <div className="flex flex-col shrink-0 h-[5.25rem] gap-2 w-72 p-3.5 border-b border-neutral-content">
      <div className="flex flex-wrap justify-between w-full items-center">
        <div className="flex gap-2 items-center w-fit">
          <Text
            className="text-primary"
            size="text-18"
            format="rubik-500"
            text={`${t('lesson')} ${scheduledLesson.lesson.order || 'Extra'}`}
          />
          <Icon />
        </div>
        <Text
          size="text-12"
          text={moment(scheduledLesson.datetime).format('DD/MM/YYYY')}
        />
      </div>
      <Link to={to}>
        <Text
          text={truncateString(scheduledLesson.lesson.name, 45)}
          size="text-14"
          format="rubik-500"
          className="underline text-primary"
        />
      </Link>
    </div>
  );
}
