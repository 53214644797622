import i18next from 'i18next';
import 'moment-timezone';
import 'moment/locale/pt-br';
import { initReactI18next } from 'react-i18next';

import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import ConditionalRenderer from './components/common/ConditionalRenderer';
import { useOffline } from './data/hook/useOffline';
import Providers from './data/providers/Providers';
import ErrorBoundary from './pages/ErrorBoundary';
import ErrorPage from './pages/notFound/ErrorPage';
import Router from './Router';
import './styles/app.css';
import en from './translations/en';
import pt_BR from './translations/pt_BR';
import { initGA } from './utils/analytics';

i18next.use(initReactI18next).init({
  lng: 'pt_BR',
  fallbackLng: 'pt_BR',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    pt_BR: { translation: pt_BR },
    en: { translation: en },
  },
});

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') initGA();
  }, []);
  const isOffline = useOffline();

  return (
    <ErrorBoundary>
      <Providers>
        <Toaster
          toastOptions={{
            duration: 2500,
          }}
        />
        <ConditionalRenderer
          condition={!isOffline}
          fallback={<ErrorPage statusCode={1} />}
        >
          <Router />
        </ConditionalRenderer>
      </Providers>
    </ErrorBoundary>
  );
}

export default App;
