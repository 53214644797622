import { UseQueryResult, useQueries } from '@tanstack/react-query';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import Activity from '@/models/Activity';
import { activityGenerator } from '@/functions/generators';
import { activitiesQueryKeys } from '../services/querykeys';

type UseActivitiesProps = {
  activityIds?: number[];
  enabled?: boolean;
};

export default function useActivities({
  activityIds = [],
  enabled,
}: UseActivitiesProps) {
  const queryKey = activitiesQueryKeys.get._def;

  const queries = useQueries({
    queries: activityIds.map(activityId => {
      return {
        ...activitiesQueryKeys.get(activityId),
        enabled: !!activityId && !isNaN(Number(activityId)) && enabled,
        keepPreviousData: true,
        staleTime: REQUEST_STALE_TIME_IN_MS,
        placeholderData: activityGenerator({ id: activityId }),
      };
    }),
  });

  const extractData = (query: UseQueryResult<Activity>) => {
    return {
      data: query.data,
      status: query.status,
      fetchStatus: query.fetchStatus,
      isPlaceholderData: query.isPlaceholderData,
    };
  };

  const extractDataList = () => queries.map(query => extractData(query));

  const activities = extractDataList();

  return {
    activities,
    queryKey,
  };
}
