import ComponentGuard from '@/components/common/ComponentGuard';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import MainButton from '@/components/common/buttons/MainButton';
import useAuth from '@/data/hook/useAuth';
import { StudentAssessment } from '@/models/StudentAssessment';
import User, { UserTypeEnum } from '@/models/User';
import { EditAssessment } from '../PedagogicalSheet';
import { PencilIcon, ChevronDownIcon } from '@heroicons/react/outline';
import Text from '@/components/common/dataDisplay/Text';
import { useTranslation } from 'react-i18next';
import BehaviorAssessmentForm from '../StudentBehaviorAssessmentForm/BehaviorAssessmentForm';
import BehaviorAssessmentPreview from '../../../../common/assessment/BehaviorAssessmentPreview';
import AccordionContainer from '@/components/common/cards/AccordionContainer';
import useToggle from '@/data/hook/useToggle';
import { SheetTab } from '@/models/SheetTab';
import AssessmentHeader from '@/components/common/assessment/AssessmentHeader';

type AssessmentProps = {
  assessment: StudentAssessment;
  editAssessment({ assessment, tab }: EditAssessment): void;
  student: User;
  tab: SheetTab;
};

export default function Assessment(props: AssessmentProps) {
  return (
    <li className="flex flex-col w-full gap-2" data-testid="assessmentItem">
      <CommentAssessmentItem {...props} />
      <BehaviorAssessmentItem {...props} />
    </li>
  );
}

function CommentAssessmentItem({
  assessment,
  editAssessment,
  klassId,
}: AssessmentProps & { klassId?: number }) {
  const { user } = useAuth();

  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const { createdAt, author, commentAssessment, behaviorAssessment } =
    assessment;

  return (
    <ConditionalRenderer condition={commentAssessment && !behaviorAssessment}>
      <hr className="h-px border-primary/40" />
      <div className="flex flex-col gap-2">
        <AssessmentHeader
          type="comment"
          createdAt={createdAt}
          author={author}
          klassId={klassId}
        />
        <Text text={commentAssessment?.comment} />
        <ComponentGuard
          roles={[
            UserTypeEnum.SUPER_ADMIN,
            UserTypeEnum.TEACHER,
            UserTypeEnum.UNIT_ADMIN,
          ]}
        >
          <ConditionalRenderer condition={user?.id === author.id}>
            <MainButton
              dataTestId="editCommentButton"
              text={t('edit')}
              onClick={() => editAssessment({ assessment, tab: 'comment' })}
              icon={<PencilIcon className="w-4" />}
            />
          </ConditionalRenderer>
        </ComponentGuard>
      </div>
    </ConditionalRenderer>
  );
}

function BehaviorAssessmentItem({
  assessment,
  student,
  editAssessment,
  tab,
  klassId,
}: AssessmentProps & { klassId?: number }) {
  const { user } = useAuth();

  const { t: tCommom } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const { t } = useTranslation('translation', {
    keyPrefix: 'pedagogicalSheet',
  });

  const { createdAt, author, commentAssessment, behaviorAssessment } =
    assessment;

  const { isOpen, toggle } = useToggle();

  return (
    <ConditionalRenderer condition={behaviorAssessment}>
      <hr className="h-px border-primary/40" />
      <div className="flex flex-col gap-4">
        <AssessmentHeader
          type="behavior"
          createdAt={createdAt}
          author={author}
          klassId={klassId}
        />
        <BehaviorAssessmentPreview
          studentBehaviorAssessment={behaviorAssessment}
        />
        <ConditionalRenderer condition={!!commentAssessment?.comment}>
          <Text text={commentAssessment?.comment} />
        </ConditionalRenderer>

        <div className="flex flex-col gap-2">
          <MainButton
            className="text-primary uppercase font-500"
            icon={
              <ChevronDownIcon
                className={`w-4 text-primary transform ease-in-out 
                duration-150 stroke-[3] ${isOpen ? 'rotate-180' : ''}`}
              />
            }
            text={t('assessments.moreDetails')}
            color="custom"
            onClick={toggle}
          />

          <AccordionContainer
            initial="closed"
            animate={isOpen ? 'open' : 'closed'}
          >
            <BehaviorAssessmentForm
              studentAssessment={assessment}
              student={student}
              tab={tab}
              disabled
            />
          </AccordionContainer>
        </div>

        <ComponentGuard
          roles={[
            UserTypeEnum.SUPER_ADMIN,
            UserTypeEnum.TEACHER,
            UserTypeEnum.UNIT_ADMIN,
          ]}
        >
          <ConditionalRenderer condition={user?.id === author.id}>
            <MainButton
              dataTestId="editAssessmentButton"
              text={tCommom('edit')}
              onClick={() => editAssessment({ assessment, tab: 'behavior' })}
              icon={<PencilIcon className="w-4" />}
            />
          </ConditionalRenderer>
        </ComponentGuard>
      </div>
    </ConditionalRenderer>
  );
}
