import IconButton from '@/components/common/buttons/IconButton';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import { LoadingIcon } from '@/components/icons';
import useTreeArea from '@/data/hook/useTreeArea';
import { createArea } from '@/data/services/areaServices';
import { areasQueryKeys } from '@/data/services/querykeys';
import Area from '@/models/Area';
import useFilterParams from '@/utils/UseFilterParams';
import { PlusIcon } from '@heroicons/react/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Fragment, useState } from 'react';
import { TopicsFiltersParams } from '../../TopicsPageContent';
import ModalNewTag from '../items/tag/ModalNewTag';
import AddActionsCards from './AddActionsCards';
import useManualQuery from '@/data/hook/query/useManualQuery';
import { getErrorMessage } from '@/utils/getErrorMessage';
import alert from '@/utils/UseAlert';
import { useTranslation } from 'react-i18next';

type AddTagOrAreaProps = {
  area: Area;
};

const AddArea = ({ area }: AddTagOrAreaProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'topics.topbar',
  });

  const { getPath, finalMountTreeObserver } = useTreeArea();
  const { add } = useManualQuery(areasQueryKeys.list()._ctx.infinity);
  const { setFilterParams } = useFilterParams<TopicsFiltersParams>();

  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const queryClient = useQueryClient();

  const addArea = ({ isLeaf }: { isLeaf: boolean }) =>
    createArea({
      name: isLeaf ? t('newTag') : t('newArea'),
      parentAreaId: area.id,
      isLeaf,
    });

  const onAddParentAreaComplete = async (data: Area) => {
    const path = getPath(area.id);
    path?.push(data.id);
    setFilterParams({
      path,
      isRenaming: true,
    });
    finalMountTreeObserver.current.notifyAll();
  };

  const onAddLeafAreaComplete = (data: Area) => {
    const path = getPath(area.id);
    setFilterParams({
      path,
      area: data.id,
    });
  };

  const { mutate: onAddArea, isLoading } = useMutation(addArea, {
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries(
        areasQueryKeys.list({
          parentAreaId: [area.id],
        }),
      );
      add(data);
      if (variables.isLeaf) onAddLeafAreaComplete(data);
      else onAddParentAreaComplete(data);
    },
    onError: e => {
      const errorMessage = getErrorMessage(e);
      alert.error(errorMessage);
    },
  });

  if (isLoading) return <LoadingIcon className="w-5 h-5 text-primary" />;
  return (
    <Fragment>
      <Tooltip
        color="white"
        classNameContainer="flex items-center justify-center h-full"
        options={{
          placement: 'right',
          interactive: true,
          delayHide: 200,
        }}
        text={
          <AddActionsCards
            area={area}
            actions={{
              onAddArea: () => onAddArea({ isLeaf: false }),
              onAddTag: () => onAddArea({ isLeaf: true }),
            }}
          />
        }
      >
        <IconButton
          color="secondary"
          testId="addArea"
          icon={<PlusIcon className="w-5 h-5 text-primary" />}
          loading={isLoading}
        />
      </Tooltip>

      <ModalNewTag
        area={area}
        visible={isVisibleModal}
        onClose={() => setIsVisibleModal(false)}
      />
    </Fragment>
  );
};

export default AddArea;
