import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import RoundedButton from '@/components/common/buttons/RoundedButton';
import Text from '@/components/common/dataDisplay/Text';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import {
  DashedCircleIcon,
  SolidGradientCheckCircleIcon,
} from '@/components/icons';
import useToggle from '@/data/hook/useToggle';
import ActivityProgress from '@/models/ActivityProgress';
import ActivityAttemptButton from '@/components/common/buttons/ActivityAttemptButton';
import ModalWarning from '@/components/common/modals/ModalWarning';
import { updateActivityProgress } from '@/data/services/activityProgressServices';
import useLessonContext from '@/data/hook/lesson';
import useActivityView from '@/data/hook/useActivityView';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';

type StudentResourceActivityProps = {
  resourceId: number;
  activityProgress: ActivityProgress;
};

export default function StudentResourceActivity(
  props: StudentResourceActivityProps,
) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const navigate = useNavigate();

  const { updateLessonProgress } = useLessonContext();

  const { setStatus, resetState } = useActivityView();

  const [loading, setLoading] = useState(false);

  const { lessonId, slugCourseName, klassId } = useParams();

  const {
    activityProgress: {
      activity,
      hasDone,
      id: activityProgressId,
      answersRevealed,
      grade,
    },
    resourceId,
  } = props;

  const baseUrl = `/courses/${slugCourseName}/lesson/${lessonId}/class/${klassId}/resources/${resourceId}/activities/`;

  const { close, open, isOpen } = useToggle();

  async function unlockAnswers() {
    try {
      setLoading(true);
      const unlockedActivityProgress = await updateActivityProgress(
        activityProgressId,
        {
          answersRevealed: true,
        },
      );

      await updateLessonProgress();

      navigate(baseUrl + unlockedActivityProgress.id);
      close();
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  }

  function seeAnswers() {
    if (answersRevealed) {
      resetState('answerkey');
      setStatus('inProgress');

      navigate(baseUrl + activityProgressId);
    } else {
      open();
    }
  }

  function startActivity() {
    resetState(answersRevealed ? 'answerkey' : 'activity');

    navigate(baseUrl + activityProgressId);
  }

  function showGrade(score?: number) {
    if (score) return Math.round(score * 100);
    return 0;
  }

  return (
    <li className="flex flex-col xs:flex-row gap-2.5 justify-between w-full">
      <ModalWarning
        visible={isOpen}
        translationString="modalAnswerKey"
        onClickConfirm={unlockAnswers}
        onClickCancel={close}
        isLoading={loading}
      />

      <span className="flex gap-2.5 overflow-x-hidden items-center">
        <ResourceActivityProgressStatus hasDone={hasDone} />
        <Tooltip
          text={activity.name}
          classNameContainer="overflow-x-hidden"
          color="lightBlue"
        >
          <Text
            text={activity.name}
            className="font-500 text-[1.125rem] text-primary truncate"
          />
        </Tooltip>
      </span>
      <div className="flex items-center gap-2.5 justify-start">
        <ConditionalRenderer condition={hasDone}>
          <div className="flex gap-2.5 items-center">
            <ActivityAttemptButton text={t('template')} onClick={seeAnswers} />
            <ActivityAttemptButton
              text={`${t('grade')}: ${showGrade(grade)}%`}
            />
          </div>
        </ConditionalRenderer>
        <ConditionalRenderer condition={!answersRevealed}>
          <RoundedButton
            testId="startActivityButton"
            text={t('start')}
            className="text-[0.75rem] md:text-[0.875rem]"
            onClick={startActivity}
          />
        </ConditionalRenderer>
      </div>
    </li>
  );
}

function ResourceActivityProgressStatus({
  hasDone,
}: Pick<ActivityProgress, 'hasDone'>) {
  return hasDone ? (
    <SolidGradientCheckCircleIcon className="w-6 h-6 shrink-0" />
  ) : (
    <DashedCircleIcon className="w-6 h-6 shrink-0 text-secondary" />
  );
}
