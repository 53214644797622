import Resource, { ResourceTypeEnum } from '@/models/Resource';
import { ROLES } from '@/utils/roles';
import FileRenderer from './FileRenderer';
import YoutubePlayer from '@/components/common/YoutubePlayer';

type ResouceRendererProps = {
  resource: Resource;
};

export default function ResourceRenderer(props: ResouceRendererProps) {
  const { resource } = props;

  const resourceType = ROLES.RESOURCE.RESOURCE_TYPE(resource);

  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;

  if (resourceType === ResourceTypeEnum.FILE) {
    return <FileRenderer resource={resource} />;
  }

  const isYoutubeLink =
    resourceType === ResourceTypeEnum.LINK &&
    youtubeRegex.test(String(resource.resourceLink));

  if (isYoutubeLink) {
    return <YoutubePlayer videoUrl={String(resource.resourceLink)} />;
  }

  return null;
}
