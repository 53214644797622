import useManualQuery from '@/data/hook/query/useManualQuery';
import { ListAlertsFilters, updateAlert } from '@/data/services/alertServices';
import {
  alertQueryKeys,
  scheduledLessonsQueryKeys,
} from '@/data/services/querykeys';
import { Alert } from '@/models/Alert';
import { createWhatsappLink } from '@/utils/communication/messageApps';
import { ROUTES } from '@/utils/routes';
import NotificationActions from '../action/NotificationActions';
import NotificationCard from './NotificationCard';
import DelayNotificationDetail from '../detail/DelayNoficationDetail';
import { useQuery } from '@tanstack/react-query';

type AbsenceNotificationCardProps = {
  notification: Alert;
  filters: ListAlertsFilters;
};

export type HandleSendMessageArgs = {
  phoneNumber: string;
  text: string;
};
const AbsenceNotificationCard = ({
  notification,
  filters,
}: AbsenceNotificationCardProps) => {
  const { update } = useManualQuery(alertQueryKeys.list(filters)._ctx.infinity);

  const { data: scheduledLesson, isInitialLoading } = useQuery(
    scheduledLessonsQueryKeys.get(notification.targetId),
  );

  const handleMarkAsRead = async (notificationId: number, isRead: boolean) => {
    const updatedNotification = await updateAlert(notificationId, {
      isRead,
    });
    update(updatedNotification);
  };

  const handleSendMessage = async ({
    phoneNumber,
    text,
  }: HandleSendMessageArgs) => {
    const link = createWhatsappLink(phoneNumber, {
      text,
    });
    window.open(link, '_blank')?.focus();
  };

  const handleAddComment = async (id: number, comment: string) => {
    const updatedAlert = await updateAlert(id, { comment });
    update(updatedAlert);
  };
  const link = ROUTES.ADMIN.STUDENTS.INFO({ search: notification.actor.title });

  return (
    <NotificationCard
      actor={{
        text: notification.actor.title,
        link,
      }}
      notification={notification}
      onMarkAsRead={handleMarkAsRead}
      detail={
        <DelayNotificationDetail
          target={scheduledLesson}
          isLoading={isInitialLoading}
        />
      }
      actions={
        <NotificationActions
          onSendMessage={handleSendMessage}
          onAddComment={handleAddComment}
          notification={notification}
        />
      }
    />
  );
};

export default AbsenceNotificationCard;
