import MainButton from '@/components/common/buttons/MainButton';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

type StartHiddenInputProps = {
  value: boolean;
  onChange: (value: boolean) => void;
};
const StartHiddenInput = ({ value, onChange }: StartHiddenInputProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'section.form',
  });
  const { text, icon: Icon } = {
    text: !value ? t('startsVisible') : t('startsHidden'),
    icon: !value ? EyeIcon : EyeOffIcon,
  };
  return (
    <MainButton
      color="custom"
      text={text}
      icon={<Icon className="relative text-primary w-5 h-5 -top-0.5" />}
      onClick={() => onChange(!value)}
    />
  );
};

export default StartHiddenInput;
