import Chapter from '@/models/Chapter';
import Text from '../../../dataDisplay/Text';
import ChapterLayoutView from './layout/ChapterLayoutView';
type ChapterViewProps = {
  chapter: Chapter;
};

export default function ChapterView({ chapter }: ChapterViewProps) {
  const sections = chapter.sections || [];
  const layout = chapter.layout || { type: 'layout1' };
  return (
    <div className="flex flex-col gap-6 items-center w-full">
      <Text
        className="font-poppins text-24 text-base-content font-700 underline decoration-secondary/50 decoration-2 underline-offset-4"
        text={chapter.title}
      />
      <div className="flex flex-wrap gap-4 w-full">
        <ChapterLayoutView layout={layout} sections={sections} />
      </div>
    </div>
  );
}
