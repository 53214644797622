import React, { PropsWithChildren, useEffect, useState } from 'react';
import { ElementPreviewFactoryProps } from '../ElementPreviewFactory';
import useAuth from '@/data/hook/useAuth';
import { isStudent } from '@/functions/auth';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  codeEditorActivitiesQueryKeys,
  codeEditorProgressQueryKeys,
} from '@/data/services/querykeys';
import ActivityHeading from '../ActivityHeading';
import { CodeEditorActivity } from '@/models/CodeEditorActivity';
import CodeEditor from '@/components/codeEditor/CodeEditor';
import { useTranslation } from 'react-i18next';
import { useAnswerDebounce } from '../ActivitiesPreview.hooks';
import { updateCodeEditorProgress } from '@/data/services/activityElement/codeEditorActivityProgressServices';
import { ActivityTypeEnum } from '@/models/Activity';
import alert from '@/utils/UseAlert';
import { getErrorMessage } from '@/utils/getErrorMessage';
import useActivityView from '@/data/hook/useActivityView';
import Tag from '@/components/common/dataDisplay/Tag';
import LoadingView from '@/pages/courses/LoadingView';
import ErrorComponent from '@/components/common/ErrorComponent';

function CodeEditorActivityElement({
  progressId,
  questionId,
  activityMode,
}: ElementPreviewFactoryProps) {
  const { user } = useAuth();

  const studentView = isStudent(user?.userType);

  if (studentView) {
    return (
      <StudentView
        key={progressId}
        progressId={progressId}
        questionId={questionId}
        activityMode={activityMode}
      />
    );
  }

  return <StaffView key={questionId} questionId={questionId} />;
}

function StudentView({
  progressId,
  questionId: activityId,
}: Pick<
  ElementPreviewFactoryProps,
  'questionId' | 'progressId' | 'activityMode'
>) {
  const [value, setValue] = useState<string>();
  const { queryFn, queryKey } = codeEditorProgressQueryKeys.get({
    progressId,
    activityId,
  });
  const {
    data: codeEditorProgress,
    isInitialLoading: isLoading,
    error,
  } = useQuery({
    queryFn,
    queryKey,
  });
  const { invalidate, blockStepNavigate, mode } = useActivityView();
  const queryClient = useQueryClient();

  const { debounce } = useAnswerDebounce(500);
  const saveAnswer = () => {
    blockStepNavigate(true);
    return updateCodeEditorProgress(
      { activityId, progressId },
      { answer: value, isDone: true },
    );
  };
  const { mutate: updateAnswer } = useMutation(saveAnswer, {
    async onSuccess(data) {
      queryClient.setQueryData(queryKey, data);
      await invalidate(ActivityTypeEnum.CODE_EDITOR, data);
    },
    onError(error: any) {
      alert.error(getErrorMessage(error));
    },
    onSettled() {
      blockStepNavigate(false);
    },
  });
  const activity = codeEditorProgress?.codeEditorActivity;

  const onAnswer = (value: string | undefined) => {
    setValue(value || '');
    debounce(updateAnswer);
  };

  useEffect(() => {
    if (typeof value !== 'undefined') return;

    if (codeEditorProgress?.answer) return setValue(codeEditorProgress?.answer);

    if (activity?.initialCode) return setValue(activity.initialCode);
  }, [activity?.initialCode, codeEditorProgress?.answer, value]);

  return (
    <ActivityDisplay isLoading={isLoading} error={error} activity={activity}>
      {activity?.language ? (
        <CodeEditor
          language={activity.language}
          value={value}
          key={codeEditorProgress?.id}
          onChangeCode={onAnswer}
          readOnly={mode !== 'activity'}
        />
      ) : null}
    </ActivityDisplay>
  );
}

type StaffViewProps = {
  questionId: number;
};

function StaffView({ questionId }: StaffViewProps) {
  const {
    data: codeEditorActivity,
    isLoading,
    error,
  } = useQuery(codeEditorActivitiesQueryKeys.get(questionId));

  const initialCode = codeEditorActivity?.initialCode;
  const language = codeEditorActivity?.language;

  return (
    <ActivityDisplay
      activity={codeEditorActivity}
      isLoading={isLoading}
      error={error}
    >
      {language ? (
        <CodeEditor
          key={codeEditorActivity.id}
          language={language}
          value={initialCode ?? ''}
        />
      ) : null}
    </ActivityDisplay>
  );
}

type ActivityDisplayProps = {
  activity?: CodeEditorActivity;
  isLoading?: boolean;
  error?: unknown;
};

const ActivityDisplay: React.FC<PropsWithChildren<ActivityDisplayProps>> = ({
  activity,
  error,
  isLoading,
  children,
}) => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'codeEditorLanguage',
  });
  const errorDetail = getErrorMessage(error);

  if (isLoading && !activity) {
    return <LoadingView />;
  }
  if (error || !activity) {
    return <ErrorComponent errorTextTitle={errorDetail} />;
  }

  return (
    <div className="flex flex-col gap-1 w-11/12">
      <ActivityHeading
        heading={activity.title}
        subheading={activity.subtitle}
        content={activity.content}
      />
      <Tag text={t(activity.language)} className="border border-primary" />
      {children}
    </div>
  );
};

export default CodeEditorActivityElement;
