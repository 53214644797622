import { useTranslation } from 'react-i18next';
import Modal from './Modal';
import RoundedButton from '../buttons/RoundedButton';
import Text from '../dataDisplay/Text';

type color = 'warning' | 'primary';

interface ModalColor {
  title: 'text-error' | 'text-primary';
  button: 'warning' | 'gradient';
}

const modalTheme: Record<color, ModalColor> = {
  primary: {
    button: 'gradient',
    title: 'text-primary',
  },
  warning: {
    button: 'warning',
    title: 'text-error',
  },
};

interface ModalDisableProps {
  visible: boolean;
  modalType?: 'disable' | 'delete' | 'confirm';
  testId?: string;
  selectedObjectName?: string;
  translationString: string;
  isRequesting?: boolean;
  onClickConfirm: (e?: any) => void;
  onClickCancel: (e?: any) => void;
  modalColor?: color;
}

export default function ModalDisable(props: ModalDisableProps) {
  const {
    visible,
    modalType,
    selectedObjectName,
    translationString,
    onClickCancel,
    onClickConfirm,
    isRequesting,
    modalColor = 'warning',
    testId,
  } = props;
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalDisable',
  });
  function closeModal() {
    if (isRequesting) return;
    onClickCancel();
  }

  const theme = modalTheme[modalColor];
  return (
    <Modal visible={visible} onClose={closeModal} testId={testId}>
      <div
        className="flex flex-col gap-6 items-center w-full"
        data-testid="modalDisable"
      >
        <Text
          text={t(`${translationString}.warning`)}
          format="rubik-500"
          size="text-18"
          className={theme.title}
        />

        <Text
          text={selectedObjectName}
          format="rubik-500"
          size="text-18"
          className="text-base-content capitalize"
        />

        <Text
          text={t(`${translationString}.lostChangesWarning`)}
          format="rubik-400"
          size="text-16"
          className="text-base-content"
        />

        <div className="flex w-full justify-around gap-6">
          <RoundedButton
            disabled={isRequesting}
            color="neutral"
            className="w-full"
            text={t('cancel')}
            onClick={onClickCancel}
            id="cancel"
          />
          <RoundedButton
            text={t(modalType || 'disable')}
            loading={isRequesting}
            testId="disableButton"
            type="button"
            color={theme.button}
            className="w-full"
            onClick={onClickConfirm}
            id="confirm"
          />
        </div>
      </div>
    </Modal>
  );
}
