import { useParams } from 'react-router-dom';

import LessonCard, {
  LessonCardProps,
} from '@/components/lessons/LessonCard/LessonCard';
import useInfiniteService from '@/data/hook/useInfiniteService';
import {
  resourceProgressQuerykeys,
  resourceQuerykeys,
} from '@/data/services/querykeys';
import {
  MANAGEMENT_PAGE_SIZE as pageSize,
  REQUEST_STALE_TIME_IN_MS as staleTime,
} from '@/constants';
import InfinityList from '../InfinityList';
import ConditionalRenderer from '../ConditionalRenderer';
import {
  CircleIcon,
  DashedCircleIcon,
  SolidGradientCheckCircleIcon,
} from '@/components/icons';
import Text from '../dataDisplay/Text';
import { getErrorMessage } from '@/utils/getErrorMessage';
import Resource from '@/models/Resource';
import LessonCardItem from '@/components/lessons/LessonCard/LessonCardItem';
import { ResourceProgress } from '@/models/ResourceProgress';
import useListService from '@/data/hook/useListService';

type ResourceCardProps = LessonCardProps & {
  resourceIds: number[];
  isStaff?: boolean;
};

export default function ResourceCard(props: ResourceCardProps) {
  const { resourceIds, isStaff } = props;

  const filters = {
    id: resourceIds,
    pageSize,
  };

  const {
    results: resources,
    isInitialLoading: loadingResources,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    error: resourcesError,
  } = useInfiniteService({
    ...resourceQuerykeys.list(filters)._ctx.infinity,
  });

  const {
    results: resourceProgress,
    isInitialLoading: loadingResourceProgress,
    error: resourceProgressError,
  } = useListService({
    ...resourceProgressQuerykeys.list({
      resourceId: resourceIds,
    }),
    enabled: !!resources.length && !isStaff,
  });

  const error = resourcesError || resourceProgressError;

  const loading = loadingResources || loadingResourceProgress;

  const pendingResourcesNotification = resourceProgress.reduce(
    (prev, { hasOpened }) => (!hasOpened ? prev + 1 : prev),
    0,
  );

  return (
    <ConditionalRenderer condition={resourceIds.length}>
      <LessonCard
        loading={loading}
        testId="resourceCard"
        {...props}
        notification={isStaff ? undefined : pendingResourcesNotification}
      >
        <ConditionalRenderer condition={error}>
          <Text text={getErrorMessage(error)} className="mt-4" />
        </ConditionalRenderer>
        <ConditionalRenderer
          condition={resources.length && !loadingResources && !error}
        >
          <InfinityList
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            onReachEnd={fetchNextPage}
            className="gap-2 max-h-[20rem] overflow-x-hidden mt-4"
            scroll
          >
            {resources.map((resource, index) => (
              <LessonResource
                index={index}
                key={resource.id}
                resource={resource}
                resourceProgress={resourceProgress.find(
                  progress => progress.resourceId === resource.id,
                )}
                {...props}
              />
            ))}
          </InfinityList>
        </ConditionalRenderer>
      </LessonCard>
    </ConditionalRenderer>
  );
}

type LessonResourceProps = {
  resource: Resource;
  resourceProgress?: ResourceProgress;
  index: number;
  isStaff?: boolean;
  active?: boolean;
};

export function LessonResource(props: LessonResourceProps) {
  const { resource, resourceProgress, active } = props;

  const { resourceId } = useParams();

  const baseUrl = 'resources';

  const selected = active && Number(resourceId) === resource.id;

  return (
    <LessonCardItem
      content={resource.title}
      icon={<ResourceProgressIcon {...resourceProgress} {...props} />}
      link={`${baseUrl}/${resource.id}`}
      selected={selected}
    />
  );
}

type ResourceProgressIconProps = {
  isStaff?: boolean;
  hasOpened?: boolean;
};

function ResourceProgressIcon(props: ResourceProgressIconProps) {
  const { isStaff, hasOpened } = props;

  if (isStaff) return <CircleIcon className="w-4 h-4" />;

  return hasOpened ? (
    <SolidGradientCheckCircleIcon className="w-4 h-4 shrink-0" />
  ) : (
    <DashedCircleIcon className="w-4 h-4 shrink-0" />
  );
}
