import { twMerge } from 'tailwind-merge';

import Text from './Text';
import { ClassNameComponent } from '@/utils/ClassNameComponent';
import { ReactNode } from 'react';

type IconWithTextProps = {
  icon: ReactNode;
  text: string;
  className?: Pick<ClassNameComponent, 'base' | 'text'>;
  href?: string;
};

export function IconWithText({
  icon,
  text,
  className,
  href,
}: IconWithTextProps) {
  return (
    <div
      className={twMerge(
        'flex flex-row self-start items-center gap-2.5',
        className?.base,
      )}
    >
      {icon}
      <Text
        href={href}
        text={text}
        className={twMerge('text-16 font-500', className?.text)}
      />
    </div>
  );
}
