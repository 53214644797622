import { SectionIcons, SectionIconsType } from '@/models/Book';

type SectionIconProps = {
  icon: SectionIconsType;
};

export function getSectionIcon(icon: string) {
  if (icon in SectionIcons) {
    return SectionIcons[icon as SectionIconsType];
  }
  return SectionIcons['AcademicCapIcon'];
}

const SectionIcon = ({ icon }: SectionIconProps) => {
  const Icon = getSectionIcon(icon);
  return <Icon className="w-4 h-4 text-base-content flex-shrink-0" />;
};
export default SectionIcon;
