import { onEditBookFn } from '@/components/admin/lessons/material/Material.hooks';
import ModalDisable from '@/components/common/modals/ModalDisable';
import useCourseEditing from '@/data/hook/useCourseEditing';
import useToggle from '@/data/hook/useToggle';
import Book from '@/models/Book';
import Chapter from '@/models/Chapter';
import useFilterParams from '@/utils/UseFilterParams';
import { EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MainButton from '../../../buttons/MainButton';
import ConditionalRenderer from '../../../ConditionalRenderer';
import ConditionalWrapper from '../../../ConditionalWrapper';
import Text from '../../../dataDisplay/Text';
import { Tooltip } from '../../../dataDisplay/Tooltip';
import ChapterService from '../chapters/ChapterService';
import ChapterView from '../chapters/ChapterView';
import { useChapter } from './BookForm.hooks';
import Chapters from './Chapters';

export type BookFormFields = Pick<Book, 'filename'>;

type BookFormProps = {
  book: Book;
  readOnly?: boolean;
  lessonId: number;
  onUpdateChapter: (chapter: Chapter) => void;
  onDeleteChapter: (chapter: Chapter) => void;
  onReorderChapters: (chapters: Chapter[]) => void;
  onDeleteBook: () => void;
  onEditBook?: onEditBookFn;
  isEditingBook?: boolean;
  onAddChapter: () => Promise<Chapter>;
  isDeletingBook: boolean;
  isAddingChapter: boolean;
  onSubmit: (params: {
    book: Partial<BookFormFields>;
    validated: boolean;
  }) => void;
};

export default function BookForm({
  book,
  lessonId,
  onSubmit,
  onUpdateChapter,
  onAddChapter,
  onDeleteBook,
  onEditBook,
  isEditingBook,
  isAddingChapter,
  onReorderChapters,
  onDeleteChapter,
  isDeletingBook,
}: BookFormProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'book.form',
  });
  const { isOpen: isOpenDeleteModal, open, close } = useToggle(false);
  const { t: tPage } = useTranslation('translation', {
    keyPrefix: 'editMaterialPage',
  });
  const { setFilterParams, filterParams } = useFilterParams();
  const { allowBigChanges } = useCourseEditing();

  const editing = filterParams.get('editing') === 'true';
  const setEditing = (editing: boolean) => setFilterParams({ editing });

  const { chapter, setChapter } = useChapter({ book });

  const { watch, trigger } = useForm<BookFormFields>({
    defaultValues: {
      filename: book.filename,
    },
  });

  const handleOnClickChapter = (chapter: Chapter) => setChapter(chapter);

  const handleOnAddChapter = async () => {
    const chapter = await onAddChapter();
    setChapter(chapter);
  };

  const handleOnDeleteChapter = (chapter: Chapter) => {
    const index = book.chapters.findIndex(c => c.id === chapter.id);

    if (index > -1) {
      const updatedChapters = [...book.chapters];
      updatedChapters.splice(index, 1);

      const newChapter =
        updatedChapters[index] || updatedChapters[index - 1] || null;
      setChapter(newChapter);
      onDeleteChapter(chapter);
    }
  };

  const handleEditingBook = async () => {
    const result = await onEditBook?.();
    if (result) {
      setEditing(true);
    }
  };

  useEffect(() => {
    const subscribe = watch(book =>
      trigger().then(validated =>
        onSubmit({ book: book as BookFormFields, validated }),
      ),
    );
    return () => {
      subscribe.unsubscribe();
    };
  }, [onSubmit, watch, trigger]);

  return (
    <div className="flex flex-col gap-4 w-full">
      <div className="flex items-center justify-between">
        <Text text={t('title')} className="text-primary text-20 font-500" />

        <div className="flex gap-4">
          <ConditionalRenderer
            condition={!editing}
            fallback={
              <MainButton
                color="custom"
                text={t('view')}
                onClick={() => setEditing(false)}
                className="text-primary font-500  gap-1  flex-row-reverse "
                icon={<EyeIcon className="text-primary" />}
              />
            }
          >
            <MainButton
              color="custom"
              text={t('edit')}
              loading={isEditingBook}
              onClick={handleEditingBook}
              className="text-primary font-500  gap-1  flex-row-reverse "
              icon={<PencilIcon className="text-primary" />}
            />
          </ConditionalRenderer>

          <ConditionalWrapper
            condition={!allowBigChanges}
            wrapper={<Tooltip text={tPage('cantModify')} />}
          >
            <MainButton
              color="custom"
              text={t('delete')}
              disabled={!allowBigChanges}
              onClick={open}
              className="text-error font-500 gap-1 flex-row-reverse"
              icon={<TrashIcon className="text-error" />}
            />
          </ConditionalWrapper>
        </div>
      </div>
      <div className="relative flex gap-3 w-full">
        <div className="relative w-full max-w-[calc(100%-268px)]">
          <ConditionalRenderer condition={editing}>
            {chapter && (
              <ChapterService
                key={chapter.id}
                bookId={book.id}
                isUnique={book.chapters.length === 1}
                bookType={book.bookType}
                lessonId={lessonId}
                chapter={chapter}
                onSubmit={onUpdateChapter}
                onDelete={handleOnDeleteChapter}
              />
            )}
          </ConditionalRenderer>
          <ConditionalRenderer condition={!editing}>
            {chapter && <ChapterView key={chapter.id} chapter={chapter} />}
          </ConditionalRenderer>
        </div>
        <Chapters
          key={JSON.stringify(book)}
          chapters={book.chapters}
          active={chapter}
          readOnly={!editing || !allowBigChanges}
          onReorder={onReorderChapters}
          onAddChapter={handleOnAddChapter}
          isAddingChapter={isAddingChapter}
          onClickChapter={handleOnClickChapter}
        />
      </div>
      <ModalDisable
        testId="deleteBookModal"
        modalType="delete"
        visible={isOpenDeleteModal}
        onClickConfirm={onDeleteBook}
        onClickCancel={close}
        translationString="modalRemoveBook"
        isRequesting={isDeletingBook}
      />
    </div>
  );
}
