import { Route, Routes } from 'react-router-dom';
import ConfirmInfoView from './components/acquisition/ConfirmInfoView';
import TrialLessonSuccessView from './components/acquisition/TrialLessonSuccessView';
import EditMaterialPage from './components/admin/lessons/material/EditMaterialPage/EditMaterialPage';
import AuthGuard from './components/auth/AuthGuard';
import HowItWorksView from './components/parents/HowItWorksView';
import './i18n';
import { UserTypeEnum } from './models/User';
import ProfilePage from './pages/ProfilePage';
import AccountPage from './pages/account/AccountPage';
import AdminPage from './pages/admin/AdminPage';
import KlassProgressView from './pages/admin/KlassProgressView';
import ManageActivityEdit from './pages/admin/ManageActivityEdit';
import ManageCourse from './pages/admin/ManageCourse';
import ManageEditingCourse from './pages/admin/ManageCourseEditing';
import ManageKlass from './pages/admin/ManageKlasses';
import ManageRecessCalendars from './pages/admin/ManageRecessCalendars';
import ManageStudent from './pages/admin/ManageStudent';
import ManageTeacher from './pages/admin/ManageTeacher';
import ManageTeam from './pages/admin/ManageTeam';
import ManageTrialLesson from './pages/admin/ManageTrialLesson';
import ManageUnit from './pages/admin/ManageUnit';
import SuggestionsPage from './pages/admin/SuggestionsPage';
import CalendarPage from './pages/calendar/CalendarPage';
import CoursesPage from './pages/courses/CoursesPage';
import TeacherFeedbackPage from './pages/feedback/TeacherFeedbackPage';
import KlassPageAdmin from './pages/klass/KlassPageAdmin';
import KlassView from './pages/klass/KlassView';
import KlassLessons from './pages/klass/lessons/KlassLessons';
import ManageExtraLessonsActivity from './pages/klass/lessons/ManageExtraLessonsActivity';
import LoginHandler from './pages/login/LoginHandler';
import LoginPage from './pages/login/LoginPage';
import ResetPassword from './pages/login/ResetPassword';
import ErrorPage from './pages/notFound/ErrorPage';
import ProjectPage from './pages/project/ProjectPage';
import ProjectView from './pages/project/ProjectView';
import ManageScheduledLessonReport from './pages/teacher/ManageScheduledLessonReport';
import TeacherPage from './pages/teacher/TeacherPage';
import StudentHistoryPage from './pages/admin/StudentHistoryPage';
import { ROUTES } from '@/utils/routes';
import TopicsPage from './pages/admin/TopicsPage';
import NotificationsPage from './pages/admin/NotificationsPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import SkillPage from './pages/skill/SkillPage';

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<DashboardPage />} />
      <Route path="/skills" element={<SkillPage />} />
      <Route path="/skills/:areaId" element={<SkillPage />} />
      <Route path="/projects">
        <Route index element={<ProjectPage />} />
        <Route path=":id" element={<ProjectView />} />
      </Route>
      <Route
        path="/teacher"
        element={
          <AuthGuard roles={[UserTypeEnum.TEACHER]}>
            <TeacherPage />
          </AuthGuard>
        }
      />
      <Route
        path="/admin"
        element={
          <AuthGuard
            roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
          >
            <AdminPage />
          </AuthGuard>
        }
      />
      <Route
        path="/admin/courses/:isAdd"
        element={
          <AuthGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
            <ManageCourse />
          </AuthGuard>
        }
      />
      <Route
        path={ROUTES.ADMIN.CLASSES.BASE}
        element={
          <AuthGuard
            roles={[
              UserTypeEnum.SUPER_ADMIN,
              UserTypeEnum.UNIT_ADMIN,
              UserTypeEnum.TEACHER,
            ]}
          >
            <ManageKlass />
          </AuthGuard>
        }
      />

      <Route
        path={ROUTES.ADMIN.CLASSES.ACTION(':actionId')}
        element={
          <AuthGuard
            roles={[
              UserTypeEnum.SUPER_ADMIN,
              UserTypeEnum.UNIT_ADMIN,
              UserTypeEnum.TEACHER,
            ]}
          >
            <ManageKlass />
          </AuthGuard>
        }
      />
      <Route
        path={ROUTES.CLASS.PROGRESS(':klassId')}
        element={
          <AuthGuard
            roles={[
              UserTypeEnum.SUPER_ADMIN,
              UserTypeEnum.UNIT_ADMIN,
              UserTypeEnum.TEACHER,
            ]}
          >
            <KlassProgressView />
          </AuthGuard>
        }
      />
      <Route
        path="/admin/student-history/:studentId"
        element={
          <AuthGuard
            roles={[
              UserTypeEnum.SUPER_ADMIN,
              UserTypeEnum.UNIT_ADMIN,
              UserTypeEnum.TEACHER,
            ]}
          >
            <StudentHistoryPage />
          </AuthGuard>
        }
      />

      <Route
        path="/admin/students/"
        element={
          <AuthGuard
            roles={[
              UserTypeEnum.SUPER_ADMIN,
              UserTypeEnum.UNIT_ADMIN,
              UserTypeEnum.TEACHER,
            ]}
          >
            <ManageStudent />
          </AuthGuard>
        }
      />

      <Route
        path={ROUTES.ADMIN.STUDENTS.ACTION(':actionId')}
        element={
          <AuthGuard
            roles={[
              UserTypeEnum.SUPER_ADMIN,
              UserTypeEnum.UNIT_ADMIN,
              UserTypeEnum.TEACHER,
            ]}
          >
            <ManageStudent />
          </AuthGuard>
        }
      />

      <Route
        path="/admin/teachers/"
        element={
          <AuthGuard
            roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
          >
            <ManageTeacher />
          </AuthGuard>
        }
      />
      <Route
        path={ROUTES.ADMIN.TEACHERS.ACTION(':actionId')}
        element={
          <AuthGuard
            roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
          >
            <ManageTeacher />
          </AuthGuard>
        }
      />

      <Route
        path="/admin/team/t/:username"
        element={
          <AuthGuard
            roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
          >
            <ManageTeam />
          </AuthGuard>
        }
      />
      <Route
        path="/admin/trial-lesson"
        element={
          <AuthGuard
            roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
          >
            <ManageTrialLesson />
          </AuthGuard>
        }
      />
      <Route
        path="/admin/team/"
        element={
          <AuthGuard
            roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
          >
            <ManageTeam />
          </AuthGuard>
        }
      />
      <Route
        path={ROUTES.ADMIN.TEAM.ACTION(':actionId')}
        element={
          <AuthGuard
            roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
          >
            <ManageTeam />
          </AuthGuard>
        }
      />
      <Route
        path="/admin/recesscalendars"
        element={
          <AuthGuard roles={[UserTypeEnum.UNIT_ADMIN]}>
            <ManageRecessCalendars />
          </AuthGuard>
        }
      />
      <Route
        path="/admin/topics"
        element={
          <AuthGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
            <TopicsPage />
          </AuthGuard>
        }
      />
      <Route
        path={ROUTES.NOTIFICATIONS.BASE}
        element={
          <AuthGuard
            roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
          >
            <NotificationsPage />
          </AuthGuard>
        }
      />
      <Route
        path="/como-funciona"
        element={
          <AuthGuard roles={[UserTypeEnum.PARENT]}>
            <HowItWorksView />
          </AuthGuard>
        }
      />
      <Route path="/courses">
        <Route index element={<CoursesPage />} />
        <Route
          path="/courses/:slugCourseName"
          element={
            <AuthGuard
              roles={[
                UserTypeEnum.SUPER_ADMIN,
                UserTypeEnum.UNIT_ADMIN,
                UserTypeEnum.TEACHER,
              ]}
            >
              <KlassPageAdmin />
            </AuthGuard>
          }
        />
        <Route
          path="/courses/:slugCourseName/class/:klassId"
          element={
            <AuthGuard
              roles={[
                UserTypeEnum.SUPER_ADMIN,
                UserTypeEnum.UNIT_ADMIN,
                UserTypeEnum.TEACHER,
              ]}
            >
              <KlassPageAdmin />
            </AuthGuard>
          }
        />
      </Route>
      <Route path="/forbidden" element={<ErrorPage statusCode={403} />} />
      <Route path="/login" element={<LoginPage />}>
        <Route index element={<LoginHandler />} />
        <Route
          path="reset-password/:uid/u/:token"
          element={<ResetPassword />}
        />
      </Route>
      <Route path="/profile/:username" element={<ProfilePage />} />
      <Route path="*" element={<ErrorPage statusCode={404} />} />

      <Route
        path="/student-lesson-feedback"
        element={
          <AuthGuard
            roles={[
              UserTypeEnum.TEACHER,
              UserTypeEnum.SUPER_ADMIN,
              UserTypeEnum.UNIT_ADMIN,
            ]}
          >
            <TeacherFeedbackPage />
          </AuthGuard>
        }
      />
      <Route path="/agendamento">
        <Route path=":trialLessonId" element={<ConfirmInfoView />} />
        <Route
          path="confirm/:trialLessonEnrollmentId/:trialLessonId"
          element={<TrialLessonSuccessView />}
        />
      </Route>
      <Route
        path="/admin/calendars"
        element={
          <AuthGuard
            roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
          >
            <ManageRecessCalendars />
          </AuthGuard>
        }
      />
      <Route
        path="/admin/units/:unitId/recess-calendar"
        element={
          <AuthGuard
            roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
          >
            <ManageRecessCalendars />
          </AuthGuard>
        }
      />
      <Route
        path="/admin/courses/"
        element={
          <AuthGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
            <ManageCourse />
          </AuthGuard>
        }
      />
      <Route
        path="/admin/courses/:slugCourseName/lessons/editing"
        element={
          <AuthGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
            <ManageEditingCourse page="edit" />
          </AuthGuard>
        }
      />
      <Route
        path="/admin/courses/:slugCourseName/lessons/versioning"
        element={
          <AuthGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
            <ManageEditingCourse page="version" />
          </AuthGuard>
        }
      />
      <Route
        path="/admin/courses/:slugCourseName/lessons/draft"
        element={
          <AuthGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
            <ManageEditingCourse page="draft" />
          </AuthGuard>
        }
      />
      <Route
        path={
          '/admin/courses/:slugCourseName/lessons/:lessonId/:bookType/:bookId'
        }
        element={
          <AuthGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
            <EditMaterialPage />
          </AuthGuard>
        }
      >
        <Route
          path={
            '/admin/courses/:slugCourseName/lessons/:lessonId/:bookType/:bookId/chapter/:chapterId'
          }
          element={
            <AuthGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
              <EditMaterialPage />
            </AuthGuard>
          }
        />
      </Route>
      <Route
        path="/courses/:slugCourseName/lesson/:lessonId/class/:klassId"
        element={<KlassView />}
      >
        <Route index element={<KlassView />} />
        <Route path="activities" element={<KlassView />}>
          <Route index element={<KlassView />} />
          <Route path=":activityId" element={<KlassView />}>
            <Route index element={<KlassView />} />
            <Route path="view" element={<KlassView />} />
          </Route>
          <Route path="homework" element={<KlassView />} />
        </Route>

        <Route path="help" element={<KlassView />} />
        <Route path="book/chapter/:chapterOrder" element={<KlassView />} />
        <Route path="t/book/chapter/:chapterOrder" element={<KlassView />} />
        <Route path="challenge" element={<KlassView />} />
        <Route path="resources" element={<KlassView />} />
        <Route path="resources/:resourceId" element={<KlassView />} />
        <Route
          path="resources/:resourceId/activities/:activityId"
          element={<KlassView />}
        >
          <Route index element={<KlassView />} />
          <Route path="view" element={<KlassView />} />
        </Route>
      </Route>
      <Route
        path="/courses/:slugCourseName/lesson/:lessonId"
        element={<KlassView />}
      >
        <Route index element={<KlassView />} />
        <Route path="activities" element={<KlassView />}>
          <Route index element={<KlassView />} />
          <Route path=":activityId" element={<KlassView />} />
          <Route path="homework" element={<KlassView />} />
        </Route>

        <Route path="help" element={<KlassView />} />
        <Route path="book/chapter/:chapterOrder" element={<KlassView />} />
        <Route path="t/book/chapter/:chapterOrder" element={<KlassView />} />
        <Route path="challenge" element={<KlassView />} />
        <Route path="resources" element={<KlassView />} />
        <Route path="resources/:resourceId" element={<KlassView />} />
        <Route
          path="resources/:resourceId/activities/:activityId"
          element={<KlassView />}
        />
      </Route>
      <Route
        path="/admin/courses/:slugCourseName/lessons/:lessonId/activities/:activityId"
        element={
          <AuthGuard
            roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
          >
            <ManageActivityEdit />
          </AuthGuard>
        }
      ></Route>
      <Route path="/admin/units">
        <Route
          index
          element={
            <AuthGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
              <ManageUnit />
            </AuthGuard>
          }
        />
        <Route
          path=":isAdd"
          element={
            <AuthGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
              <ManageUnit />
            </AuthGuard>
          }
        />
      </Route>
      <Route
        path="/calendar"
        element={
          <AuthGuard
            roles={[
              UserTypeEnum.SUPER_ADMIN,
              UserTypeEnum.TEACHER,
              UserTypeEnum.UNIT_ADMIN,
              UserTypeEnum.PARENT,
            ]}
          >
            <CalendarPage />
          </AuthGuard>
        }
      />
      <Route path="/account" element={<AccountPage />} />
      <Route path="/sugestoes" element={<SuggestionsPage />} />
      <Route
        path="/lesson-report"
        element={
          <AuthGuard roles={[UserTypeEnum.TEACHER]}>
            <ManageScheduledLessonReport />
          </AuthGuard>
        }
      />
      <Route
        path="/class/:klassId/lesson-report/:scheduledLessonId"
        element={
          <AuthGuard
            roles={[
              UserTypeEnum.TEACHER,
              UserTypeEnum.SUPER_ADMIN,
              UserTypeEnum.UNIT_ADMIN,
            ]}
          >
            <ManageScheduledLessonReport />
          </AuthGuard>
        }
      />
      <Route path="/class/:klassId/lessons">
        <Route
          index
          element={
            <AuthGuard
              roles={[
                UserTypeEnum.TEACHER,
                UserTypeEnum.SUPER_ADMIN,
                UserTypeEnum.UNIT_ADMIN,
              ]}
            >
              <KlassLessons />
            </AuthGuard>
          }
        />
        <Route
          path=":lessonId/activities/:activityId"
          element={
            <AuthGuard
              roles={[
                UserTypeEnum.SUPER_ADMIN,
                UserTypeEnum.UNIT_ADMIN,
                UserTypeEnum.TEACHER,
              ]}
            >
              <ManageExtraLessonsActivity />
            </AuthGuard>
          }
        />
      </Route>
      <Route path="*" element={<ErrorPage statusCode={404} />} />
    </Routes>
  );
};

export default Router;
