import useTheme from '@/data/hook/useTheme';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

export type ChartData = {
  label: string;
  value: number;
  onClickLabel?(): void | Promise<void>;
};

type Label = Partial<{ size: string; weight: string }>;
type RadarColor = Partial<{ polygon: string; connectorColors: string }>;

type ChartOptions = Partial<{
  offsetX: number;
  offsetY: number;
  height: number | string;
  width: number | string;
  label: Label;
  seriesName: string;
  radarColor: RadarColor;
}>;

type RadarChartProps = {
  data: ChartData[];
  chartOptions?: ChartOptions;
};

function RadarChart({ data, chartOptions = {} }: RadarChartProps) {
  const { activeTheme, themeName: theme } = useTheme();

  const categories = data.map(({ label }) => label);
  const style = {
    fontFamily: 'Poppins',
    fontSize: chartOptions.label?.size || '1.5rem',
    colors: categories.map(() => activeTheme['base-content']),
    fontWeight: chartOptions.label?.weight || '500',
  };
  const plotOptions: ApexPlotOptions = {
    radar: {
      polygons: {
        strokeColors:
          activeTheme['neutral-focus'] || chartOptions.radarColor?.polygon,
        connectorColors:
          activeTheme['neutral-focus'] ||
          chartOptions.radarColor?.connectorColors,
      },
    },
  };

  function xAxisLabelClick(e: PointerEvent) {
    const target = e.target;
    if (!target) return;

    if (!('innerHTML' in target && typeof target.innerHTML === 'string'))
      return;

    const label = target.innerHTML;
    const foundData = data.find(
      ({ onClickLabel, label: dataLabel }) =>
        !!onClickLabel && label === dataLabel,
    );
    foundData?.onClickLabel?.();
  }
  function mouseMove(e: MouseEvent) {
    const target = e.target;
    if (!target || !('innerHTML' in target)) return;
    if (typeof target.innerHTML !== 'string' || !('classList' in target))
      return;
    const content = data.find(({ label }) => label === target.innerHTML);
    if (
      !content ||
      !(target.classList instanceof DOMTokenList) ||
      !content.onClickLabel
    )
      return;

    target.classList.add('chart-label');
  }

  const options: ApexOptions = {
    chart: {
      events: {
        xAxisLabelClick,
        mouseMove,
      },
      offsetX: chartOptions.offsetX,
      offsetY: chartOptions.offsetY,
      toolbar: {
        show: false,
        offsetX: 0,
        offsetY: 0,
      },
      redrawOnParentResize: true,
      parentHeightOffset: 0,
    },
    yaxis: {
      showAlways: false,
      show: false,
    },
    xaxis: {
      categories,
      labels: {
        style,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      theme,
    },
    plotOptions,
  };
  const series: ApexAxisChartSeries | ApexNonAxisChartSeries = [
    {
      data: data.map(({ value }) => value),
      name: chartOptions.seriesName,
      color: activeTheme.secondary,
    },
  ];

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="radar"
      height={chartOptions.height || 550}
      width={chartOptions.width}
    />
  );
}

export default RadarChart;
