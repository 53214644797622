import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Placement } from '@popperjs/core';
import { usePopper } from 'react-popper';
import { twMerge } from 'tailwind-merge';

import ConditionalRenderer from './ConditionalRenderer';
import { ClassNameComponent } from '@/utils/ClassNameComponent';
import Tag from './dataDisplay/Tag';
import Text from './dataDisplay/Text';

type RefElement = HTMLElement | null;

export type VacationNoticeTagProps = {
  className?: ClassNameComponent;
  placement?: Placement;
  offset?: [number, number];
  target?: RefElement;
  render?: boolean;
};

export default function VacationNoticeTag({
  className,
  placement,
  offset,
  target,
  render,
}: VacationNoticeTagProps) {
  const [t] = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const [popper, setPopper] = useState<RefElement>(null);

  const [visible, setVisible] = useState(false);

  const { styles, attributes } = usePopper(target, popper, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset,
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 20,
          mainAxis: false,
          altAxis: false,
        },
      },
      {
        name: 'flip',
        enabled: false,
      },
    ],
  });

  // TODO: remove the timeout. It is necessary because in the student report it flickers.
  useEffect(() => {
    const update = () => setVisible(true);

    const timeOut = setTimeout(update, 1000);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  return (
    <ConditionalRenderer condition={render}>
      <div
        ref={setPopper}
        style={{
          ...styles.popper,
          transform: styles.popper?.transform
            ? styles.popper.transform
            : 'none',
          willChange: 'transform',
          visibility: visible ? 'visible' : 'hidden',
        }}
        {...attributes.popper}
        className={className?.popup}
      >
        <Tag className={twMerge('bg-info', className?.base)} color="custom">
          <Text className="text-base-100" text={t('onVacation')} />
        </Tag>
      </div>
    </ConditionalRenderer>
  );
}
