import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { resourceQuerykeys } from '@/data/services/querykeys';
import Resource, {
  imageExtensions,
  ResourceFileExtension,
} from '@/models/Resource';
import { LoadingIcon } from '@/components/icons';
import Text from '@/components/common/dataDisplay/Text';
import { ROLES } from '@/utils/roles';

type FileRendererProps = {
  resource: Resource;
};

export default function FileRenderer(props: FileRendererProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const { resource } = props;

  const { data: file, isInitialLoading: loading } = useQuery({
    ...resourceQuerykeys.getFile(String(resource.resourceFile)),
    enabled: !!resource.resourceFile,
  });

  if (loading) {
    return <LoadingIcon className="w-10 h-10 text-primary/40" />;
  }

  if (!file) {
    return <Text text={t('notFoundFile')} />;
  }

  const fileExtension = ROLES.RESOURCE.FILE_EXTENSION(
    file.headers['content-type'],
  );

  if (imageExtensions.includes(fileExtension)) {
    return <ImageRenderer resource={resource} />;
  }

  if (fileExtension === ResourceFileExtension['application/pdf']) {
    return (
      <div className="flex w-full">
        <embed
          className="scrollbar-none"
          src={String(resource.resourceFile)}
          width="100%"
          height={600}
          type="application/pdf"
        />
      </div>
    );
  }

  return null;
}

function ImageRenderer(props: FileRendererProps) {
  const {
    resource: { title, resourceFile },
  } = props;

  return (
    <img
      className="w-full self-center flex"
      src={String(resourceFile)}
      alt={title}
    />
  );
}
