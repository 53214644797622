import { DEFAULT_EDITORJS_DATA } from '@/data/hook/editorjs/useEditorJS';
import { OutputData } from '@editorjs/editorjs';
import {
  AcademicCapIcon,
  KeyIcon,
  LightningBoltIcon,
  PlayIcon,
  QuestionMarkCircleIcon,
  TerminalIcon,
} from '@heroicons/react/solid';
import { t } from 'i18next';
import Chapter from './Chapter';
import Tool from './Tool';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';

export type bookType = 'STUDENT' | 'TEACHER';

export default interface Book {
  id: number;
  filename: string;
  uploadedAt: string;
  chapters: Chapter[];
  verion: number;
  status: VersioningStatusEnum;
  bookType: bookType;
  tools: Tool[];
}

export class Section {
  id: number = 0;
  chapterId: number = 0;
  title: string = t('section.title');
  content: OutputData = DEFAULT_EDITORJS_DATA;
  icon: SectionIconsType = 'AcademicCapIcon';
  order: number = 0;
  startsHidden: boolean = false;

  constructor(init?: Partial<Section>) {
    Object.assign(this, init);
  }
}

export const SectionIcons = {
  TerminalIcon,
  KeyIcon,
  AcademicCapIcon,
  LightningBoltIcon,
  QuestionMarkCircleIcon,
  PlayIcon,
};

export type SectionIconsType = keyof typeof SectionIcons;
