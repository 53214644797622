import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import TextWithCopy from '@/components/common/dataDisplay/TextWithCopy';
import ScheduledLesson from '@/models/ScheduledLesson';
import { NotificationActionsProps } from '../../card/NotificationTypes';
import NotificationActions from './NotificationActions';
import { useTranslation } from 'react-i18next';

type DelayNotificationActionProps = NotificationActionsProps & {
  scheduledLesson?: ScheduledLesson;
};

export const DelayNotificationAction = ({
  scheduledLesson,
  ...props
}: DelayNotificationActionProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'alerts.buttons',
  });
  return (
    <NotificationActions
      {...props}
      addicionalActions={
        <ConditionalRenderer condition={scheduledLesson?.url}>
          <TextWithCopy
            label={t('copyLink')}
            target={scheduledLesson?.url}
            clickText
            className={{
              base: 'rounded-full bg-primary-content border-[1px] border-primary px-2 py-1 gap-1.5 cursor-pointer flex-row-reverse',
              text: 'text-primary text-14',
            }}
          />
        </ConditionalRenderer>
      }
    />
  );
};
