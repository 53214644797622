import requester from '@/axios';
import { CodeEditorActivityProgress } from '@/models/ActivityProgress';

const getBaseUrl = (activityId: Number) =>
  `code-editor-activity/${activityId}/code-editor-activity-progress`;

export type CodeEditorProgressParam = {
  activityId: number;
  progressId: number;
};

export const getCodeEditorProgress = async ({
  activityId,
  progressId,
}: CodeEditorProgressParam) => {
  const { data: codeEditorProgress } =
    await requester().get<CodeEditorActivityProgress>(
      `${getBaseUrl(activityId)}/${progressId}/`,
    );
  return codeEditorProgress;
};

type Fields = Pick<CodeEditorActivityProgress, 'answer' | 'timer' | 'isDone'>;

type UpdateBody = Partial<Fields>;

export const updateCodeEditorProgress = async (
  { activityId, progressId }: CodeEditorProgressParam,
  body: UpdateBody,
) => {
  const { data: codeEditorProgress } =
    await requester().patch<CodeEditorActivityProgress>(
      `${getBaseUrl(activityId)}/${progressId}/`,
      body,
    );
  return codeEditorProgress;
};
