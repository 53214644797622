import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid';
import React, { Fragment } from 'react';
import { createSearchParams, Link } from 'react-router-dom';
import { Avatar } from '@/components/common/dataDisplay/Avatar';
import Text from '@/components/common/dataDisplay/Text';
import { useTranslation } from 'react-i18next';
import User, { UserTypeEnum } from '@/models/User';
import useAuth from '@/data/hook/useAuth';
import ScheduledLesson from '@/models/ScheduledLesson';
import {
  isLessonBeforeToday,
  isLessonDelayed,
} from '@/utils/ScheduledLessonDateValidator';
import { handleUserFullName } from '@/functions/handleUserFullName';
import Tag from '@/components/common/dataDisplay/Tag';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';

interface GivenContentProps {
  scheduledLesson: ScheduledLesson;
  teacher?: User;
  klassId: number;
}

export default function GivenContent(props: GivenContentProps) {
  const { teacher, scheduledLesson, klassId } = props;

  const { t } = useTranslation('translation', {
    keyPrefix: 'klassProgressPage',
  });
  const { t: tCommon } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const isBeforeToday = isLessonBeforeToday(scheduledLesson);
  const isDelayed = isLessonDelayed(scheduledLesson);

  const { user } = useAuth();
  const userRole = user ? [user.userType] : [UserTypeEnum.STUDENT];

  const reportUrl = `/class/${scheduledLesson.klass}/lesson-report/${scheduledLesson.id}`;

  const isUpcoming = !isBeforeToday && !scheduledLesson.hasDone;

  const teacherFullName = handleUserFullName(teacher);

  const RenderIcon = () => {
    if (isUpcoming) {
      return <Fragment />;
    } else if (isDelayed) {
      return <ExclamationCircleIcon className="h-4 w-4 text-error" />;
    }
    return <CheckCircleIcon className="h-4 w-4 text-success" />;
  };

  const RenderMainContent = () => {
    if (isUpcoming) {
      return (
        <Fragment>
          <Text text={t('lessonScheduled')} size="text-16" format="rubik-500" />
          <Text
            text={
              scheduledLesson.hasDone
                ? scheduledLesson.notes
                : t('lessonIsScheduled')
            }
          />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Text
            text={isDelayed ? t('reportNotFilled') : t('givenContent')}
            size="text-16"
            className={isDelayed ? 'text-error' : 'text-primary'}
            format="rubik-500"
          />
          <Text
            className="break-words"
            text={isDelayed ? t('pleaseFillReport') : scheduledLesson.notes}
          />
        </Fragment>
      );
    }
  };

  const RenderTeacher = () => {
    if (!teacher) {
      return <React.Fragment />;
    } else if (userRole[0] === UserTypeEnum.TEACHER) {
      return (
        <React.Fragment>
          <ConditionalRenderer condition={teacher}>
            <Avatar userId={teacher.id} />
          </ConditionalRenderer>
          <Text text={teacherFullName} className="text-primary" />
        </React.Fragment>
      );
    } else
      return (
        <React.Fragment>
          <ConditionalRenderer condition={teacher}>
            <Avatar userId={teacher.id} />
          </ConditionalRenderer>
          <Link
            to={
              `/admin/teachers/?${createSearchParams({
                search: handleUserFullName(teacher),
              })}` || ''
            }
          >
            <Text
              text={teacherFullName || ''}
              className="text-primary cursor-pointer underline"
            />
          </Link>
        </React.Fragment>
      );
  };

  const isPublished =
    scheduledLesson.lesson.status === VersioningStatusEnum.PUBLISHED;

  return (
    <div className="w-72 p-3.5 h-full flex">
      <div className="flex text-14 justify-between flex-col h-full w-full">
        <div className="flex gap-2.5 flex-col">
          <Link
            to={isPublished ? reportUrl : `/class/${klassId}/lessons`}
            className="flex items-center gap-2.5 self-end"
          >
            <RenderIcon />
            {isPublished ? (
              <Text
                text={t('seeReport')}
                className="text-primary underline flex"
                format="rubik-500"
              />
            ) : (
              <Tag
                color="warning"
                className="shrink-0 rounded-full underline px-3 py-1 font-500"
                text={tCommon('lessonUnpublished')}
              />
            )}
          </Link>

          <div
            data-testid={`mainContent${scheduledLesson.order}`}
            className={`gap-2.5 flex-col h-44 overflow-y-scroll break-words scrollbar-thin scrollbar-thumb-primary/40 scrollbar-track-primary-content scrollbar-thumb-rounded-full scrollbar-track-rounded-full flex ${
              isDelayed ? 'bg-error-content rounded-xl p-3 h-full' : ''
            }`}
          >
            <RenderMainContent />
          </div>
        </div>
        <div
          className={`${
            scheduledLesson.hasDone || isDelayed ? 'flex' : 'hidden'
          } self-end items-center mt-2.5 gap-2.5`}
        >
          <RenderTeacher />
        </div>
      </div>
    </div>
  );
}
