import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { StudentReportEditProps } from '../teacher/StudentReportEdit';
import ScheduledLessonReport, {
  GradeEnum,
} from '@/models/ScheduledLessonReport';
import { StudentRatingReport } from '../teacher/StudentRatingReport';
import { AccordionTextarea } from '@/components/common/dataInput/AccordionTextarea';
import {
  AssignmentType,
  HomeworkActivityProgress,
} from '@/models/HomeworkActivity';
import SaveCancelGroup from '@/components/common/buttons/SaveCancelGroup';
import useStudentReport from '@/data/hook/useStudentReport';
import { updateMgActivityProgress } from '@/data/services/mgActivityProgressServices';
import { Reward } from '@/models/Rewards';
import { buildChangedObject } from '@/utils/buildChangedObject';
import alert from '@/utils/UseAlert';
import { getErrorMessage } from '@/utils/getErrorMessage';

type PickProps = Pick<
  StudentReportEditProps,
  'enrollment' | 'canEditCash' | 'scheduledLesson'
>;

type StudentReportFormProps = Required<PickProps> & {
  homeworkProgress?: HomeworkActivityProgress;
  report?: ScheduledLessonReport;
  invalidateReports?: () => Promise<void>;
  invalidateHomeworks?: () => Promise<void>;
  reward: Reward;
};

export type ReportFields = Partial<ScheduledLessonReport> & {
  notDone: boolean;
  homeworkGrade: GradeEnum | null;
  allValues?: GradeEnum | null;
  presenceReward: number;
};

export function StudentReportForm({
  scheduledLesson,
  reward,
  enrollment,
  canEditCash,
  homeworkProgress,
  report,
  invalidateReports,
  invalidateHomeworks,
}: StudentReportFormProps) {
  const [changed, setChanged] = useState(false);

  const { update, create } = useStudentReport();

  const checkHomework = () => {
    const hasAssignment =
      homeworkProgress?.homework.assignmentType === AssignmentType.TEXT;

    if (hasAssignment)
      return (
        !!homeworkProgress.answer || !!homeworkProgress.activityProgress.grade
      );

    return homeworkProgress?.activityProgress.hasDone ?? false;
  };

  const isHomeworkDone = checkHomework();

  const defaultValues = useMemo<ReportFields>(() => {
    if (report)
      return {
        ...report,
        presenceReward: report.presenceReward ?? reward.amount,
        homeworkGrade: homeworkProgress?.activityProgress.grade || null,
        notDone: !isHomeworkDone,
        notes: report.notes,
        allValues: null,
      };

    return {
      ...new ScheduledLessonReport(),
      notDone: !isHomeworkDone,
      homeworkGrade: homeworkProgress?.activityProgress.grade || null,
      presenceReward: reward.amount,
    };
  }, [
    homeworkProgress?.activityProgress.grade,
    isHomeworkDone,
    report,
    reward.amount,
  ]);

  const { control, handleSubmit, register, reset, watch, setValue } =
    useForm<ReportFields>({
      defaultValues,
    });

  useEffect(() => {
    reset(defaultValues);
    setChanged(false);
  }, [defaultValues, reset]);

  useEffect(() => {
    const subscription = watch(() => setChanged(true));
    return () => subscription.unsubscribe();
  }, [watch, setChanged]);

  const resetFields = () => {
    reset(defaultValues);
    setChanged(false);
  };

  const onSubmitHomework = async (
    changes: Partial<Pick<ReportFields, 'homeworkGrade' | 'notDone'>>,
  ) => {
    if (homeworkProgress) {
      const { homeworkGrade, notDone } = changes;

      const hasDone = notDone || homeworkGrade ? !notDone : undefined;

      const mgActivityProgressId = homeworkProgress.activityProgress.id;

      const updated = await updateMgActivityProgress(mgActivityProgressId, {
        grade: notDone ? 0 : homeworkGrade,
        hasDone,
      });

      if (updated.id) await invalidateHomeworks?.();
    }
  };

  const homeworkChanged = (changes: Partial<ReportFields>) =>
    'homeworkGrade' in changes || 'notDone' in changes;

  const onUpdate = async (id: number, changes: Partial<ReportFields>) => {
    if (!defaultValues) return;

    if (Object.keys(changes).length) {
      const updated = await update({
        id,
        changes,
      });

      if (updated.id) await invalidateReports?.();
    }
  };

  const onCreate = async (
    changes: Partial<ReportFields> & {
      scheduledLesson: number;
      student: number;
    },
  ) => {
    const { homeworkGrade, notDone, ...rest } = changes;

    const created = await create(rest);

    if (created.id) await invalidateReports?.();
  };

  const [loading, setLoading] = useState(false);

  const setReaward = (changes: Partial<ReportFields>) => {
    if (changes.presenceReward) return changes.presenceReward;

    return !report?.presenceReward ? reward.amount : undefined;
  };

  const onSubmit = async (changes: ReportFields) => {
    try {
      setLoading(true);

      const changedFields = buildChangedObject<ReportFields>(
        defaultValues,
        changes,
      );

      const { homeworkGrade, notDone, ...rest } = changedFields;

      const { allValues, ...allValuesRemoved } = rest;

      if (homeworkChanged(changedFields))
        await onSubmitHomework({ homeworkGrade, notDone });

      if (Object.keys(allValuesRemoved).length) {
        if (defaultValues.id)
          await onUpdate(defaultValues.id, {
            ...rest,
            presenceReward: setReaward(rest),
          });
        else {
          await onCreate({
            ...rest,
            scheduledLesson: scheduledLesson.id,
            student: enrollment.student.id,
            presenceReward: rest.presenceReward ?? reward.amount,
          });
        }
      }
    } catch (error) {
      alert.error(getErrorMessage(error));
    } finally {
      setChanged(false);
      setLoading(false);
    }
  };

  return (
    <form
      data-testid="studentReportEdit"
      className="flex flex-col gap-y-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <StudentRatingReport
        control={control}
        homeworkProgress={homeworkProgress}
        rewardAmount={reward.amount}
        locked={!canEditCash}
        setValue={setValue}
      />

      <AccordionTextarea
        register={register('notes')}
        testId="accordionTextarea"
        studentName={enrollment.student.firstName}
      />

      <SaveCancelGroup
        loading={loading}
        cancel={{
          onClick: resetFields,
          testId: 'cancelButton',
        }}
        save={{
          disabled: !changed,
          testId: 'saveButton',
        }}
      />
    </form>
  );
}
