import { twMerge } from 'tailwind-merge';

import { useEnrollmentStatusTranslation } from '@/data/hook/useEnrollmentStatusTranslation';
import { EnrollmentStatusEnum } from '@/models/Enrollment';
import ConditionalRenderer from '../common/ConditionalRenderer';
import Tag from '../common/dataDisplay/Tag';

type EnrollmentTagProps = {
  status: EnrollmentStatusEnum;
  className?: string;
};

export default function EnrollmentTag({
  status,
  className,
}: EnrollmentTagProps) {
  const translatedStatus = useEnrollmentStatusTranslation(status);

  const enrollmentStatus = [
    EnrollmentStatusEnum.AWAITING_PAYMENT,
    EnrollmentStatusEnum.CONCLUDED,
  ];

  const renderEnrollmentTag = enrollmentStatus.includes(status);

  return (
    <ConditionalRenderer condition={renderEnrollmentTag}>
      <Tag
        color="warning"
        className={twMerge(
          'py-1 px-2 rounded-full text-12 flex text-center',
          className,
        )}
        text={translatedStatus}
      />
    </ConditionalRenderer>
  );
}
