import AreaLineCard from './AreaLineCard';
import { useTranslation } from 'react-i18next';
import { AreaScore } from '@/pages/skill/SkillPage.hooks';
import { scrollBarClassName } from '@/utils/scrollBarClassName';

type TagScoreRankProps = {
  areaTagScores: AreaScore[];
  selectedAreaScore?: AreaScore;
  selectArea(area: AreaScore): void;
};

export default function TagScoreRank({
  areaTagScores,
  selectedAreaScore,
  selectArea,
}: TagScoreRankProps) {
  const [t] = useTranslation('translation', {
    keyPrefix: 'skillPage',
  });
  function sumScores(previousValue: number, { tagScore }: AreaScore) {
    const score = tagScore?.score;
    return score ? previousValue + score : previousValue;
  }

  const total = areaTagScores.reduce(sumScores, 0);

  return (
    <ul
      className={`flex flex-col gap-2 max-h-[270px] overflow-auto pr-2 ${scrollBarClassName}`}
    >
      <li>
        <AreaLineCard
          color="custom"
          className={{ base: 'text-accent bg-transparent shadow-none' }}
          title={t('total')}
          score={total}
        />
      </li>
      {areaTagScores.map(({ area, tagScore }) => {
        const isSelected = area?.id === selectedAreaScore?.area?.id;
        return (
          <li key={tagScore.id}>
            <AreaLineCard
              title={area.name}
              color={isSelected ? 'accent' : 'accent-content'}
              score={tagScore?.score}
              onClick={() => selectArea({ area, tagScore })}
              difficultyStage={tagScore?.difficultyStage}
              className={{
                tag: `px-2 ${isSelected ? '' : 'border border-accent/50'}`,
              }}
            />
          </li>
        );
      })}
    </ul>
  );
}
