import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { MaterialMode as Mode } from '@/data/context/CourseEditingContext';
import { createSection } from '@/data/services/sectionServices';
import { PencilIcon, UploadIcon } from '@heroicons/react/outline';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useCourseEditing from '@/data/hook/useCourseEditing';
import { createBook, GetBookParams } from '@/data/services/bookServices';
import { createChapter } from '@/data/services/chapterServices';
import { bookType } from '@/models/Book';
import CoursePath from '@/models/Course';
import { ApiError } from '@/models/Errors';
import { Lesson } from '@/models/Lesson';
import LoadingView from '@/pages/courses/LoadingView';
import { bookEndpoints } from '@/utils/setBookType';
import alert from '@/utils/UseAlert';
import MainButton from '@/components/common/buttons/MainButton';
import TooltipHandler from '@/components/common/TooltipHandler';
import AddMaterial from './AddMaterial';

interface MaterialModeProps {
  course: CoursePath;
  lesson: Lesson;
  updateLessons?: () => Promise<void>;
  bookType: bookType;
  isLoadingContainer: boolean;
}

export default function MaterialMode({
  course,
  lesson,
  updateLessons,
  bookType,
  isLoadingContainer,
}: MaterialModeProps) {
  const { materialMode, setMaterialMode, allowBigChanges } = useCourseEditing();
  const { t } = useTranslation('translation', {
    keyPrefix: 'editMaterialPage',
  });
  const { t: editRulesT } = useTranslation('translation', {
    keyPrefix: 'adminPage.editCourseRules',
  });
  const navigate = useNavigate();

  const endpoint = bookEndpoints[bookType];

  const editBookRoute = `/admin/courses/${course.slug}/lessons/${lesson.id}/${endpoint}`;

  const { mutate: sectionCreate, isLoading: isLoadingSection } = useMutation(
    createSection,
    {
      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
      onSuccess: updateLessons,
    },
  );

  const handleCreateChapter = (params: GetBookParams) => {
    if (materialMode === 'slides')
      return createChapter(params, { content: '' });
    else return createChapter(params);
  };

  const { mutate: chapterCreate, isLoading: isLoadingChapter } = useMutation(
    handleCreateChapter,
    {
      onSuccess(chapter) {
        if (materialMode === 'default')
          navigate(`${editBookRoute}/${chapter.book}`);
        else
          sectionCreate({
            bookId: chapter.book,
            chapterId: chapter.id,
            lessonId: lesson.id,
          });
      },
      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const { mutate: bookCreate, isLoading: isLoadingBook } = useMutation(
    createBook,
    {
      onSuccess(data) {
        chapterCreate({
          lessonId: lesson.id,
          bookType: data.bookType,
          bookId: data.id,
        });
      },
      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );
  const onClickCreate = (mode: Exclude<Mode, 'upload'>) => {
    setMaterialMode(mode);
    bookCreate({
      bookType,
      lessonId: lesson.id,
    });
  };
  const isLoadingCreate = isLoadingBook || isLoadingChapter || isLoadingSection;

  if (isLoadingContainer) {
    return <LoadingView />;
  }

  switch (materialMode) {
    case 'upload':
      return (
        <AddMaterial
          course={course}
          lesson={lesson}
          updateLessons={updateLessons}
          bookType={bookType}
          setMaterialMode={setMaterialMode}
        />
      );
    default:
      return (
        <div className="flex gap-4">
          <TooltipHandler
            tooltipMessage={editRulesT('cantModify')}
            renderTooltip={!allowBigChanges}
          >
            <MainButton
              text={t('uploadMaterial')}
              icon={<UploadIcon />}
              disabled={!allowBigChanges}
              onClick={() => setMaterialMode('upload')}
            />
          </TooltipHandler>
          <TooltipHandler
            tooltipMessage={editRulesT('cantModify')}
            renderTooltip={!allowBigChanges}
          >
            <MainButton
              text={t('createMaterial')}
              icon={<PencilIcon />}
              loading={isLoadingCreate && materialMode === 'default'}
              disabled={!allowBigChanges}
              onClick={() => onClickCreate('default')}
            />
          </TooltipHandler>
          <ConditionalRenderer condition={bookType === 'STUDENT'}>
            <TooltipHandler
              tooltipMessage={editRulesT('cantModify')}
              renderTooltip={!allowBigChanges}
            >
              <MainButton
                text={t('createSlidesMaterial')}
                icon={<PencilIcon />}
                disabled={!allowBigChanges}
                loading={isLoadingCreate && materialMode === 'slides'}
                onClick={() => onClickCreate('slides')}
              />
            </TooltipHandler>
          </ConditionalRenderer>
        </div>
      );
  }
}
