const theme = {
  light: {
    primary: '#2492F5',
    'primary-content': '#E9F4FE',
    secondary: '#A680FF',
    'secondary-content': '#F0EAFF',
    accent: '#738CFF',
    'accent-content': '#E6EAFF',
    neutral: '#334155',
    'neutral-content': '#e8e9ee',
    'neutral-focus': '#A7B2C0',
    'base-100': '#ffffff',
    'base-200': '#fdfdfd',
    'base-300': '#64748b',
    'base-content': '#334155',
    info: '#db2777',
    'info-content': '#fbcfe8',
    success: '#10b98f',
    'success-content': '#d1faec',
    warning: '#f59e0b',
    'warning-content': '#fef3c7',
    error: '#f87171',
    'error-content': '#fee2e2',
  },
  dark: {
    primary: '#2492F5',
    'primary-content': '#172554',
    secondary: '#8b5cf6',
    'secondary-content': '#2e1065',
    accent: '#738CFF',
    'accent-content': '#1e1b4b',
    neutral: '#9ba3ae',
    'neutral-content': '#192a58',
    'neutral-focus': '#A7B2C0',
    'base-100': '#020617',
    'base-200': '#0f172a',
    'base-300': '#cbd5e1',
    'base-content': '#f1f5f9',
    info: '#f472b6',
    'info-content': '#831843',
    success: '#10b981',
    'success-content': '#022c22',
    warning: '#f59e0b',
    'warning-content': '#451a03',
    error: '#f87171',
    'error-content': '#450a0a',
  },
};

export default theme;
