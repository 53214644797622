import useListService from '@/data/hook/useListService';
import { areasQueryKeys } from '@/data/services/querykeys';
import Area from '@/models/Area';
import { extractParents } from '@/utils/area';
import { useTranslation } from 'react-i18next';
import Text from '../common/dataDisplay/Text';
import { Fragment } from 'react/jsx-runtime';

type BreadcrumbsProps = {
  areaId: number | undefined;
};

export default function Breadcrumbs({ areaId }: BreadcrumbsProps) {
  const [t] = useTranslation('translation', {
    keyPrefix: 'skillPage',
  });
  const { queryFn, queryKey } = areasQueryKeys.listParents(areaId ?? 0);
  const { results: areas } = useListService({
    queryFn,
    queryKey,
    enabled: !!areaId,
    staleTime: Infinity,
  });

  const allAreas = areas.reduce<Area[]>(
    (acc, area) => acc.concat(extractParents(area)),
    [],
  );

  return (
    <div className="flex gap-1 relative z-50">
      <Text
        text={t('general')}
        size="text-20"
        format="rubik-500"
        className={areaId ? 'underline' : 'text-secondary'}
        href={areaId ? '/skills' : undefined}
      />
      {allAreas.map(({ id, name }, i, arr) => (
        <Fragment key={id}>
          <Text
            text={'/'}
            size="text-20"
            format="rubik-500"
            className={i === arr.length - 1 ? 'text-secondary' : ''}
          />
          <Text
            text={name}
            size="text-20"
            format="rubik-500"
            className={i === arr.length - 1 ? 'text-secondary' : 'underline'}
            href={`/skills/${id}`}
          />
        </Fragment>
      ))}
    </div>
  );
}
