import { MouseEvent } from 'react';
import OutlineButton from '../buttons/OutlineButton';
import { Button } from '../buttons/SaveCancelGroup';
import { twMerge } from 'tailwind-merge';

export type ToggleButton<T> = Button & { value: T };

type ToggleButtonSize = 'small' | 'medium' | 'large';

type ToggleButtonGroupCommom<T> = {
  buttons: ToggleButton<T>[];
  orientation?: 'horizontal' | 'vertical';
  size?: ToggleButtonSize;
  className?: string;
  testId?: string;
};

export type onChangeToggle<T> = (
  value: T,
  e?: MouseEvent<HTMLButtonElement>,
) => void;

type ToggleButtonSingleProps<T> = {
  exclusive: true;
  value?: T;
  onChange?: onChangeToggle<T>;
} & ToggleButtonGroupCommom<T>;

type ToggleButtonMultiProps<T> = {
  exclusive?: false;
  value?: T[];
  onChange?: onChangeToggle<T[]>;
} & ToggleButtonGroupCommom<T>;

type ToggleButtonProps<T> =
  | ToggleButtonSingleProps<T>
  | ToggleButtonMultiProps<T>;

export default function ToggleButtonGroup<T>(props: ToggleButtonProps<T>) {
  const stylesBySize: {
    [key in ToggleButtonSize]: string;
  } = {
    small: 'px-2.5 py-0.5 text-14',
    medium: 'px-3 py-1.5',
    large: 'h-10',
  };

  if (props && props.exclusive)
    return (
      <ToogleButtonGroupSingle
        {...props}
        className={twMerge(
          props.className,
          stylesBySize[props?.size || 'medium'],
        )}
      />
    );

  return (
    <ToogleButtonGroupMulti
      {...props}
      className={twMerge(
        props.className,
        stylesBySize[props?.size || 'medium'],
      )}
    />
  );
}

function ToogleButtonGroupSingle<T>({
  buttons,
  value,
  onChange,
  orientation = 'horizontal',
  className,
}: ToggleButtonSingleProps<T>) {
  return (
    <div
      className={`flex gap-1.5 flex-wrap ${
        orientation === 'vertical' && 'flex-col'
      }`}
    >
      {buttons.map(button => (
        <OutlineButton
          icon={button.icon}
          disabled={button.disabled}
          type="button"
          testId={button.testId}
          key={button.text}
          className={className}
          text={button.text}
          onClick={e => {
            onChange?.(button.value, e);
          }}
          active={value === button.value}
        />
      ))}
    </div>
  );
}

function ToogleButtonGroupMulti<T>({
  buttons,
  value = [],
  onChange,
  className,
  orientation = 'horizontal',
}: ToggleButtonMultiProps<T>) {
  return (
    <div
      className={`flex gap-1.5 flex-wrap ${
        orientation === 'vertical' && 'flex-col'
      }`}
    >
      {buttons.map(button => (
        <OutlineButton
          icon={button.icon}
          disabled={button.disabled}
          testId={button.testId}
          key={button.text}
          type="button"
          className={className}
          text={button.text}
          onClick={e => {
            onChange?.(
              value.includes(button.value)
                ? value.filter(v => v !== button.value)
                : [...value, button.value],
              e,
            );
          }}
          active={value.includes(button.value)}
        />
      ))}
    </div>
  );
}
