import IconButton from '@/components/common/buttons/IconButton';
import MainButton from '@/components/common/buttons/MainButton';
import Text from '@/components/common/dataDisplay/Text';
import useLessonContext from '@/data/hook/lesson';
import useStudentContext from '@/data/hook/student';
import useAuth from '@/data/hook/useAuth';
import {
  booksQueryKeys,
  coursePathsQueryKeys,
} from '@/data/services/querykeys';
import { isStudent } from '@/functions/auth';
import { formatLessonName } from '@/functions/lessonsName';
import Chapter from '@/models/Chapter';
import { ApiError } from '@/models/Errors';
import { Lesson } from '@/models/Lesson';
import { fadeIn } from '@/utils/animations/commom';
import getKlassViewBaseUrl from '@/utils/getKlassViewBaseUrl';
import { makeLessonHeadTitle } from '@/utils/lessonHeadTitle';
import { scrollBarClassName } from '@/utils/scrollBarClassName';
import { ArrowsExpandIcon, XIcon } from '@heroicons/react/outline';
import { useQuery } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import ChapterRender from '../../common/ChapterRender';
import ErrorComponent from '../../common/ErrorComponent';
import HeadTitle from '../../common/HeadTitle';
import AdminMaterialControl from './AdminMaterialControl';
import ChapterContainer from './ChapterContainer';
import StudentMaterialControl from './StudentMaterialControl';

export default function StudentMaterialView() {
  const { t: tError } = useTranslation('translation', {
    keyPrefix: 'errors.errorPage',
  });
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassView',
  });

  const { lesson, scheduledLesson, fullScreen, setFullscreen } =
    useLessonContext();
  const { book: bookId, order } = lesson || {};
  const { currentProgress } = useStudentContext();
  const { chapterOrder, slugCourseName = '', lessonId, klassId } = useParams();

  const {
    data: book,
    error,
    isInitialLoading: isLoading,
  } = useQuery({ ...booksQueryKeys.get(Number(bookId)), enabled: !!bookId });

  const chapters = book?.chapters ?? [];
  const chapter = chapters?.at(Number(chapterOrder) - 1);
  const { data: course } = useQuery({
    enabled: !!slugCourseName && !currentProgress,
    ...coursePathsQueryKeys.get(slugCourseName ?? ''),
  });
  const coursePath = course ?? currentProgress?.coursePath;
  const courseAndLessonTitle = makeLessonHeadTitle({
    lessonOrder: order || 0,
    coursePath,
    lessonType: scheduledLesson?.type,
  });
  const titleWithoutChapter = `${t(
    'studentMaterial',
  )} - ${courseAndLessonTitle}`;

  const title = chapter
    ? `${chapter.title} - ${t('studentMaterial')} - ${courseAndLessonTitle}`
    : titleWithoutChapter;

  const apiError = error ? new ApiError(error as any) : undefined;

  if (apiError?.statusCode) {
    return (
      <React.Fragment>
        <HeadTitle routeInfo={title} />
        <ErrorComponent
          statusCode={apiError.statusCode}
          errorTextTitle={
            apiError.statusCode === 500
              ? tError('internError.title')
              : tError('materialNotFound.title')
          }
          errorTextSubTitle={
            apiError.statusCode === 500
              ? tError('internError.subtitle')
              : tError('materialNotFound.subtitle')
          }
        />
      </React.Fragment>
    );
  }

  return (
    <ChapterContainer isLoading={isLoading}>
      <HeadTitle routeInfo={title} />
      {!fullScreen && (
        <ChapterWithoutFullscreen
          setFullscreen={setFullscreen}
          chapter={chapter}
          chapters={chapters}
          slugCourseName={slugCourseName}
          lessonId={Number(lessonId)}
          klassId={Number(klassId)}
        />
      )}
      <AnimatePresence>
        {fullScreen && (
          <ChapterFullscreen
            chapter={chapter}
            chapters={chapters}
            lesson={lesson}
            setFullscreen={setFullscreen}
            slugCourseName={slugCourseName}
            lessonId={Number(lessonId)}
            klassId={Number(klassId)}
          />
        )}
      </AnimatePresence>
    </ChapterContainer>
  );
}

type ChapterFullscreenProps = {
  chapter?: Chapter;
  chapters: Chapter[];
  lesson?: Lesson;
  slugCourseName: string;
  setFullscreen: (full: boolean) => void;
  lessonId: number;
  klassId: number;
};

function ChapterWithoutFullscreen({
  setFullscreen,
  chapter,
  chapters,
  slugCourseName,
  lessonId,
  klassId,
}: ChapterFullscreenProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassView.fullScreen',
  });
  return (
    <motion.div className="flex flex-col gap-4">
      <div className="absolute top-8 right-8">
        <IconButton
          className="w-5"
          icon={<ArrowsExpandIcon />}
          testId="fullscreen"
          tooltip={t('enter')}
          onClick={() => setFullscreen(true)}
        />
      </div>
      <ChapterRender chapter={chapter} />
      <MaterialControl
        hasChapter={!!chapter}
        chapters={chapters}
        baseUrl={getKlassViewBaseUrl({
          slugCourseName,
          lessonId: Number(lessonId),
          klassId: Number(klassId),
        })}
      />
    </motion.div>
  );
}

function ChapterFullscreen({
  chapter,
  chapters,
  lesson,
  setFullscreen,
  slugCourseName,
  lessonId,
  klassId,
}: ChapterFullscreenProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassView.fullScreen',
  });
  const lessonName = formatLessonName(lesson as Lesson);
  return (
    <motion.div
      className="fixed inset-0 z-10 bg-base-100 p-5 max-h-screen h-full flex flex-col gap-5 "
      data-testid="fullscreen-container"
      {...fadeIn}
    >
      <div className="flex w-full justify-between items-center flex-wrap gap-2">
        <motion.div layoutId={lessonName}>
          <Text
            format="rubik-500"
            size="text-24"
            text={lessonName}
            className="text-primary text-left flex-shrink-0"
          />
        </motion.div>

        <MainButton
          className="text-error gap-1"
          icon={<XIcon />}
          color="custom"
          text={t('exit')}
          onClick={() => setFullscreen(false)}
        />
      </div>
      <div
        className={twMerge(
          'relative w-full h-full mb-10 pr-2.5',
          scrollBarClassName,
        )}
      >
        <ChapterRender chapter={chapter} />
      </div>
      <div className="fixed w-full bottom-0 p-6 pt-2.5 left-0 bg-base-100 z-10">
        <MaterialControl
          hasChapter={!!chapter}
          chapters={chapters}
          baseUrl={getKlassViewBaseUrl({
            slugCourseName,
            lessonId: Number(lessonId),
            klassId: Number(klassId),
          })}
        />
      </div>
    </motion.div>
  );
}

type MaterialControlProps = {
  hasChapter: boolean;
  chapters: Chapter[];
  baseUrl: string;
};

function MaterialControl({
  hasChapter,
  chapters,
  baseUrl,
}: MaterialControlProps) {
  const { user } = useAuth();
  if (!hasChapter) {
    return null;
  }
  if (isStudent(user?.userType)) {
    return <StudentMaterialControl baseUrl={baseUrl} />;
  }
  return <AdminMaterialControl baseUrl={baseUrl} chapters={chapters} />;
}
