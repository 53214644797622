import {
  ArrowRightIcon,
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/outline';
import { format, subDays } from 'date-fns';
import { enUS, ptBR } from 'date-fns/locale';
import React, { useState } from 'react';
import { CaptionProps, DateRange, useNavigation } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import ConditionalRenderer from '../ConditionalRenderer';
import { LocaleDayPicker } from './LocaleDayPicker';

interface DateRangePickerProps {
  onSelect?: () => void;
  range: DateRange | undefined;
  className?: string;
  setRange: React.Dispatch<React.SetStateAction<DateRange>>;
}

export default function DateRangePicker({
  range,
  setRange,
  onSelect,
  className,
}: DateRangePickerProps) {
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const nowDate = new Date(Date.now());

  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'calendar.admin',
  });

  const localeLanguage = {
    locale: language === 'pt_BR' ? ptBR : enUS,
  };

  const defaultSelected: DateRange = {
    from: subDays(nowDate, 29),
    to: nowDate,
  };

  const HeaderDates = () => (
    <div className={`flex gap-2.5 justify-start items-center ${className}`}>
      <div className="gap-2.5 flex font-text items-center font-links text-subheading_2 leading-tight text-18 font-500">
        {range?.from && range.to ? (
          <React.Fragment>
            <p className="align-middle">{format(range.from, 'dd/MM/yyyy')}</p>
            <ArrowRightIcon className="w-6 h-6" />
            <p className="align-middle">{format(range.to, 'dd/MM/yyyy')}</p>
          </React.Fragment>
        ) : (
          <p>{t('selectDates')}</p>
        )}
      </div>
      <div
        className="relative cursor-pointer flex overflow-hidden items-center hover:opacity-75 transition-all duration-100 ease-in-out"
        onClick={() => setIsPopperOpen(!isPopperOpen)}
      >
        <CalendarIcon className="w-6 h-6" />
      </div>
    </div>
  );

  function CustomCaption({ displayMonth }: CaptionProps) {
    const { goToMonth, nextMonth, previousMonth } = useNavigation();
    return (
      <div className="flex flex-row justify-between">
        <button
          disabled={!previousMonth}
          onClick={() => previousMonth && goToMonth(previousMonth)}
        >
          <ChevronLeftIcon className="w-6 h-6 rounded-[50%] p-1 text-primary hover:bg-primary hover:text-white" />
        </button>

        <h3 className="capitalize text-primary">
          {format(displayMonth, 'LLLL yyy', localeLanguage)}
        </h3>

        <button
          disabled={!nextMonth}
          onClick={() => nextMonth && goToMonth(nextMonth)}
        >
          <ChevronRightIcon className="w-6 h-6 rounded-[50%] p-1 text-primary hover:bg-primary hover:text-white" />
        </button>
      </div>
    );
  }

  return (
    <React.Fragment>
      <HeaderDates />
      <ConditionalRenderer condition={isPopperOpen}>
        <LocaleDayPicker
          className="absolute left-44 m-0 z-20 bg-white drop-shadow-lg p-4 rounded-md"
          mode="range"
          defaultMonth={nowDate}
          selected={range}
          onSelect={newValue => {
            setRange(newValue ?? defaultSelected);
            onSelect?.();
          }}
          modifiersClassNames={{
            selected: 'bg-purple-400 text-white',
            today: 'bg-primary text-white',
          }}
          classNames={{
            head: 'text-primary',
          }}
          components={{
            Caption: CustomCaption,
          }}
        />
        <div
          onClick={() => setIsPopperOpen(!isPopperOpen)}
          className="fixed w-screen top-0 left-0 h-screen z-10"
        />
      </ConditionalRenderer>
    </React.Fragment>
  );
}
