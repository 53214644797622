import { CheckCircleIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import MainButton from '@/components/common/buttons/MainButton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import ConditionalWrapper from '@/components/common/ConditionalWrapper';
import { Avatar } from '@/components/common/dataDisplay/Avatar';
import PresenceCheckbox from '@/components/common/dataDisplay/PresenceCheckbox';
import Text from '@/components/common/dataDisplay/Text';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import ModalDisable from '@/components/common/modals/ModalDisable';
import Skeleton from '@/components/common/Skeleton';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import useListService from '@/data/hook/useListService';
import useStudentReport from '@/data/hook/useStudentReport';
import { homeworkProgressQueryKeys } from '@/data/services/querykeys';
import { handleUserFullName } from '@/functions/handleUserFullName';
import Enrollment, { EnrollmentStatusEnum } from '@/models/Enrollment';
import ScheduledLesson from '@/models/ScheduledLesson';
import {
  ActiveEnum,
  EnrollmentAndReportStatus,
  PresenceEnum,
  ScheduledLessonReportEnum,
  TranferredEnum,
} from '@/models/ScheduledLessonReport';
import { studentReportStatus } from '@/utils/studentReportStatus';
import { StudentReportAssessment as ReportAssessment } from '../teacher/StudentReportAssessment';
import NotifyLatelessButton from './NotifyLatelessButton';
import { StudentReportPermissions } from './StudentReports';
import VacationNoticeTag from '../common/VacationNoticeTag';
import Klass from '@/models/Klass';
import moment from 'moment';
import useAuth from '@/data/hook/useAuth';
import { isSuperAdmin } from '@/functions/auth';
import { twMerge } from 'tailwind-merge';
import { useEnrollmentStatusTranslation } from '@/data/hook/useEnrollmentStatusTranslation';
import { VacationNotice } from '@/models/VacationNotice';
import { useConecta } from '@/data/hook/useConecta';
import ConectaTag from '../common/ConectaTag';

type onConfirm = {
  presence: PresenceEnum;
  reportId: number;
};

type StudentReportProps = {
  enrollment: Enrollment;
  handleEnrollment: (enrollment: Enrollment) => void;
  permissions: StudentReportPermissions;
  scheduledLesson: ScheduledLesson;
  homeworkId?: number;
  setButtonRef?: (e: HTMLElement) => void;
  klass: Klass;
  vacationNotice?: VacationNotice;
};

export function StudentReport({
  enrollment,
  handleEnrollment,
  permissions,
  scheduledLesson,
  homeworkId,
  setButtonRef,
  klass,
  vacationNotice,
}: StudentReportProps) {
  const studentId = enrollment.student.id;
  const { user } = useAuth();

  const scheduledLessonId = scheduledLesson.id;

  const wasUpdatedAfterLesson = moment(scheduledLesson.datetime).isBefore(
    enrollment.updatedAt,
  );

  const {
    report,
    loadingReport,
    create,
    update,
    loadingCreate,
    loadingUpdate,
  } = useStudentReport({
    studentId,
    scheduledLessonId,
  });

  const { results: homeworkProgresses } = useListService({
    ...homeworkProgressQueryKeys.list(homeworkId ?? 0, {
      studentId: enrollment.student.id,
    }),
    enabled: !!homeworkId && !!enrollment.student.id,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const homeworkProgress = homeworkProgresses.at(0);

  const [visible, setVisible] = useState(false);

  const name = handleUserFullName(enrollment.student);

  async function onConfirm({ presence, reportId: id }: onConfirm) {
    await update({ id, changes: { presence, presenceReward: null } });

    setVisible(false);
  }

  function onCancel() {
    setVisible(false);
  }

  async function handlePresence(presence: PresenceEnum) {
    if (report) {
      const id = report.id;
      if (presence === PresenceEnum.MISSED) return setVisible(true);
      else {
        const setedPresence = presence === PresenceEnum.NONE ? null : presence;
        await update({
          id,
          changes: { presence: setedPresence },
        });
      }
    } else {
      await create({
        student: studentId,
        scheduledLesson: scheduledLessonId,
        presence,
      });
    }
    handleEnrollment(enrollment);
  }

  async function handleActivation() {
    if (!report) return;

    const id = report.id;
    await update({
      id,
      changes: { status: ScheduledLessonReportEnum.ACTIVE },
    });
  }

  const status = studentReportStatus({
    enrollmentStatus: enrollment.status,
    reportStatus: report?.status,
    wasUpdatedAfterLesson,
  });

  const activeStatus: EnrollmentAndReportStatus[] = [
    EnrollmentStatusEnum.ACTIVE,
    ActiveEnum.CANCELED_IN_FUTURE,
    ActiveEnum.TRANSFERED_IN_FUTURE,
  ];

  function getReportPermission() {
    if (enrollment.status === EnrollmentStatusEnum.AWAITING_PAYMENT) {
      return false;
    }
    return activeStatus.includes(status) || isSuperAdmin(user?.userType);
  }

  const isReportEditAllowed = getReportPermission();

  const disabledClassname = isReportEditAllowed
    ? ''
    : 'disabled grayscale-[80] cursor';

  const loading = loadingReport || loadingCreate || loadingUpdate;

  const [vacationRef, setVacationRef] = useState<HTMLElement | null>(null);

  const { isConectaStudentSeat } = useConecta({
    studentUnitIds: enrollment.student.unitsIds,
    unitKlassId: klass.unitId,
  });

  return (
    <li
      data-testid="studentReport"
      className={`flex flex-col xl:flex-row flex-wrap gap-2 justify-start items-start ${
        loadingCreate ? 'disabled' : ''
      }`}
    >
      <RemoveAttendanceModal
        onCancel={onCancel}
        onConfirm={onConfirm}
        visible={visible}
        name={name}
        reportId={report?.id}
      />

      <div
        className={`flex items-center gap-2 w-full flex-1 ${disabledClassname}`}
      >
        <PresenceCheckbox
          key={`presenceCheckbox${report?.id}`}
          presence={report?.presence}
          onClick={handlePresence}
          loading={loading}
          disabled={!permissions.hasPresencePermission}
        />

        <Avatar
          testId="studentAvatar"
          size="6"
          userId={enrollment.student.id}
        />

        <div className="flex w-full justify-between items-center relative">
          <ConditionalRenderer
            fallback={
              <Skeleton className="flex h-8 bg-primary-content rounded-md" />
            }
            condition={!loadingReport}
          >
            <span className="flex gap-2.5">
              <MainButton
                text={name}
                disabled={
                  !permissions.hasReportsPermission || !isReportEditAllowed
                }
                onClick={() => handleEnrollment(enrollment)}
                color="custom"
                className="flex text-primary hover:text-primary/40 text-ellipsis overflow-hidden font-rubik font-500"
              />
              <ConectaTag render={isConectaStudentSeat} />
            </span>
            <StudentReportStatus status={status} />
          </ConditionalRenderer>

          <NotifyLatelessButton
            key={scheduledLesson.id}
            scheduledLesson={scheduledLesson}
            scheduledLessonReport={report}
            studentId={enrollment.student.id}
            klass={klass}
            setRef={setButtonRef}
          />
        </div>
      </div>

      <div className="border border-primary-content h-6 m-0 w-fit hidden xl:flex" />

      <ReportAssessment
        homeworkProgress={homeworkProgress}
        testId="reportAssessment"
        key={`reportAssessment${report?.id}`}
        report={report}
        loading={loadingReport}
        setRef={setVacationRef}
      />
      <VacationNoticeTag
        key={scheduledLesson.id}
        className={{
          base: 'z-50 py-0.5 px-1 text-14 lowercase',
        }}
        offset={[-70, -30]}
        target={vacationRef}
        render={!!vacationNotice}
      />

      <ActiveReportButton
        onActiveReport={handleActivation}
        status={status}
        locked={!permissions.hasPresencePermission}
        loading={loadingUpdate}
      />
    </li>
  );
}

type RemoveAttendanceModalProps = {
  name: string;
  reportId?: number;
  visible?: boolean;
  onConfirm({ presence, reportId }: onConfirm): Promise<void>;
  onCancel(): void;
};

function RemoveAttendanceModal({
  name,
  reportId,
  visible = false,
  onConfirm,
  onCancel,
}: RemoveAttendanceModalProps) {
  const presence = PresenceEnum.MISSED;

  if (reportId) {
    return (
      <ModalDisable
        modalType="confirm"
        selectedObjectName={name}
        onClickConfirm={async () => await onConfirm({ presence, reportId })}
        onClickCancel={onCancel}
        visible={visible}
        translationString="modalStudentAbsence"
      />
    );
  }

  return null;
}

type ReportStatusProps = {
  status: EnrollmentAndReportStatus;
  className?: string;
};

export function StudentReportStatus({ status, className }: ReportStatusProps) {
  const transalatedStatus = useEnrollmentStatusTranslation(status);

  return (
    <ConditionalRenderer condition={status !== EnrollmentStatusEnum.ACTIVE}>
      <Text
        text={transalatedStatus}
        className={twMerge(
          'absolute h-fit w-fit px-1.5 py-0.5 rounded-full left-3 -bottom-2 bg-primary-content text-12 whitespace-nowrap',
          className,
        )}
      />
    </ConditionalRenderer>
  );
}

type ActiveReportButtonProps = ReportStatusProps & {
  loading?: boolean;
  onActiveReport(): Promise<void>;
  locked: boolean;
};

function ActiveReportButton({
  status,
  locked,
  loading,
  onActiveReport,
}: ActiveReportButtonProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });

  return (
    <ConditionalRenderer
      condition={status === TranferredEnum.TRANSFERRED_TO_CLASS}
    >
      <ConditionalWrapper
        condition={locked}
        wrapper={<Tooltip text={t('isLockedDesc')} />}
      >
        <MainButton
          icon={<CheckCircleIcon />}
          text={t('activeStudent')}
          onClick={onActiveReport}
          disabled={locked}
          loading={loading}
        />
      </ConditionalWrapper>
    </ConditionalRenderer>
  );
}
