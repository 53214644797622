import PageTitle from '@/components/common/PageTitle';
import RadarChart, { ChartData } from '@/components/charts/RadarChart';
import Skeleton from '@/components/common/Skeleton';
import { AreaCard } from '@/components/skills/AreaCard';
import Layout from '@/components/template/Layout';
import useAuth from '@/data/hook/useAuth';
import useListService from '@/data/hook/useListService';
import {
  areasQueryKeys,
  studentTagScoreQueryKeys,
} from '@/data/services/querykeys';
import { ListStudentTagScoreParams } from '@/data/services/studentTagScoreServices';
import { Breakpoints } from '@/utils/Breakpoint';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import ConditionalWrapper from '@/components/common/ConditionalWrapper';
import Modal from '@/components/common/modals/Modal';
import TagScoreRank from '@/components/skills/TagScoreRank';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Breadcrumbs from '@/components/skills/AreaBreadcrumb';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { AreaScore, useAreaScore } from './SkillPage.hooks';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';

function SkillPage() {
  const [t] = useTranslation('translation', {
    keyPrefix: 'skillPage',
  });
  const { areaId: areaIdParam } = useParams();
  const areaId = Number(areaIdParam);
  const [isLoadingCard, setIsLoadingCard] = useState(true);
  const { user } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedAreaParam = searchParams.get('area_id');
  const tagScoreFilters: ListStudentTagScoreParams = {
    studentId: user?.id,
    latestOnly: true,
    parentAreaOnly: !areaId,
    parentAreaId: areaId ? areaId : undefined,
  };

  const isLg = useMediaQuery({
    minWidth: Breakpoints.lg,
  });
  const { areaScores, isLoadingAreas } = useAreaScore(tagScoreFilters);
  const areaIdSearch = Number(selectedAreaParam);
  function getAreaScore() {
    if (areaIdSearch) {
      return areaScores.find(({ area }) => area.id === areaIdSearch);
    }
    return isLg ? areaScores.at(0) : undefined;
  }
  const areaScore = getAreaScore();

  function onCloseModal() {
    setSearchParams(new URLSearchParams());
  }

  function setAreaScore(areaScore: AreaScore) {
    setSearchParams(`area_id=${areaScore.area.id}`);
  }

  useEffect(() => {
    setIsLoadingCard(!areaScore);
  }, [areaScore]);

  return (
    <Layout>
      <PageTitle headingText={t('title')} />
      <main className="flex gap-4 flex-col lg:flex-row">
        <div className="w-full max-w-5xl">
          <Breadcrumbs areaId={areaId} />
          <SkillRadarChart
            areaScores={areaScores}
            areaId={areaId}
            isLoadingAreas={isLoadingAreas}
          />
          <TagScoreRank
            selectArea={setAreaScore}
            areaTagScores={areaScores}
            selectedAreaScore={areaScore}
          />
        </div>
        <ConditionalWrapper
          condition={!isLg}
          wrapper={<Modal onClose={onCloseModal} visible={!!areaIdSearch} />}
        >
          <AreaCard
            className="shrink-1"
            selectedAreaScore={areaScore}
            isLoading={isLoadingCard}
          />
        </ConditionalWrapper>
      </main>
    </Layout>
  );
}

export default SkillPage;

type SkillRadarChartProps = {
  areaScores: AreaScore[];
  areaId?: number;
  isLoadingAreas?: boolean;
};

function SkillRadarChart({
  areaScores,
  areaId,
  isLoadingAreas,
}: SkillRadarChartProps) {
  const navigate = useNavigate();
  const [t] = useTranslation('translation', {
    keyPrefix: 'skillPage',
  });
  const chartData = areaScores.map<ChartData>(createChartData);
  const { user } = useAuth();
  const { results: areaWithParents, isInitialLoading } = useListService({
    ...areasQueryKeys.listParents(areaId ?? 0),
    staleTime: Infinity,
    enabled: !!areaId,
  });
  const parentArea = areaWithParents.at(0)?.parentArea;
  const { results: parentTagScores } = useListService({
    ...studentTagScoreQueryKeys.list({
      studentId: user?.id,
      parentAreaId: parentArea?.id,
      latestOnly: true,
      parentAreaOnly: !parentArea?.id,
    }),
    enabled: !!areaId && !!areaWithParents.length,
  });
  const parentChartData = parentTagScores.map<ChartData>(({ score }) => ({
    label: '',
    value: score,
  }));

  function createChartData({ area, tagScore }: AreaScore): ChartData {
    const onClickLabel = area.isLeaf
      ? undefined
      : () => navigate(`/skills/${area.id}`);

    const newData: ChartData = {
      label: area?.name || '',
      value: tagScore.score,
      onClickLabel,
    };

    return newData;
  }
  const isXs = useMediaQuery({
    maxWidth: Breakpoints.xs,
  });
  const isMd = useMediaQuery({
    minWidth: Breakpoints.md,
    maxWidth: Breakpoints.lg,
  });
  const isLg = useMediaQuery({
    minWidth: Breakpoints.lg,
    maxWidth: Breakpoints.xl,
  });

  const labelSize = isXs || isLg ? '0.8rem' : undefined;
  const tooltipText = parentArea
    ? t('chartOf', { name: parentArea?.name })
    : t('generalChart');

  if (isLoadingAreas) {
    return (
      <div className="py-8">
        <Skeleton className="aspect-square bg-primary-content w-2/5 mx-auto hex" />
      </div>
    );
  }

  return (
    <div className="-mb-10 relative">
      <ConditionalRenderer condition={!!areaId && !isInitialLoading}>
        <div className="absolute right-0 z-10 bg-secondary-content rounded-lg">
          <Tooltip text={tooltipText}>
            <RadarChart
              data={parentChartData}
              chartOptions={{
                seriesName: 'xp',
                height: 112,
                width: 112,
                offsetX: 5,
                offsetY: -10,
              }}
            />
          </Tooltip>
        </div>
      </ConditionalRenderer>

      <RadarChart
        data={chartData}
        chartOptions={{
          label: { size: labelSize },
          seriesName: 'xp',
          offsetX: isMd ? 25 : isLg ? 20 : 16,
          offsetY: -40,
          height: isLg ? 500 : 550,
        }}
      />
    </div>
  );
}
