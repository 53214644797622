import { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

export default function SectionContainer({
  className,
  children,
  ...props
}: ComponentProps<'div'>) {
  return (
    <div
      className={twMerge(
        'relative border border-base-300 rounded-md p-2.5 bg-base-100',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}
