import Resource from '@/models/Resource';
import { useQuery } from '@tanstack/react-query';

import Text from '@/components/common/dataDisplay/Text';
import Skeleton from '@/components/common/Skeleton';
import { resourceQuerykeys } from '@/data/services/querykeys';
import { formatFileInfo } from '@/utils/resourceUtils';

type ResourcePreviewProps = {
  resource: Resource;
};

export function ResourcePreview({ resource }: ResourcePreviewProps) {
  return (
    <section className="flex gap-1 items-center h-9">
      <ResourceFilePreview resource={resource} />
      <ResourceLinkPreview resource={resource} />
    </section>
  );
}

function ResourceFilePreview({
  resource,
}: Omit<ResourcePreviewProps, 'onSubmit' | 'resourceType'>) {
  const { resourceFile, uploading } = resource;

  const { data: file, isInitialLoading } = useQuery({
    ...resourceQuerykeys.getFile(String(resourceFile)),
    enabled: !!resourceFile && !uploading,
  });

  if (isInitialLoading) {
    return (
      <Skeleton
        className="h-5 w-20 bg-primary-content rounded"
        testId="loading"
      />
    );
  }

  if (file) {
    return (
      <Text
        text={formatFileInfo(file)}
        className="text-[0.875rem] text-base-300 pt-1"
      />
    );
  }

  return null;
}

function ResourceLinkPreview({
  resource,
}: Omit<ResourcePreviewProps, 'onSubmit' | 'resourceType'>) {
  const { resourceLink } = resource;

  if (!resourceLink) return null;

  return (
    <Text text={resourceLink} className="text-[0.875rem] text-base-300 pt-1" />
  );
}
