import { TrashIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import CoursePath, { CourseCategoryEnum } from '@/models/Course';
import { BranchIcon } from '@/components/icons';
import handleButtonContent from '@/functions/handleCourseStatusIcon';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import AstroImage from '@/components/common/AstroImage';
import Skeleton from '@/components/common/Skeleton';
import Text from '@/components/common/dataDisplay/Text';
import Tag from '@/components/common/dataDisplay/Tag';
import MainButton from '@/components/common/buttons/MainButton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import useListService from '@/data/hook/useListService';
import {
  courseBasesQueryKeys,
  coursePathsQueryKeys,
} from '@/data/services/querykeys';
import useCourseEditing from '@/data/hook/useCourseEditing';
import { Fragment } from 'react/jsx-runtime';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import { useState } from 'react';
import alert from '@/utils/UseAlert';
import { getErrorMessage } from '@/utils/getErrorMessage';
import ModalDisable from '@/components/common/modals/ModalDisable';
import { deleteCourse } from '@/data/services/courseServices';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ComponentGuard from '@/components/common/ComponentGuard';
import { UserTypeEnum } from '@/models/User';
import { Link } from 'react-router-dom';
import CoursePathItemFooter from './CoursePathItemFooter';
import { courseHasVersions } from '@/utils/coursePathUtils';

type CoursePathItemProps = {
  coursePath: CoursePath;
  isDraft?: boolean;
};

export default function CoursePathItem({ coursePath }: CoursePathItemProps) {
  const alternativeSrc = '/images/course-default-image.png';
  const bannerImage = coursePath.course.bannerImg || alternativeSrc;
  const [tCategory] = useTranslation('translation', {
    keyPrefix: 'courseCategory',
  });
  const statusCourseButton = handleButtonContent(
    coursePath.status,
    coursePath.version,
    'w-3.5 h-3.5',
  );
  const { course, version } = coursePath;
  const { results: coursePaths, isInitialLoading: isLoading } = useListService({
    ...coursePathsQueryKeys.list({ course: course.id }),
    enabled: courseHasVersions(version, course.lastCoursePathVersion),
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });
  const categoryColors: Record<
    CourseCategoryEnum,
    'primary' | 'success' | 'warning'
  > = {
    F2F: 'primary',
    ONLINE: 'success',
    SCHOOL: 'warning',
  };
  const categoryColor = categoryColors[coursePath.course.category];

  return (
    <li className="card rounded-lg bg-base-100 w-full p-2 gap-2.5 border border-neutral-content shadow-none">
      <Link to={`/courses/${coursePath.slug}`}>
        <div className="relative">
          <figure>
            <AstroImage
              data-testid="courseCardImage"
              src={bannerImage}
              className="rounded-md w-full h-full"
              alt="course-banner"
              alternativesrc={alternativeSrc}
              validation={{
                renderLoading: () => (
                  <Skeleton className="rounded-md w-full h-[12.181rem] bg-primary-content" />
                ),
              }}
            />
            <ConditionalRenderer
              condition={coursePath.status !== VersioningStatusEnum.PUBLISHED}
            >
              <Tag
                text={statusCourseButton.text}
                icon={statusCourseButton.icon}
                color="custom"
                className="bg-secondary-content rounded-md gap-1 !text-secondary px-1.5 py-1 absolute top-1.5 right-2 font-400 text-14"
              />
            </ConditionalRenderer>
          </figure>
        </div>
      </Link>
      <div className="flex flex-col gap-2.5">
        <Link to={`/courses/${coursePath.slug}`}>
          <div className="flex justify-between items-center w-full gap-0.5">
            <div className="flex gap-y-1 gap-x-2.5 flex-wrap items-center">
              <Text
                text={coursePath.course.name}
                as="h2"
                format="rubik-500"
                className="text-16"
              />
              <Tag className="py-0.5 px-1.5 border" color={categoryColor}>
                <Text
                  text={tCategory(coursePath.course.category)}
                  format="rubik-400"
                  className="text-12"
                />
              </Tag>
            </div>
            <Text
              format="rubik-500"
              text={`v${coursePath.version.toFixed(1)}`}
              className="text-14 text-secondary"
            />
          </div>
        </Link>

        <ActionButtons
          coursePath={coursePath}
          coursePathList={coursePaths}
          isLoading={isLoading}
        />
        <CoursePathItemFooter
          coursePath={coursePath}
          coursePathList={coursePaths}
        />
      </div>
    </li>
  );
}

type ActionButtonsProps = {
  coursePath: CoursePath;
  coursePathList: CoursePath[];
  isLoading?: boolean;
};

function ActionButtons({
  coursePath: { status, version, slug, course },
  coursePathList,
  isLoading,
}: ActionButtonsProps) {
  type LoadingTypes = 'none' | 'versioning' | 'editing';
  const [isLoadingRequest, setIsLoadingRequest] =
    useState<LoadingTypes>('none');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [t] = useTranslation('translation', { keyPrefix: 'coursesView.admin' });
  const isPublished = status === VersioningStatusEnum.PUBLISHED;
  const isFirstVersion = version <= 1;
  const hasVersioningCourse = coursePathList.some(({ status }) =>
    [VersioningStatusEnum.VERSIONING, VersioningStatusEnum.BETA].includes(
      status,
    ),
  );
  const hasEditingCourse = coursePathList.some(
    ({ status }) => status === VersioningStatusEnum.EDITING,
  );
  const editText = hasEditingCourse ? 'continueEdit' : 'createEdit';
  const versioningText = hasVersioningCourse
    ? 'continueVersion'
    : 'createVersion';
  const isCreatingFirstVersion = isFirstVersion && !isPublished;
  const { allowVersioningMode, allowEditingMode } = useCourseEditing();
  const queryClient = useQueryClient();

  const { mutate, isLoading: isDiscarding } = useMutation(deleteCourse, {
    onSuccess() {
      queryClient.invalidateQueries(coursePathsQueryKeys.list._def);
      queryClient.invalidateQueries(courseBasesQueryKeys.list._def);
    },
    onError(error) {
      alert.error(getErrorMessage(error));
    },
    onSettled() {
      setIsModalOpen(false);
    },
  });

  async function actionDecorator(
    loading: LoadingTypes,
    cb: () => Promise<void>,
  ) {
    try {
      setIsLoadingRequest(loading);
      await cb();
      await queryClient.invalidateQueries(coursePathsQueryKeys.list._def);
    } catch (error) {
      alert.error(getErrorMessage(error));
    } finally {
      setIsLoadingRequest('none');
    }
  }

  async function createCourseVersion() {
    const versioningCourse = coursePathList.find(
      ({ status }) => status === VersioningStatusEnum.VERSIONING,
    ) ?? { status, slug };
    await actionDecorator('versioning', () =>
      allowVersioningMode(versioningCourse, isFirstVersion),
    );
  }
  async function createCourseEdit() {
    const edittingCourse = coursePathList.find(
      ({ status }) => status === VersioningStatusEnum.EDITING,
    ) ?? { status, slug };
    await actionDecorator('editing', () =>
      allowEditingMode(edittingCourse, isFirstVersion),
    );
  }
  const disabledButtons = isLoadingRequest !== 'none';
  const courseName = `${course.name} v${version.toFixed(1)}`;

  return (
    <ComponentGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
      <div className="flex flex-col gap-0.5" data-testid="versiong-actions">
        <ModalDisable
          onClickCancel={() => setIsModalOpen(false)}
          onClickConfirm={() => mutate(course.id)}
          translationString="modalDeleteCourse"
          visible={isModalOpen}
          isRequesting={isDiscarding}
          selectedObjectName={courseName}
          modalType="delete"
        />
        <ConditionalRenderer
          condition={!isLoading}
          fallback={
            <Fragment>
              <Skeleton className="h-3 w-4/5 rounded-full bg-primary-content" />
              <Skeleton className="h-3 w-4/5 rounded-full bg-primary-content" />
            </Fragment>
          }
        >
          <ConditionalRenderer condition={!isCreatingFirstVersion}>
            <TextButton
              disabled={disabledButtons}
              onClick={createCourseEdit}
              text={t(editText)}
              isLoading={isLoadingRequest === 'editing'}
              type="edit"
            />
            <TextButton
              disabled={disabledButtons}
              onClick={createCourseVersion}
              text={t(versioningText)}
              type="edit"
              isLoading={isLoadingRequest === 'versioning'}
            />
          </ConditionalRenderer>
          <ConditionalRenderer condition={isCreatingFirstVersion}>
            <TextButton
              disabled={disabledButtons}
              onClick={createCourseVersion}
              text={t('continueCreating')}
              type="edit"
              isLoading={isLoadingRequest === 'versioning'}
            />
            <TextButton
              onClick={() => setIsModalOpen(true)}
              disabled={disabledButtons}
              text={t('discardCourse')}
              type="discard"
            />
          </ConditionalRenderer>
        </ConditionalRenderer>
      </div>
    </ComponentGuard>
  );
}

type TextButtonProps = {
  text: string;
  type: 'discard' | 'edit';
  onClick?(): void | Promise<void>;
  isLoading?: boolean;
  disabled?: boolean;
};

function TextButton({
  text,
  type,
  onClick,
  disabled,
  isLoading,
}: TextButtonProps) {
  const isDiscardType = type === 'discard';
  const icon = isDiscardType ? (
    <TrashIcon strokeWidth={3} />
  ) : (
    <BranchIcon className="h-4 w-4" strokeWidth={2.8} />
  );
  const color = isDiscardType ? '!text-error' : '!text-primary';

  return (
    <MainButton
      loading={isLoading}
      disabled={disabled}
      onClick={onClick}
      text={text}
      color="custom"
      className={`flex-row-reverse gap-1.5 font-rubik text-14 truncate font-600 ${color}`}
      icon={icon}
    />
  );
}
