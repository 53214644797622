import { PlusIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import MainButton from '@/components/common/buttons/MainButton';
import ComponentGuard from '@/components/common/ComponentGuard';
import { UserTypeEnum } from '@/models/User';
import PageTitle from '@/components/common/PageTitle';
import HeadTitle from '@/components/common/HeadTitle';
import CoursesTab from '@/components/common/dataDisplay/course/coursesTab/CoursesTab';

export default function AdminCoursesView() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'coursesView.admin',
  });

  return (
    <div className="flex flex-col gap-6">
      <HeadTitle routeInfo={t('courses')} />
      <div className="flex justify-between lg:w-full items-center mt-8">
        <PageTitle headingText={t('courses')} position="mb-0 md:mb-0" />
        <ComponentGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
          <MainButton
            href="/admin/courses/add"
            icon={<PlusIcon />}
            text={t('newCourse')}
            color="custom"
            className="flex-row-reverse font-600 text-primary gap-1.5"
          />
        </ComponentGuard>
      </div>
      <CoursesTab />
    </div>
  );
}
