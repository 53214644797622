import IconButton from '@/components/common/buttons/IconButton';
import Text from '@/components/common/dataDisplay/Text';
import { LoadingIcon } from '@/components/icons';
import useCourseEditing from '@/data/hook/useCourseEditing';
import { ChapterRouteParams } from '@/data/services/sectionServices';
import { Section } from '@/models/Book';
import { ImageFolderEnum } from '@/models/CkEditor';
import { Breakpoints } from '@/utils/Breakpoint';
import { CheckIcon, TrashIcon } from '@heroicons/react/outline';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import ConditionalRenderer from '../../../../ConditionalRenderer';
import EditorJs from '../../../../dataInput/EditorJS';
import SectionContainer from '../view/SectionContainer';
import SectionTitleForm from './SectionTitleForm';
import StartHiddenInput from './StartsHiddenInput';

export type SectionFormFields = Pick<
  Section,
  'content' | 'title' | 'icon' | 'startsHidden'
>;

type SectionFormProps = {
  section: Section;
  isDeleting?: boolean;
  isLoading?: boolean;
  hasChanges?: boolean;
  onDelete: () => void;

  onSubmit: (params: {
    section: SectionFormFields;
    validated: boolean;
  }) => void;
} & ChapterRouteParams;

export default function SectionForm({
  section,
  onSubmit,
  onDelete,
  isLoading,
  isDeleting,
  hasChanges,
  ...routeParams
}: SectionFormProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'section.form',
  });

  const { t: tPage } = useTranslation('translation', {
    keyPrefix: 'editMaterialPage',
  });
  const { allowBigChanges } = useCourseEditing();

  const isXsScreen = useMediaQuery({
    minWidth: Breakpoints.xs,
  });
  const { watch, control, trigger, setValue } = useForm<SectionFormFields>({
    defaultValues: {
      content: section.content,
      title: section.title,
      icon: section.icon,
      startsHidden: section.startsHidden,
    },
  });

  useEffect(() => {
    const w = watch(e => {
      trigger().then(validated =>
        onSubmit({ section: e as SectionFormFields, validated }),
      );
    });
    return () => w.unsubscribe();
  }, [watch, onSubmit, trigger, section]);
  return (
    <SectionContainer className="w-full h-full">
      <div className="relative" role="form" data-testid="sectionForm">
        <div className="flex gap-2.5 items-center flex-wrap">
          <SectionTitleForm
            control={control}
            onClose={() => {
              setValue('title', section.title);
            }}
          />
          {/* TODO: enable when has web socket back impl */}
          <ConditionalRenderer condition={false}>
            <Controller
              control={control}
              name="startsHidden"
              render={({ field: { value, onChange } }) => (
                <StartHiddenInput value={value} onChange={onChange} />
              )}
            />
          </ConditionalRenderer>
        </div>
        <Controller
          control={control}
          name="content"
          render={({ field: { value, onChange } }) => (
            <EditorJs
              folder={ImageFolderEnum.BOOK}
              value={value}
              onChange={onChange}
              extraBody={routeParams}
              minHeight={0}
            />
          )}
        />
        <div className="relative ml-auto w-fit sm:absolute top-0 right-0 flex gap-2">
          <ConditionalRenderer condition={isLoading}>
            <LoadingIcon className="text-secondary w-5 h-5" />
          </ConditionalRenderer>
          <ConditionalRenderer condition={!isLoading && hasChanges}>
            <div className="flex gap-1 items-center">
              <CheckIcon className="text-success w-4 h-4" />
              {isXsScreen && (
                <Text className="text-14 text-sucess" text={t('save')} />
              )}
            </div>
          </ConditionalRenderer>
          <IconButton
            onClick={onDelete}
            type="button"
            loading={isDeleting}
            className="w-5 h-5 text-error"
            testId="deleteSection"
            tooltip={allowBigChanges ? t('deleteSection') : tPage('cantModify')}
            disabled={!allowBigChanges}
            icon={<TrashIcon />}
          />
        </div>
      </div>
    </SectionContainer>
  );
}
