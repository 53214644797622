import { AxiosResponse } from 'axios';
import { ROLES } from './roles';

function formatFileSize(bytes: number): string {
  if (bytes < 1024) return `${bytes} bytes`;
  if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(2)}kb`;
  return `${(bytes / (1024 * 1024)).toFixed(2)}mb`;
}

export function formatFileInfo(file: AxiosResponse<File, any>): string {
  const headers = file.headers;

  const contentLength = Number(headers['content-length']);
  const contentType = headers['content-type'];

  const size = contentLength ? formatFileSize(contentLength) : '';

  const fileExtension = ROLES.RESOURCE.FILE_EXTENSION(contentType);

  return `${fileExtension} • ${size}`;
}
