import { AlterDatePill } from '@/components/admin/calendar/ManageScheduledLesson/AlterDatePill';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { IconWithText } from '@/components/common/dataDisplay/IconWithText';
import Text from '@/components/common/dataDisplay/Text';
import LinkText from '@/components/common/LinkText';
import Skeleton from '@/components/common/Skeleton';
import { ActivityColorIcon, LoadingIcon } from '@/components/icons';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import { klassesQueryKeys } from '@/data/services/querykeys';
import { formatLessonName } from '@/functions/lessonsName';
import ScheduledLesson from '@/models/ScheduledLesson';
import { ROUTES } from '@/utils/routes';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { DetailTargetProps } from '../../card/NotificationTypes';

const DelayNotificationDetail: React.FC<DetailTargetProps<ScheduledLesson>> = ({
  target: scheduledLesson,
  isLoading,
}) => {
  const { data: klass, isInitialLoading: isInitialLoadingKlass } = useQuery({
    ...klassesQueryKeys.get(scheduledLesson?.klass || 0),
    staleTime: REQUEST_STALE_TIME_IN_MS,
    enabled: !isNaN(scheduledLesson?.klass || NaN),
  });

  if (isLoading) {
    return (
      <div className="w-full flex justify-center">
        <LoadingIcon className="h-5 w-5 text-primary" />
      </div>
    );
  }

  if (!scheduledLesson) {
    return <Text text="Não foi possivel buscar os detalhes da notificação" />;
  }

  const { lesson, datetime } = scheduledLesson;

  const reportLink = ROUTES.CLASS.LESSON_REPORT(
    scheduledLesson.klass,
    scheduledLesson.id,
  );

  const klassLink = ROUTES.ADMIN.CLASSES.INFO({
    name: klass?.name.replace('#', '') || '',
  });

  return (
    <div className="manage-scheduled-lesson flex justify-between bg-secondary-content p-1.5 rounded-md border border-secondary/20">
      <div className="flex flex-col gap-0.5">
        <Link
          to={reportLink}
          className="flex hover:text-secondary text-primary transition-all duration-150 ease-in-out"
        >
          <IconWithText
            className={{
              base: 'flex-row-reverse gap-1',
              text: 'font-700 text-[0.875rem] md:text-[1rem] underline',
            }}
            text={formatLessonName(lesson)}
            icon={
              <ActivityColorIcon className="w-6 h-6 p-0.5 bg-base-100 rounded-full border hover:ring ring-primary-content transition-all duration-150 ease-in-out" />
            }
          />
        </Link>

        <ConditionalRenderer
          condition={!isInitialLoadingKlass}
          fallback={
            <Skeleton className="bg-secondary/20 h-[15px] w-full rounded-md" />
          }
        >
          <LinkText
            href={klassLink}
            text={klass?.name}
            className="font-rubik !text-12 md:!text-14 leading-none text-primary font-500"
          />
        </ConditionalRenderer>
      </div>
      <AlterDatePill
        dateTime={new Date(datetime)}
        klassDuration={klass?.duration}
      />
    </div>
  );
};

export default DelayNotificationDetail;
