import { BaseFilters } from '@/models/Service';
import { ListResourceFilters } from './resourceServices';
import requester from '@/axios';
import JsonApiResponse from '@/models/JsonApiResponse';
import { ResourceProgress } from '@/models/ResourceProgress';

const baseUrl = 'resource-progress';

export type ListResourceProgressFilters = BaseFilters &
  ListResourceFilters & {
    resourceId?: number[];
  };

export async function listresourceProgress(
  params: ListResourceProgressFilters = {},
) {
  const { data } = await requester().get<JsonApiResponse<ResourceProgress>>(
    `${baseUrl}/`,
    {
      params,
    },
  );

  return data;
}

export async function getResourceProgress(resourceProgressId: number) {
  const { data } = await requester().get<ResourceProgress>(
    `${baseUrl}/${resourceProgressId}/`,
  );

  return data;
}

type ResourceProgressRequest = {
  resourceProgressId: number;
  body: Partial<ResourceProgress>;
};

export async function updateResourceProgress({
  resourceProgressId,
  body,
}: ResourceProgressRequest) {
  const { data } = await requester().patch<ResourceProgress>(
    `${baseUrl}/${resourceProgressId}/`,
    body,
  );

  return data;
}
