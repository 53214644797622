import { useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { InformationCircleIcon } from '@heroicons/react/outline';

import { fadeIn } from '@/utils/animations/commom';
import Klass from '@/models/Klass';
import { KlassAssessment } from '@/models/KlassAssessment';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import { SheetTab } from '@/models/SheetTab';
import Tag from '@/components/common/dataDisplay/Tag';
import { UserTypeEnum } from '@/models/User';
import ComponentGuard from '@/components/common/ComponentGuard';
import Assessments from './KlassAssessments/Assessments';
import BehaviorAssessmentForm from './KlassBehaviorAssessmentForm/BehaviorAssessmentForm';
import CommentAssessmentForm from './KlassCommentAssessmentForm/CommentAssessmentForm';
import ToggleButtonGroup, {
  ToggleButton,
} from '@/components/common/dataInput/ToggleGroup';
import { getErrorMessage } from '@/utils/getErrorMessage';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { klassAssessmentsQueryKeys } from '@/data/services/querykeys';

export type EditAssessment = {
  tab: SheetTab;
  assessment: KlassAssessment;
};

export type KlassSheetProps = {
  klass: Klass;
};

export default function KlassSheet({ klass }: KlassSheetProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassSheet',
  });

  const [klassAssessment, setKlassAssessment] = useState<KlassAssessment>();

  const {
    results: klassAssessments,
    isInitialLoading: isLoadingKlassAssessments,
    isFetchingNextPage,
    error: klassAssessmentsError,
    hasNextPage,
    fetchNextPage,
    invalidate: updateAssessments,
  } = useInfiniteService({
    keepPreviousData: true,
    enabled: !!klass.id,
    staleTime: REQUEST_STALE_TIME_IN_MS,
    ...klassAssessmentsQueryKeys.list({
      klassId: klass.id,
      pageSize: 5,
    })._ctx.infinity,
  });

  const assessmentsError = getErrorMessage(klassAssessmentsError);

  type Tabs = {
    [key in SheetTab]: JSX.Element;
  };

  const [tab, setTab] = useState<SheetTab>('view');

  function changeTab(changed: SheetTab) {
    if (changed === tab) setTab('view');
    else setTab(changed);
  }

  const editAssessment = ({ assessment, tab }: EditAssessment) => {
    setKlassAssessment(assessment);
    changeTab(tab);
  };

  const tabs: Tabs = {
    view: (
      <Assessments
        klass={klass}
        assessments={klassAssessments}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        editAssessment={editAssessment}
        tab={tab}
        isFetchingNextPage={isFetchingNextPage}
        error={assessmentsError}
        isLoading={isLoadingKlassAssessments}
      />
    ),
    comment: (
      <CommentAssessmentForm
        klass={klass}
        klassAssessment={klassAssessment}
        updateAssessments={updateAssessments}
        changeTab={changeTab}
      />
    ),
    behavior: (
      <BehaviorAssessmentForm
        klass={klass}
        klassAssessment={klassAssessment}
        updateAssessments={updateAssessments}
        changeTab={changeTab}
        tab={tab}
      />
    ),
  };

  const buttons: ToggleButton<SheetTab>[] = [
    {
      testId: 'addAssessmentButton',
      text: t('assessment'),
      value: 'behavior',
      disabled: isLoadingKlassAssessments,
    },
    {
      testId: 'addCommentButton',
      text: t('comment'),
      value: 'comment',
      disabled: isLoadingKlassAssessments,
    },
  ];

  return (
    <motion.div className="flex flex-col w-full gap-2 px-8 py-5" {...fadeIn}>
      <Tag
        text={t('title')}
        icon={<InformationCircleIcon className="w-6 h-6" />}
      />

      <ComponentGuard
        roles={[
          UserTypeEnum.SUPER_ADMIN,
          UserTypeEnum.TEACHER,
          UserTypeEnum.UNIT_ADMIN,
        ]}
      >
        <ToggleButtonGroup
          buttons={buttons}
          value={tab}
          onChange={tab => {
            setKlassAssessment(undefined);
            changeTab(tab);
          }}
          exclusive
        />
      </ComponentGuard>

      {tabs[tab]}
    </motion.div>
  );
}
