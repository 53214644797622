import { createContext, useCallback, useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import User, { AuthToken } from '@/models/User';
import TokenResponse from '@/models/TokenResponse';
import {
  getTokenResponse,
  removeTokenResponse,
  setTokenResponse,
} from '@/functions/tokenSettings';
import { lessonsProgressesQueryKeys, meQueryKeys } from '../services/querykeys';
import useDeleteUpdates from '../hook/useDeleteUpdates';
import { UPDATES_ENDTIME } from '@/constants';

type AccessConfig = {
  tokenResponse: TokenResponse;
  remember: boolean;
};

export interface AuthContextProps {
  user?: User;
  isLoading: boolean;
  signup?: (username: string, password: string) => Promise<void>;
  jwt?: string | null;
  logout?: () => void;
  handleAccessConfig?({ tokenResponse, remember }: AccessConfig): void;
}

export const AuthContext = createContext<AuthContextProps>({
  isLoading: false,
});

export function AuthProvider(props: any) {
  const queryClient = useQueryClient();
  const [jwt, setJwt] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const logout = useCallback(() => {
    setLoading(true);
    removeTokenResponse();
    setJwt(null);
    queryClient.removeQueries();
    queryClient.clear();

    setLoading(false);
  }, [queryClient]);

  const { data: user, isInitialLoading: loadingMe } = useQuery({
    enabled: !!jwt,
    ...meQueryKeys.get(jwt ?? ''),
  });

  useEffect(() => {
    if (user) moment.tz.setDefault(user.timezone);
  }, [user]);

  useEffect(() => {
    setLoading(true);

    const tokenResponse = getTokenResponse();

    const access = tokenResponse?.access;

    if (!user && access) {
      const token = jwtDecode<AuthToken>(access);
      setJwt(access);
      if (!('userType' in token)) logout();
    }

    setLoading(false);
  }, [logout, user]);

  function handleAccessConfig({ tokenResponse, remember }: AccessConfig) {
    setJwt(tokenResponse.access);

    setTokenResponse({ tokenResponse, remember });

    navigate('/');
  }

  useEffect(() => {
    queryClient.removeQueries(lessonsProgressesQueryKeys.list());
  }, [queryClient]);

  useDeleteUpdates({
    dueDate: moment()
      .subtract(...UPDATES_ENDTIME)
      .toDate(),
    keywords: ['deleteExtraLesson'],
  });

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading: loadingMe || loading,
        jwt,
        logout,
        handleAccessConfig,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
