import {
  DragDropContext,
  DragDropContextProps,
  Draggable,
  DraggableChildrenFn,
  Droppable,
  DropResult,
} from '@hello-pangea/dnd';
import { PropsWithChildren, ReactNode } from 'react';
import ConditionalRenderer from '../ConditionalRenderer';
import { Tooltip } from '../dataDisplay/Tooltip';
import { DragIcon } from '@/components/icons';
import { twMerge } from 'tailwind-merge';

export const DragDropList = ({
  children,
  groupId,
  className,
  renderClone,
  ...props
}: PropsWithChildren<
  {
    groupId: string;
    className?: string;
    renderClone?: DraggableChildrenFn;
  } & DragDropContextProps
>) => {
  return (
    <DragDropContext {...props}>
      <Droppable
        droppableId={groupId}
        mode="standard"
        renderClone={renderClone}
      >
        {provided => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={twMerge('flex flex-col gap-2.5', className)}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

type DragDropItemProps = {
  id: string;
  index: number;
  dragIconHandle?: ReactNode;
  disabled?: boolean;
  tooltip?: string;
};

export const DragDropItem = ({
  children,
  index,
  tooltip,
  dragIconHandle,
  disabled,
  id,
}: PropsWithChildren<DragDropItemProps>) => {
  return (
    <Draggable
      draggableId={id}
      index={index}
      isDragDisabled={disabled}
      key={id}
    >
      {provided => (
        <div
          ref={provided.innerRef}
          data-testid={id}
          {...provided.draggableProps}
          className="gap-1.5 flex items-start"
        >
          <ConditionalRenderer condition={!disabled}>
            <div
              {...provided.dragHandleProps}
              className="relative -top-1"
              data-testid={'dndHandle-' + id}
            >
              <Tooltip text={tooltip}>
                {dragIconHandle || <DragIcon className="w-4" />}
              </Tooltip>
            </div>
          </ConditionalRenderer>
          <div className={!disabled ? 'w-[calc(100%-22px)]' : 'w-full'}>
            {children}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export const dropHandler = <T,>(dndResult: DropResult, originalArray: T[]) => {
  if (
    !dndResult.destination ||
    dndResult.destination.index === dndResult.source.index
  )
    return originalArray;

  const arrayReordened = Array.from(originalArray);
  const [item] = arrayReordened.splice(dndResult.source.index, 1);

  arrayReordened.splice(dndResult.destination.index, 0, item);
  return arrayReordened;
};
