import { ChevronLeftIcon, XIcon } from '@heroicons/react/outline';
import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import ConditionalRenderer from '../ConditionalRenderer';
import IconButton from '../buttons/IconButton';

export type ModalProps = PropsWithChildren<{
  visible: boolean;
  onClose?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;

  closeButtonTestId?: string;
  testId?: string;
  className?: string;
  onBack?(): void;
}>;

export default function Modal(props: ModalProps) {
  const {
    visible,
    onClose,
    closeButtonTestId,
    children,
    testId,
    className,
    onBack,
  } = props;
  return (
    <ConditionalRenderer condition={visible}>
      <div
        data-testid={testId || 'modal'}
        className={twMerge(
          'modal flex justify-center text-center overflow-x-hidden',
          visible ? 'modal-open' : '',
        )}
      >
        <div
          className={twMerge(
            'modal-box p-10 relative overflow-visible',
            className,
          )}
        >
          <ConditionalRenderer condition={!!onBack || !!onClose}>
            <div className="absolute flex items-center left-0 px-4 top-4 w-full h-6">
              <ConditionalRenderer condition={onBack}>
                <IconButton
                  icon={
                    <ChevronLeftIcon className="w-6 h-6 text-base-content" />
                  }
                  onClick={onBack}
                />
              </ConditionalRenderer>
              <ConditionalRenderer condition={!!onClose}>
                <IconButton
                  icon={
                    <XIcon
                      data-testid={closeButtonTestId}
                      className="text-base-content self-end w-6 h-6"
                    />
                  }
                  className="ml-auto"
                  onClick={onClose}
                />
              </ConditionalRenderer>
            </div>
          </ConditionalRenderer>
          {children}
        </div>
      </div>
    </ConditionalRenderer>
  );
}
