import IconButton from '@/components/common/buttons/IconButton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { IconWithText } from '@/components/common/dataDisplay/IconWithText';
import Text from '@/components/common/dataDisplay/Text';
import TextInput from '@/components/common/dataInput/TextInput';
import Popup from '@/components/common/Popup';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import useAuth from '@/data/hook/useAuth';
import useInfiniteService from '@/data/hook/useInfiniteService';
import useToggle from '@/data/hook/useToggle';
import { klassesQueryKeys } from '@/data/services/querykeys';
import { isAdmin, isUnitAdmin } from '@/functions/auth';
import CoursePath from '@/models/Course';
import { KlassStatusEnum } from '@/models/Klass';
import User, { UserTypeEnum } from '@/models/User';
import { courseHasVersions } from '@/utils/coursePathUtils';
import { scrollBarYClassName } from '@/utils/scrollBarClassName';
import {
  ClockIcon,
  EyeIcon,
  InformationCircleIcon,
  SearchIcon,
} from '@heroicons/react/outline';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfoSideModal from '@/components/common/InfoSideModal';

import MainButton from '@/components/common/buttons/MainButton';
import { LoadingIcon } from '@/components/icons';
import ComponentGuard from '@/components/common/ComponentGuard';

type CardFooterProps = {
  coursePath: CoursePath;
  coursePathList: CoursePath[];
};

export default function CoursePathItemFooter({
  coursePath,
  coursePathList,
}: CardFooterProps) {
  const { lastUpdate } = coursePath;
  const { user } = useAuth();
  const [t] = useTranslation('translation', { keyPrefix: 'coursesView.admin' });

  return (
    <div className="flex flex-col-reverse items-start xs:flex-row gap-y-1 gap-x-2 xs:items-center justify-between">
      <ComponentGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
        <ConditionalRenderer condition={!!lastUpdate}>
          <Text
            className="text-14"
            text={t('lastEdit', {
              dateDistance: moment(lastUpdate).fromNow(),
            })}
          />
        </ConditionalRenderer>
      </ComponentGuard>
      <PopupHandler
        coursePathList={coursePathList}
        user={user}
        coursePath={coursePath}
      />
    </div>
  );
}

type PopupHandlerProps = CardFooterProps & {
  user: User | undefined;
};

function PopupHandler({ user, coursePath, coursePathList }: PopupHandlerProps) {
  const userType = user?.userType;
  if (!isAdmin(user?.userType)) {
    return null;
  }
  if (isUnitAdmin(userType)) {
    return <UnitAdminPopup coursePath={coursePath} />;
  }

  return (
    <SuperAdminPopup coursePath={coursePath} coursePathList={coursePathList} />
  );
}

type SuperAdminPopupProps = Pick<
  CardFooterProps,
  'coursePath' | 'coursePathList'
>;

function SuperAdminPopup({
  coursePath: {
    version,
    course: { lastCoursePathVersion },
  },
  coursePathList,
}: SuperAdminPopupProps) {
  const [t] = useTranslation('translation', { keyPrefix: 'coursesView.admin' });
  const hasVersions = courseHasVersions(version, lastCoursePathVersion);

  const popupReference = (
    <IconWithText
      className={{
        base: 'flex-row-reverse gap-1.5 text-primary cursor-pointer',
        text: 'text-14',
      }}
      icon={<ClockIcon className="w-4" />}
      text={t('versions')}
    />
  );
  return (
    <ConditionalRenderer condition={hasVersions}>
      <Popup
        reference={popupReference}
        hover
        placement="bottom-end"
        offset={[2, 2]}
      >
        <ul
          className={`flex flex-col gap-1.5 border border-neutral-content bg-base-100 p-4 rounded-lg max-h-40 overflow-y-auto ${scrollBarYClassName}`}
        >
          {coursePathList.map(({ id, version, slug }) => (
            <li key={id} className="flex gap-2 items-center">
              <Text
                className="text-primary hover:opacity-70"
                href={`/courses/${slug}`}
                text={`v${version.toFixed(1)}`}
              />
            </li>
          ))}
        </ul>
      </Popup>
    </ConditionalRenderer>
  );
}

type UnitAdminPopupProps = Omit<CardFooterProps, 'coursePathList'>;

function UnitAdminPopup({ coursePath }: UnitAdminPopupProps) {
  const [t] = useTranslation('translation', { keyPrefix: 'coursesView.admin' });
  const [search, setSearch] = useState('');
  const [klassCount, setKlassCount] = useState(0);
  const { isOpen, open, close } = useToggle();
  const {
    results: klasses,
    meta,
    isPreviousData,
  } = useInfiniteService({
    ...klassesQueryKeys.list({
      courseId: [coursePath.course.id],
      status: [KlassStatusEnum.OPEN, KlassStatusEnum.IN_PROGRESS],
      name: search,
    })._ctx.infinity,
    staleTime: REQUEST_STALE_TIME_IN_MS,
    keepPreviousData: true,
  });
  const paginationCount = meta?.pagination.count ?? 0;
  const klassCountText = t('klassesCount', { count: klassCount });

  const modalTrigger = (
    <MainButton
      text={klassCountText}
      color="custom"
      onClick={open}
      className="!text-primary !font-600 font-rubik text-14 cursor-pointer"
    />
  );

  useEffect(() => {
    if (!search && !!paginationCount) {
      setKlassCount(paginationCount);
    }
  }, [paginationCount, search]);

  if (klassCount === 0) return null;

  return (
    <InfoSideModal
      trigger={modalTrigger}
      open={isOpen}
      onClose={close}
      title={coursePath.course.name}
      subtitle={klassCountText.toLowerCase()}
    >
      <div className="flex flex-col gap-2.5">
        <TextInput
          value={search}
          onChange={e => setSearch(e.target.value)}
          icon={
            isPreviousData ? (
              <LoadingIcon className="h-4" />
            ) : (
              <SearchIcon className="h-4" />
            )
          }
          placeholder={t('search')}
        />
        <ul className="flex flex-col gap-2 max-h-96">
          {klasses.map(({ id, name, coursePathSlug }) => (
            <li key={id} className="flex gap-2 items-center">
              <Text
                className="text-14 text-base-content truncate w-full"
                format="rubik-500"
                text={name}
              />
              <div className="flex gap-2.5 items-center text-primary">
                <IconButton
                  testId={`klassTableLink${id}`}
                  href={`/admin/classes?name=${name.replace('#', '')}`}
                  icon={
                    <InformationCircleIcon className="h-5" strokeWidth={2} />
                  }
                />
                <IconButton
                  testId={`klassPageLink${id}`}
                  href={`/courses/${coursePathSlug}/class/${id}`}
                  icon={<EyeIcon className="h-5" strokeWidth={2} />}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </InfoSideModal>
  );
}
