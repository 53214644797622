import Text from '@/components/common/dataDisplay/Text';
import { LoadingIcon } from '@/components/icons';
import {
  FilterFields,
  useBankFilters,
} from '@/data/context/BankFiltersContext';
import { animMerge, fadeIn, slideHorizontal } from '@/utils/animations/commom';
import { bankTypes } from '@/utils/modalBankUtils';
import { SearchIcon } from '@heroicons/react/outline';
import { AnimatePresence, motion } from 'framer-motion';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ConditionalRenderer from '../../ConditionalRenderer';
import TextInput from '../../dataInput/TextInput';
import ToggleButtonGroup, { ToggleButton } from '../../dataInput/ToggleGroup';
import CardsFactory, { Item } from './CardsFactory/CardsFactory';
import { ExternalModalBankProps, UseFunctions } from './ModalBank';
import InfinityList from '../../InfinityList';
import { useItems } from './ListBankCards.hooks';

type ListBankCardsProps = {
  mode: bankTypes;
  checkable?: boolean;
  loading?: boolean;
} & UseFunctions &
  ExternalModalBankProps;

export const ListBankCards = ({
  mode,
  checkable,
  onUseActivity,
  onUseLesson,
  onUseQuestion,
  loading,
  selectedId,
  includedIds,
}: ListBankCardsProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalBank.list',
  });

  const { filters, setFilter, setFilters } = useBankFilters();

  const {
    results,
    isFetching,
    isFetchingNextPage,
    isInitialLoading: isLoading,
    hasNextPage,
    fetchNextPage,
  } = useItems(mode, filters);

  const resultsOptimized = JSON.stringify(results);

  const items = useMemo(() => {
    const items = JSON.parse(resultsOptimized) as Item[];
    return items;
  }, [resultsOptimized]);

  const filterFeedbackUser = isFetching && !isLoading && !isFetchingNextPage;

  const onToggle = (toggleValues: ToogleValue[]) => {
    let newValues: FilterFields = {} as FilterFields;

    if (toggleValues.includes('my')) {
      newValues['author'] = Array.from(new Set([...filters.author, 'my']));
    } else if (!checkable)
      newValues['author'] = filters.author.filter(author => author !== 'my');

    newValues['courseAbbreviation'] = toggleValues.includes('course');
    setFilters({ ...filters, ...newValues });
  };

  const buildToggleValueFromFilters = () => {
    let toggleValue: ToogleValue[] = [];
    if (filters.author.includes('my')) toggleValue.push('my');
    if (filters.courseAbbreviation) toggleValue.push('course');
    return toggleValue;
  };

  return (
    <div className="relative flex flex-col gap-4 w-full">
      <ListHeader
        onSearch={debounce(search => setFilter('search', search), 500)}
        toggleValue={buildToggleValueFromFilters()}
        onToggle={onToggle}
        isLoading={filterFeedbackUser}
      />
      <InfinityList
        className="relative h-[76vh] pr-5 gap-4"
        scroll
        onReachEnd={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
      >
        {useMemo(
          () => (
            <AnimatePresence>
              {items.map((item, index) => {
                const customKey = JSON.stringify({
                  ...item,
                  teacherRating: undefined,
                  isPublicInBank: undefined,
                  inBank: undefined,
                });
                return (
                  <CardsFactory
                    checkable={checkable}
                    item={item}
                    key={customKey}
                    disabledAnimation={index > 20}
                    onUseLesson={onUseLesson}
                    onUseActivity={onUseActivity}
                    onUseQuestion={onUseQuestion}
                    loading={loading}
                    selectedId={selectedId}
                    includedIds={includedIds}
                  />
                );
              })}
            </AnimatePresence>
          ),
          [
            items,
            checkable,
            onUseLesson,
            onUseActivity,
            onUseQuestion,
            loading,
            selectedId,
            includedIds,
          ],
        )}
        <ConditionalRenderer condition={isLoading}>
          <div className="flex w-full h-[76vh] justify-center items-center">
            <LoadingIcon className="text-primary/40 w-10 h-10" />
          </div>
        </ConditionalRenderer>
        <ConditionalRenderer condition={!items.length}>
          <Text
            text={t(`empty.${mode}`)}
            className="flex justify-center mt-5"
          />
        </ConditionalRenderer>
      </InfinityList>
    </div>
  );
};

type ToogleValue = 'my' | 'course';

type ListHeaderProps = {
  onSearch: (search: string) => void;
  toggleValue: ToogleValue[];
  onToggle?: (toggleValue: ToogleValue[]) => void;
  isLoading?: boolean;
};
const ListHeader = ({
  onSearch,
  toggleValue,
  onToggle,
  isLoading,
}: ListHeaderProps) => {
  const [search, setSearch] = useState('');
  const { slugCourseName } = useParams();
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalBank.filters',
  });
  const toggleButtons: ToggleButton<ToogleValue>[] = [
    { value: 'my', text: t('my') },
  ];

  if (slugCourseName) {
    toggleButtons.push({ value: 'course', text: t('course') });
  }

  return (
    <div className="flex gap-4 w-full justify-between flex-gap">
      <div className="flex gap-3 w-full flex-1 items-center">
        <TextInput
          placeholder={t('search')}
          icon={<SearchIcon />}
          className={{ base: 'flex-1 max-w-[400px] z-10' }}
          value={search}
          onChange={e => {
            setSearch(e.target.value);
            onSearch(e.target.value);
          }}
        />
        <ConditionalRenderer condition={isLoading}>
          <motion.div {...animMerge(fadeIn, slideHorizontal())}>
            <LoadingIcon className="w-6 text-primary z-0" />
          </motion.div>
        </ConditionalRenderer>
      </div>
      <ToggleButtonGroup
        buttons={toggleButtons}
        value={toggleValue}
        onChange={onToggle}
      />
    </div>
  );
};
