import Text from '@/components/common/dataDisplay/Text';
import AboutKlass from './AboutKlass';
import KlassesStudentInfo from './KlassesStudentInfo';
import { useTranslation } from 'react-i18next';
import Enrollment from '@/models/Enrollment';
import useStatisticService from '@/data/hook/useStatistics';
import attendancePercentage from '@/utils/statistics/attendencePercentage';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { LoadingIcon } from '@/components/icons';
import { scheduledLessonStatisticsQueryKeys } from '@/data/services/querykeys';
import Klass from '@/models/Klass';

interface KlassesInfoColProps {
  klassProgress: number;
  klass: Klass;
  enrollments: Enrollment[];
  isLoading?: boolean;
  isOnlyActive: boolean;
}

export default function KlassesInfoCol(props: KlassesInfoColProps) {
  const { enrollments, isLoading, klassProgress, klass, isOnlyActive } = props;

  const { t } = useTranslation('translation', {
    keyPrefix: 'klassProgressPage',
  });

  const {
    statistic: lessonStatistic,
    isInitialLoading: isLoadingCourseStatistic,
  } = useStatisticService(
    scheduledLessonStatisticsQueryKeys.list({ klassId: klass.id }),
  );

  return (
    <div
      data-testid="klassesInfoCol"
      className="p-3.5 border w-fit bg-base-100 rounded-2xl border-neutral-content"
    >
      <Text
        format="rubik-500"
        className="text-primary min-h-[5.25rem] pt-3.5 pb-9 w-full border-b border-neutral-content"
        text={`${t('students')} (${enrollments.length || 0})`}
      />
      <ConditionalRenderer
        condition={!isLoading}
        fallback={
          <div className="h-14 py-4 flex justify-center shrink-0 border-b border-neutral-content">
            <LoadingIcon className="w-6 flex text-primary h-6" />
          </div>
        }
      >
        <ConditionalRenderer
          condition={!!enrollments.length}
          fallback={
            <Text
              className="h-14 flex items-center align-middle border-b border-neutral-content"
              text={
                isOnlyActive ? t('noActiveStudent') : t('noInactiveStudent')
              }
            />
          }
        >
          {enrollments
            .sort((a, b) => a.student.id - b.student.id)
            .map(enrollment => {
              return (
                <KlassesStudentInfo
                  key={enrollment.id}
                  klass={klass}
                  enrollment={enrollment}
                />
              );
            })}
        </ConditionalRenderer>
      </ConditionalRenderer>

      <AboutKlass
        isLoading={isLoading || isLoadingCourseStatistic}
        klassLessonCount={lessonStatistic?.lessonsCount || 0}
        klassMissedCount={lessonStatistic?.absencesCount || 0}
        klassProgressAvg={Math.round(klassProgress * 100)}
        klassAttendancePercentage={attendancePercentage({
          presences: lessonStatistic?.presencesCount || 0,
          totalReports:
            (lessonStatistic?.presencesCount || 0) +
            (lessonStatistic?.absencesCount || 0),
        })}
      />
    </div>
  );
}
