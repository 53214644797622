import { adapterCardFiltersToFiltersService } from '@/adapters/alertsAdapters';
import { ROUTES } from '@/utils/routes';
import {
  ArrowsExpandIcon,
  BellIcon,
  RefreshIcon,
} from '@heroicons/react/outline';
import { ComponentProps, forwardRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import MainButton from '../../common/buttons/MainButton';
import SimpleTextIcon from '../../common/SimpleTextIcon';
import NotificationFilters from './NotificationFilters';
import NotificationsList from './NotificationsList';
import { NotificationFilterFields } from './NotificationTypes';
import { useQueryClient } from '@tanstack/react-query';
import IconButton from '@/components/common/buttons/IconButton';
import { alertQueryKeys } from '@/data/services/querykeys';


const Notifications = forwardRef<
  HTMLDivElement,
  Pick<ComponentProps<'div'>, 'className'>
>(({ className }, ref) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'alerts',
  });
  const navigate = useNavigate();
  const [filters, setFilters] = useState<NotificationFilterFields>({
    sortBy: 'desc',
    status: 'all',
    types: [],
    onlyPendings: false,
  });
  const onFilter = (fields: NotificationFilterFields) => setFilters(fields);
  const queryClient = useQueryClient();

  const refreshNotifications = useCallback(() => {
    const queryKeys = alertQueryKeys.list._def;
    queryClient.invalidateQueries(queryKeys);
  }, [queryClient]);

  return (
    <div
      className={twMerge(
        'relative flex flex-col gap-2.5 border border-neutral-content rounded-xl py-2.5 px-4 w-full',
        className,
      )}
      ref={ref}
    >
      <nav className="flex justify-between items-center">
        <SimpleTextIcon
          icon={<BellIcon className="h-6 w-6" />}
          title={t('title')}
          className={{ text: 'text-accent' }}
          tooltip
        />
        <div className="flex gap-4 items-center">
           <IconButton
            icon={<RefreshIcon className="w-5 text-accent" />}
            onClick={refreshNotifications}
          />
          <MainButton
            color="custom"
            icon={<ArrowsExpandIcon className="text-primary" />}
            onClick={() => navigate(ROUTES.NOTIFICATIONS.BASE)}
            text={t('buttons.fullScreen')}
            className="flex-row-reverse h-fit items-center gap-1.5"
          />
        </div>
      </nav>
      <NotificationFilters onFilter={onFilter} filters={filters} />
      <NotificationsList
        filters={adapterCardFiltersToFiltersService(filters)}
      />
    </div>
  );
});

export default Notifications;
