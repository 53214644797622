import React from 'react';
import { Alert, AlertTypeEnum } from '@/models/Alert';
import DelayNotificationCard from './DelayNotificationCard';
import AbsenceNotificationCard from './AbsenceNotificationCard';
import { ListAlertsFilters } from '@/data/services/alertServices';
import HomeworkNotDoneNotificationCard from './HomeworkNotDoneNotificationCard';

type NotificationFactoryProps = {
  notification: Alert;
  filters?: ListAlertsFilters;
};

const NotificationFactory: React.FC<NotificationFactoryProps> = ({
  notification,
  filters = {},
}) => {
  switch (notification.type) {
    case AlertTypeEnum.ABSENCE:
      return (
        <AbsenceNotificationCard
          filters={filters}
          notification={notification}
        />
      );
    case AlertTypeEnum.LATENESS:
      return (
        <DelayNotificationCard filters={filters} notification={notification} />
      );
    case AlertTypeEnum.HOMEWORK_NOT_DONE:
      return (
        <HomeworkNotDoneNotificationCard
          filters={filters}
          notification={notification}
        />
      );
    default:
      return null;
  }
};

export default NotificationFactory;
