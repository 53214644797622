import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import MainButton from '@/components/common/buttons/MainButton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { HtmlPreview } from '@/components/common/dataDisplay/HtmlPreview';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import ModalDisable from '@/components/common/modals/ModalDisable';
import useCourseEditing from '@/data/hook/useCourseEditing';
import { deleteBook } from '@/data/services/bookServices';
import { updateLesson } from '@/data/services/lessonServices';
import chapterFormatter from '@/functions/chapterFormatter';
import { isPulished } from '@/functions/handleCourseStatusIcon';
import Book, { bookType } from '@/models/Book';
import Chapter from '@/models/Chapter';
import CoursePath from '@/models/Course';
import { ApiError } from '@/models/Errors';
import { Lesson } from '@/models/Lesson';
import { bookEndpoints } from '@/utils/setBookType';
import { anOldChapter, stringToStringArray } from '@/utils/stringToStringArray';
import alert from '@/utils/UseAlert';
import { Chapters } from './EditMaterialPage/Chapters';
import { onEditBookFn } from './Material.hooks';

interface ViewMaterialProps {
  book: Book;
  course?: CoursePath;
  lesson: Lesson;
  readOnly?: boolean;
  bookType: bookType;
  updateLessons?: () => Promise<void>;
  onEditBook: onEditBookFn;
  isEditing?: boolean;
}

export default function ViewMaterial({
  book,
  course,
  lesson,
  bookType,
  updateLessons,
  onEditBook,
  isEditing: isLoadingEditingBook,
  readOnly,
}: ViewMaterialProps) {
  const navigate = useNavigate();

  const { allowBigChanges, setMaterialMode, setBook, handleLessonTab } =
    useCourseEditing();
  const [chapterIndex, setChapterIndex] = useState(0);
  const endpoint = bookEndpoints[bookType];
  const getBaseUrl = (lessonId: number) =>
    `courses/${course?.slug}/lessons/${lessonId}/${endpoint}`;
  const [visibleModal, setVisibleModal] = useState(false);

  const handleAfterEdit = (book: Book, lesson: Lesson) => {
    if (chapterIndex > 0) {
      const findChapter = book.chapters.find(
        (_chapter, index) => index === chapterIndex,
      );

      if (findChapter) {
        navigate(
          `/admin/${getBaseUrl(lesson.id)}/${book.id}/chapter/${
            findChapter.id
          }`,
        );
      }
    } else {
      navigate(`/admin/${getBaseUrl(lesson.id)}/${book.id}`);
    }
  };

  const handleEditingBook = async () => {
    const { book, lesson } = await onEditBook();
    handleAfterEdit(book, lesson);
  };
  const { t } = useTranslation('translation', {
    keyPrefix: 'editMaterialPage',
  });

  const { isLoading: isLoadingDeleteBook, mutate: onDeleteBook } = useMutation(
    deleteBook,
    {
      onSuccess() {
        handleLessonTab({
          previousActiveLessonIndex: null,
          activeLessonIndex: null,
          activeTab: 'details',
        });
        setMaterialMode('default');
        setBook(null);
        alert.success(t('deleted'));
        updateLessons?.();
      },
      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const requestDeleteBook = async () => {
    const slugCourseName = course?.slug;
    let currentLesson = lesson;
    if (isPulished(lesson.status) && slugCourseName) {
      const editedLesson = await updateLesson(
        {
          lessonId: currentLesson.id,
          slugCourseName,
        },
        {},
      );
      currentLesson = editedLesson;
    }
    const lessonId = currentLesson.id;
    const bookId =
      bookType === 'STUDENT' ? currentLesson.book : currentLesson.teacherBook;

    if (!bookId) {
      throw new Error('Book invalid');
    }
    onDeleteBook({ bookType, lessonId, bookId });
  };

  const chapter = book.chapters.length
    ? book.chapters[chapterIndex]
    : undefined;

  const content = (chapter?: Chapter) => {
    if (chapter) {
      if (anOldChapter(chapter))
        return chapterFormatter(
          chapter.title,
          stringToStringArray(chapter.content),
        );
      return chapter.content;
    }
  };

  return (
    <div className="flex flex-col gap-5">
      <ModalDisable
        testId="deleteBookModal"
        modalType="delete"
        visible={visibleModal}
        onClickConfirm={requestDeleteBook}
        onClickCancel={() => setVisibleModal(false)}
        translationString="modalRemoveBook"
        isRequesting={isLoadingDeleteBook}
      />
      <div className="flex gap-3.5">
        <div className="w-[70%]">
          <HtmlPreview
            className="select-none"
            html={content(chapter) ?? ''}
            format="book"
          />
        </div>

        <Chapters
          courseSlug={course?.slug ?? ''}
          lessonId={lesson.id}
          book={book}
          chapterIndex={chapterIndex}
          setChapterIndex={setChapterIndex}
          chapters={book.chapters}
        />
      </div>
      <ConditionalRenderer condition={course && !readOnly}>
        <div className="flex justify-between gap-4">
          <MainButton
            loading={isLoadingEditingBook}
            text={t('editMaterial')}
            icon={<PencilIcon />}
            onClick={handleEditingBook}
          />
          <Tooltip
            text={!allowBigChanges ? t('cantModify') : null}
            className="w-fit"
          >
            <MainButton
              text={t('deleteMaterial')}
              icon={<TrashIcon />}
              onClick={() => setVisibleModal(true)}
              color="warning"
              disabled={!allowBigChanges}
            />
          </Tooltip>
        </div>
      </ConditionalRenderer>
    </div>
  );
}
