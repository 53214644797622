import { ArrowRightIcon, CalendarIcon, XIcon } from '@heroicons/react/outline';
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import React, { MouseEventHandler, useState } from 'react';
import { DateRange, SelectRangeEventHandler } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import IconButton from '../buttons/IconButton';
import ConditionalRenderer from '../ConditionalRenderer';
import Text from '../dataDisplay/Text';
import { Tooltip } from '../dataDisplay/Tooltip';
import { LocaleDayPicker } from './LocaleDayPicker';

interface DateRangeInputProps {
  range?: DateRange;
  onChange?: SelectRangeEventHandler;
}

function DateRangeInput({ onChange, range }: DateRangeInputProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('translation', {
    keyPrefix: 'common.dateRangeInput',
  });

  const hasSelectDates = range?.from && range?.to;
  const cancelSelectDate: MouseEventHandler<HTMLButtonElement> = e => {
    onChange?.({ from: new Date() }, new Date(), {}, e);
  };
  return (
    <div className="flex transition-all ease-in-out duration-150 items-center gap-5 relative px-2 py-1 border border-primary rounded-md">
      <div
        onClick={() => setIsOpen(prev => !prev)}
        className="flex cursor-pointer items-center gap-5 relative "
      >
        <div className="flex items-center gap-3 text-base-content">
          <ConditionalRenderer
            fallback={<Text text={t('selectDate')} />}
            condition={hasSelectDates}
          >
            <React.Fragment>
              <Text text={moment(range?.from).format('DD/MM/YYYY')} />
              <ArrowRightIcon className="w-5 text-primary" />
              <Text text={moment(range?.to).format('DD/MM/YYYY')} />
            </React.Fragment>
          </ConditionalRenderer>
        </div>
        <div className="flex gap-2 items-center">
          <ConditionalRenderer condition={hasSelectDates}>
            <Tooltip text={t('cancelSelect')}>
              <IconButton
                icon={<XIcon className="text-error w-4" />}
                onClick={cancelSelectDate}
              />
            </Tooltip>
          </ConditionalRenderer>
          <CalendarIcon
            onMouseEnter={() => setIsOpen(true)}
            className="w-6 text-primary"
          />
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            onMouseLeave={() => setIsOpen(false)}
            initial={{ opacity: 0, top: '-2rem' }}
            animate={{ opacity: 1, top: '20%' }}
            exit={{ opacity: 0, top: '1rem' }}
            className="absolute rounded-md left-full bg-base-100 z-20"
          >
            <LocaleDayPicker
              mode="range"
              selected={range}
              onSelect={onChange}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default DateRangeInput;
