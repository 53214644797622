import MainButton from '@/components/common/buttons/MainButton';
import ToggleButtonGroup, {
  onChangeToggle,
  ToggleButton,
} from '@/components/common/dataInput/ToggleGroup';
import { Section } from '@/models/Book';
import { LayoutChapter, Layouts } from '@/models/ChapterLayout';
import { SaveIcon } from '@heroicons/react/outline';
import React, { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import ConditionalRenderer from '../../../../ConditionalRenderer';
import NumberInput from '../../../../dataInput/NumberInput';
import FlexLayout from './FlexLayout';
import GridCustomLayout from './GridCustomLayout';
import {
  fixedLayouts,
  mountLayoutSystem,
} from '@/utils/material/chapter/chapterLayout';

export type SectionsListProps = {
  sections: Section[];
};

type ChapterLayoutFormProps = {
  sections: Section[];
  layout: LayoutChapter;
  onChange: (layout: LayoutChapter) => void;
  onFinish: () => void;
};
const ChapterLayoutForm: React.FC<ChapterLayoutFormProps> = ({
  sections,
  layout,
  onChange,
  onFinish,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chapter.layout',
  });
  const handleNumberCols: ChangeEventHandler<HTMLInputElement> = e => {
    const cols = Number(e.target.value);
    onChange({
      type: 'custom',
      system: mountLayoutSystem(sections, cols),
      cols,
    });
  };

  const statusOptions: ToggleButton<Layouts>[] = [
    { text: t('layout1'), value: 'layout1' },
    { text: t('layout2'), value: 'layout2' },
    { text: t('layout3'), value: 'layout3' },
    { text: t('custom'), value: 'custom' },
  ];

  const onChangeLayoutType: onChangeToggle<Layouts> = type => {
    if (type === 'custom')
      onChange({
        type: 'custom',
        system: mountLayoutSystem(sections, 3),
        cols: 3,
      });
    else onChange({ type });
  };

  return (
    <div className="w-full flex flex-col gap-2.5">
      <div className="flex justify-between items-center gap-2 flex-wrap w-full">
        <div className="flex gap-2">
          <ToggleButtonGroup
            exclusive
            size="medium"
            className="gap-2"
            buttons={statusOptions}
            value={layout.type}
            onChange={onChangeLayoutType}
          />

          {layout.type === 'custom' && (
            <NumberInput
              label="Colunas"
              labelPosition="left"
              className={{ label: 'w-fit', base: 'w-fit ml-2 gap-1.5' }}
              value={layout.cols}
              onChange={handleNumberCols}
              showIncButton
              showDecButton
              disableTextChange
              min={1}
              max={4}
            />
          )}
        </div>
        <MainButton
          type="button"
          text={t('finish')}
          onClick={onFinish}
          className="font-500 gap-1.5"
          icon={<SaveIcon />}
        />
      </div>
      {layout.type === 'custom' && (
        <GridCustomLayout
          sections={sections}
          layout={layout}
          onChange={onChange}
        />
      )}
      <ConditionalRenderer condition={layout.type !== 'custom'}>
        <FlexLayout sections={sections} cols={fixedLayouts[layout.type]} />
      </ConditionalRenderer>
    </div>
  );
};

export default ChapterLayoutForm;
