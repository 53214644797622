import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { InformationCircleIcon, UsersIcon } from '@heroicons/react/outline';

import Klass from '@/models/Klass';
import Popup from '@/components/common/Popup';
import MainButton from '@/components/common/buttons/MainButton';
import { ROUTES } from '@/utils/routes';
import Text from '@/components/common/dataDisplay/Text';
import KlassPopupActions from './KlassPopupActions';
import { TeachingWeekdayProps } from '../TeachingWeek/TeachingWeekday';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { useActiveStudents } from '@/components/scheduledLessonReport/StudentReports.hook';
import { handleUserFullName } from '@/functions/handleUserFullName';
import { OptionItem } from '@/models/OptionItem';
import EnrollmentView from '../EnrollmentView';
import InfinityList from '@/components/common/InfinityList';
import TextWithCopy from '@/components/common/dataDisplay/TextWithCopy';
import { LoadingIcon } from '@/components/icons';
import { getErrorMessage } from '@/utils/getErrorMessage';
import AvatarClassroom from '@/components/klass/AvatarClassroom/AvatarClassroom';
import useToggle from '@/data/hook/useToggle';

export type KlassPopupProps = Partial<TeachingWeekdayProps> & {
  klass: Klass;
};

export default function KlassPopup(props: KlassPopupProps) {
  const {
    name,
    klassStartDate,
    klassEndDate,
    id: klassId,
    enrollsCount,
  } = props.klass;

  const { onChangeModal } = props;
  const { isOpen, close, open, toggle } = useToggle();

  const { t: tKlassPopup } = useTranslation('translation', {
    keyPrefix: 'klassPopup',
  });

  const [t] = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const to = ROUTES.ADMIN.CLASSES.INFO({
    name: props.klass.name.replace('#', ''),
  });

  const startDate = moment(klassStartDate).format('DD/MM/YYYY');

  const endDate = moment(klassEndDate).format('DD/MM/YYYY');

  const period = `${startDate}-${endDate}`;

  const {
    enrollments,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isInitialLoading: loading,
    error,
  } = useActiveStudents({
    klassId,
    enabled: isOpen,
  });

  const reduceNamesEmails = enrollments.reduce(
    (acc: { names: string[]; emails: string[] }, { student }) => {
      acc.names.push(handleUserFullName(student));
      acc.emails.push(student.email);
      return acc;
    },
    { names: [], emails: [] },
  );

  const studentsNamesEmails = {
    names: reduceNamesEmails.names.join('\n'),
    emails: reduceNamesEmails.emails.join('\n'),
  };

  const copyOptions: OptionItem[] = [
    {
      id: 'copyNames',
      text: tKlassPopup('copyNames'),
      target: studentsNamesEmails.names,
    },
    {
      id: 'copyEmails',
      text: tKlassPopup('copyEmails'),
      target: studentsNamesEmails.emails,
    },
  ];

  return (
    <Popup
      opened={isOpen}
      onToggle={toggle}
      onOpen={open}
      onClose={close}
      className={{
        popup: 'max-w-[56rem] w-[calc(100vw-5rem)]',
      }}
      reference={
        <MainButton
          color="custom"
          className="uppercase font-700 gap-0.5"
          text={tKlassPopup('seeMore')}
          icon={<InformationCircleIcon className="w-3.5" strokeWidth={3} />}
        />
      }
    >
      <section className="flex flex-col bg-base-100 rounded-xl border-neutral-content border shadow-default p-3.5 gap-3.5 w-full">
        <div className="flex flex-wrap gap-3.5 justify-between">
          <Text
            format="rubik-500"
            text={name}
            href={to}
            className="hyphens-auto"
          />
          <Text
            format="rubik-400"
            text={period}
            className="text-base-content text-14"
          />
        </div>
        <div className="flex  gap-3.5">
          <div className="flex gap-3.5 flex-col justify-between">
            <KlassPopupActions {...props} />
            <ConditionalRenderer condition={enrollsCount > 0}>
              <div className="flex items-center gap-3.5 text-14 lg:text-16">
                <div className="flex gap-0.5 items-center justify-center text-center">
                  <Text
                    format="rubik-500"
                    className="uppercase text-14 text-center leading-none h-3 shrink-0"
                    text={t('students', { count: enrollsCount })}
                  />
                  <span className="w-3.5">
                    <UsersIcon className="w-3.5" strokeWidth={3} />
                  </span>
                </div>
                <ul className="text-base-content flex flex-wrap gap-2">
                  {copyOptions.map(copyOption => (
                    <li
                      className="gap-1.5 flex items-center"
                      key={copyOption.id}
                    >
                      <TextWithCopy
                        label={copyOption.text}
                        target={copyOption.target}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </ConditionalRenderer>
          </div>
          <AvatarClassroom
            {...props}
            className={{
              base: 'rounded-t-2xl w-80 hidden sm:flex',
              img: 'rounded-t-2xl',
            }}
          />
        </div>

        <InfinityList
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          onReachEnd={fetchNextPage}
          className="gap-2 max-h-[15rem] overflow-x-hidden"
          scroll
        >
          <ConditionalRenderer condition={loading}>
            <div className="flex w-full justify-center">
              <LoadingIcon className="text-primary/40 w-10" />
            </div>
          </ConditionalRenderer>

          <ConditionalRenderer condition={error}>
            <Text text={getErrorMessage(error)} />
          </ConditionalRenderer>

          <ConditionalRenderer
            condition={!enrollments.length && !loading && !error}
          >
            <Text
              text={`${t('noResults')} ¯\\_(ツ)_/¯`}
              className="overflow-hidden self-center text-primary"
            />
          </ConditionalRenderer>

          <ConditionalRenderer condition={enrollments.length}>
            {enrollments.map((enrollment, index) => (
              <EnrollmentView
                key={enrollment.id}
                index={index}
                enrollment={enrollment}
                onChangeModal={onChangeModal}
                klass={props.klass}
              />
            ))}
          </ConditionalRenderer>
        </InfinityList>
      </section>
    </Popup>
  );
}
