import useTheme from '@/data/hook/useTheme';
import { ApexOptions } from 'apexcharts';
import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

type ChartData = {
  label: string;
  value: number;
};

type ChartOptions = Partial<{
  yaxis: { show?: boolean; showLabels?: boolean };
  stroke: { width: number };
  height: string | number;
  width: string | number;
  dataLabels: boolean;
  seriesName: string;
}>;

type AreaChartProps = {
  data: ChartData[];
  options?: ChartOptions;
};

export default function AreaChart({
  data,
  options: customOptions,
}: AreaChartProps) {
  const { activeTheme, themeName: theme } = useTheme();

  const grid = {
    show: false,
    padding: { bottom: 0, left: 0, right: 0, top: 0 },
  };
  const [options] = useState<ApexOptions>({
    chart: {
      toolbar: { show: false },
      offsetY: 10,
    },
    xaxis: {
      categories: data.map(({ label }) => label),
      labels: { show: false, maxHeight: 0 },
      floating: false,
      axisBorder: { show: false },
      type: 'category',
    },
    dataLabels: {
      enabled: !!customOptions?.dataLabels,
    },
    yaxis: {
      show: !!customOptions?.yaxis?.show,
      labels: { show: !!customOptions?.yaxis?.showLabels },
    },
    grid,
    stroke: {
      curve: 'smooth',
      width: customOptions?.stroke?.width || 1,
    },
    tooltip: {
      theme,
    },
  });
  const series: ApexAxisChartSeries | ApexNonAxisChartSeries = [
    {
      data: data.map(({ value }) => value),
      color: activeTheme.secondary,
      name: customOptions?.seriesName,
    },
  ];

  return (
    <ReactApexChart
      type="area"
      series={series}
      options={options}
      height={customOptions?.height || '100%'}
      width={customOptions?.width || '100%'}
    />
  );
}
