import { handleUserFullName } from '@/functions/handleUserFullName';
import Text from './Text';
import User from '@/models/User';
import { getAge } from '@/utils/profile';
import ConditionalRenderer from '../ConditionalRenderer';
import { useTranslation } from 'react-i18next';
import { ClassNameComponent } from '@/utils/ClassNameComponent';
import { twMerge } from 'tailwind-merge';

type NameAgeProps = {
  user: User;
  testId?: string;
  className?: Pick<ClassNameComponent, 'base' | 'text'> & { age?: string };
};

export default function NameAge({ user, className, testId }: NameAgeProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageStudent',
  });
  const birthDate = user.profile.dateOfBirth;
  const age = birthDate ? getAge(birthDate) : 0;
  return (
    <div className={className?.base}>
      <Text
        text={handleUserFullName(user)}
        testId={testId}
        className={twMerge('pr-2', className?.text)}
      />
      <ConditionalRenderer condition={age}>
        <Text
          text={t('age', {
            count: age,
          })}
          size="text-14"
          className={twMerge(
            'relative text-neutral inline-block top-0.5',
            className?.age,
          )}
        />
      </ConditionalRenderer>
    </div>
  );
}
