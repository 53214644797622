import { OutputData, OutputBlockData } from '@editorjs/editorjs';

type Transformer = (block: OutputBlockData) => OutputBlockData;

export const anchorToTargetBlankTransformer: Transformer = block => {
  if (block.type === 'paragraph') {
    block.data.text = block.data.text.replace(
      /<a /g,
      '<a target="_blank" rel="noopener noreferrer" ',
    );
  }
  return block;
};

const outputDataTransformer = (data: OutputData) => {
  const transformers: Transformer[] = [anchorToTargetBlankTransformer];
  data.blocks = data.blocks.map(block => {
    return transformers.reduce((acc, transformer) => transformer(acc), block);
  });
  return data;
};

export default outputDataTransformer;
