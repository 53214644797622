import { twJoin } from 'tailwind-merge';

type ActivityAttemptButtonProps = {
  text: string;
  onClick?(): void;
};

export default function ActivityAttemptButton(
  props: ActivityAttemptButtonProps,
) {
  const { text, onClick } = props;
  return (
    <button
      aria-label={text}
      type="button"
      className={twJoin(
        'bg-secondary-content text-secondary h-fit text-[0.75rem] md:text-[0.875rem] px-2.5 py-0.5 rounded-lg uppercase whitespace-nowrap',
        onClick ? 'cursor-pointer' : 'cursor-default',
      )}
      onClick={onClick}
    >
      {text}
    </button>
  );
}
