import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Text from '@/components/common/dataDisplay/Text';
import InfinityList from '@/components/common/InfinityList';
import Enrollment from '@/models/Enrollment';
import Klass from '@/models/Klass';
import ScheduledLesson from '@/models/ScheduledLesson';
import { getErrorMessage } from '@/utils/getErrorMessage';
import ModalUpdates from '../common/modals/ModalUpdates';
import { LoadingIcon } from '../icons';
import { StudentReport } from './StudentReport';
import { useActiveStudents } from './StudentReports.hook';
import useListService from '@/data/hook/useListService';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import { vacationNoticeQueryKeys } from '@/data/services/querykeys';

export type StudentReportPermissions = {
  hasPresencePermission: boolean;
  hasReportsPermission: boolean;
};

interface StudentReportsProps {
  klass: Klass;
  handleEnrollment(enrollment: Enrollment): void;
  homeworkId?: number;
  permissions: StudentReportPermissions;
  scheduledLesson: ScheduledLesson;
  editingAllowed?: boolean;
}

export function StudentReports(props: StudentReportsProps) {
  const { klass, scheduledLesson } = props;
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });

  const { t: tUpdates } = useTranslation('translation', {
    keyPrefix: 'updates',
  });

  const [status, setStatus] = useState(true);

  const {
    enrollments,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isInitialLoading: loadingEnrollments,
    error: enrollmentsError,
  } = useActiveStudents({
    active: status,
    klassId: klass.id,
    scheduledLesson,
  });

  const userId = enrollments.map(({ student }) => student.id);

  const { results: vacationNotices, isInitialLoading: loadingVacationNitices } =
    useListService({
      staleTime: REQUEST_STALE_TIME_IN_MS,
      enabled: !!enrollments.length,
      ...vacationNoticeQueryKeys.list({
        userId: userId,
        date: moment(scheduledLesson.datetime).format('YYYY-MM-DD'),
      }),
    });

  return (
    <div
      data-testid="studentReports"
      className="flex flex-col p-5 shadow-default rounded-xl bg-base-100 gap-4 max-h-full"
    >
      <StatusToggle handleChecked={setStatus} />
      <div data-testid="studentsPreview" className="flex flex-col gap-4">
        <InfinityList
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          onReachEnd={fetchNextPage}
          className="min-h-40 max-h-96 flex flex-col gap-4 "
          scroll
        >
          <ConditionalRenderer condition={loadingEnrollments}>
            <div className="flex items-center justify-center grow">
              <LoadingIcon className="w-20 text-primary-content" />
            </div>
          </ConditionalRenderer>

          <ConditionalRenderer condition={enrollmentsError}>
            <Text
              text={getErrorMessage(enrollmentsError)}
              format="rubik-400"
              className="text-primary"
              size="text-14"
            />
          </ConditionalRenderer>

          <ConditionalRenderer
            condition={
              !enrollments.length &&
              !loadingEnrollments &&
              !loadingVacationNitices
            }
            fallback={
              <Fragment>
                {enrollments.map((enrollment, index) => {
                  const vacationNotice = vacationNotices.find(
                    vacation => vacation.userId === enrollment.student.id,
                  );

                  return (
                    <StudentReport
                      key={enrollment.id}
                      {...props}
                      enrollment={enrollment}
                      setButtonRef={index === 0 ? setRef : undefined}
                      vacationNotice={vacationNotice}
                    />
                  );
                })}
              </Fragment>
            }
          >
            <Text text={t('noStudents')} />
          </ConditionalRenderer>
        </InfinityList>
      </div>
      <ConditionalRenderer condition={!!ref}>
        <ModalUpdates
          placement="right-start"
          keyword="notifyLatelessButton"
          target={ref}
        >
          <div className="flex flex-col justify-center items-center gap-4">
            <img
              className="max-w-80 self-center"
              src="/images/notifyLateless.png"
              alt={t('notifyLatelessButton.description')}
            />
            <Text
              text={tUpdates('notifyLatelessButton.secondDescriptionState')}
            />
            <img
              className="max-w-80"
              src="/images/notifyLateless2.png"
              alt={t('notifyLatelessButton.description')}
            />
            <Text
              text={tUpdates('notifyLatelessButton.lastDescriptionState')}
            />
            <img
              className="max-w-80"
              src="/images/notifyLateless3.png"
              alt={t('notifyLatelessButton.description')}
            />
          </div>
        </ModalUpdates>
      </ConditionalRenderer>
    </div>
  );
}

type StatusToggleProps = {
  defaultChecked?: boolean;
  handleChecked(checked: boolean): void;
};

function StatusToggle({
  defaultChecked = true,
  handleChecked,
}: StatusToggleProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });

  const [checked, setChecked] = useState(defaultChecked);

  function onChangeChecked(checked: boolean) {
    setChecked(checked);
    handleChecked(checked);
  }

  return (
    <div className="flex gap-2 items-center">
      <Text
        testId="studentsTitle"
        text={t('students')}
        format="rubik-500"
        className="text-center text-primary w-fit flex"
      />
      <input
        type="checkbox"
        defaultChecked={checked}
        onChange={({ target }) => onChangeChecked(target.checked)}
        className="toggle toggle-sm toggle-primary mx-2"
      />
      <Text text={checked ? t('active') : t('inactive')} className="flex" />
    </div>
  );
}
