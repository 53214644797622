import useMutationObserver, {
  CustomMutationCallback,
} from '@/data/hook/useMutationObverser';
import { Section } from '@/models/Book';
import { scrollBarYClassName } from '@/utils/scrollBarClassName';
import { useCallback, useEffect, useState } from 'react';
import GridLayout from 'react-grid-layout';
import { twMerge } from 'tailwind-merge';
import SectionView from '../../sections/view/SectionView';
import { GRID_ROW_HEIGHT } from '@/constants';
import {
  findLayoutItem,
  mountKey,
  mountLayout,
} from '@/utils/material/chapter/chapterLayout';
import { LayoutChapter } from '@/models/ChapterLayout';

export type GridCustomLayoutProps = {
  sections: Section[];
  layout: LayoutChapter & {
    type: 'custom';
  };
  readOnly?: boolean;
  onChange?: (layout: LayoutChapter) => void;
};

export default function GridCustomLayout({
  sections,
  layout,
  readOnly,
  onChange,
}: GridCustomLayoutProps) {
  const layoutSystem = layout.system;

  const [gridWidth, setGridWidth] = useState(1350);

  const cbSetWidth: CustomMutationCallback<HTMLDivElement> = useCallback(
    (_ref, _obj, ref) => {
      if (ref) {
        setGridWidth(ref.offsetWidth);
      }
    },
    [],
  );

  const { ref } = useMutationObserver<HTMLDivElement>(cbSetWidth, {
    attributes: true,
    subtree: true,
  });

  useEffect(() => {
    if (ref.current) {
      setGridWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  return (
    <div ref={ref} className="w-full ">
      <GridLayout
        className="w-full"
        layout={layoutSystem}
        onLayoutChange={layoutSystem =>
          onChange?.({
            ...layout,
            system: layoutSystem,
          })
        }
        cols={layout.cols}
        margin={[0, 0]}
        useCSSTransforms={!readOnly}
        isResizable={!readOnly}
        isDraggable={!readOnly}
        resizeHandles={['se', 'sw', 'ne', 'nw']}
        rowHeight={GRID_ROW_HEIGHT}
        autoSize
        verticalCompact
        width={gridWidth}
      >
        {sections.map(section => {
          const definedLayout = findLayoutItem(
            mountKey(section),
            layout.system,
          );
          return (
            <div
              key={mountKey(section)}
              data-grid={
                !definedLayout
                  ? mountLayout(section, 0, layout.cols)
                  : undefined
              }
            >
              <LayoutItem section={section} readOnly={readOnly} />
            </div>
          );
        })}
      </GridLayout>
    </div>
  );
}

type LayoutItemProps = {
  section: Section;
  readOnly?: boolean;
};

const LayoutItem = ({ section, readOnly }: LayoutItemProps) => {
  return (
    <div className="h-full p-2">
      <SectionView
        section={section}
        className={{
          base: twMerge('w-full h-full z-10', scrollBarYClassName),
          main: !readOnly ? 'pointer-events-none' : '',
        }}
      />
    </div>
  );
};
