import { ListStudentTagScoreParams } from '@/data/services/studentTagScoreServices';
import { isStudent } from '@/functions/auth';
import User from '@/models/User';
import { useAreaScore } from '@/pages/skill/SkillPage.hooks';
import Card from '../common/Card';
import { useTranslation } from 'react-i18next';
import { IconWithText } from '../common/dataDisplay/IconWithText';
import { ChevronRightIcon } from '@heroicons/react/outline';
import RadarChart, { ChartData } from '../charts/RadarChart';
import ConditionalWrapper from '../common/ConditionalWrapper';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '@/utils/Breakpoint';
import Skeleton from '../common/Skeleton';
import { twJoin } from 'tailwind-merge';

type SkillsCardProps = {
  user: User;
  isCurrentUser?: boolean;
};

export default function SkillsCard({ user, isCurrentUser }: SkillsCardProps) {
  const filters: ListStudentTagScoreParams = {
    studentId: user.id,
    parentAreaOnly: true,
    latestOnly: true,
  };
  const isSm = useMediaQuery({
    minWidth: Breakpoints.sm,
    maxWidth: Breakpoints.md,
  });
  const isLg = useMediaQuery({
    minWidth: Breakpoints.lg,
    maxWidth: Breakpoints.xl,
  });

  const { areaScores, isLoadingAreas } = useAreaScore(
    filters,
    isStudent(user.userType),
  );
  const [t] = useTranslation('translation', {
    keyPrefix: 'profile',
  });
  const sizeClassName =
    'w-full md:w-full md:max-w-md h-72 shrink-1 min-h-[250px] md:h-72 sm:h-auto max-h-none overflow-hidden';
  const chartData = areaScores.map<ChartData>(({ area, tagScore }) => ({
    label: area.name,
    value: tagScore?.score || 0,
  }));

  if (isLoadingAreas) {
    return (
      <Skeleton className={`bg-primary-content rounded-lg ${sizeClassName}`} />
    );
  }

  if (!areaScores.length) {
    return null;
  }

  return (
    <ConditionalWrapper
      condition={!!isCurrentUser}
      wrapper={
        <Link
          to={'/skills'}
          className={`hover:opacity-75 active:scale-95 transition-all duration-200 ease-in-out ${sizeClassName}`}
        />
      }
    >
      <Card
        className={`p-2.5 sm:justify-center !text-accent items-center sm:items-stretch flex-col border md:w-full m-0 md:m-0 md:justify-start border-neutral/20 gap-2 ${sizeClassName}`}
      >
        <IconWithText
          icon={
            isCurrentUser ? (
              <ChevronRightIcon className="w-4 text-primary" />
            ) : undefined
          }
          text={t('skills')}
          className={{
            base: twJoin(
              'w-full justify-between',
              isCurrentUser && 'flex-row-reverse',
            ),
            text: 'font-rubik font-500 text-18',
          }}
        />
        <RadarChart
          data={chartData}
          chartOptions={{
            seriesName: 'xp',
            height: isSm || isLg ? 80 * 4 : 90 * 4,
            width: isSm || isLg ? '100%' : 380,
            offsetX: isSm ? 15 : isLg ? 20 : 28,
            offsetY: isSm ? -5 : isLg ? -38 : -65,
            label: { size: isLg || isSm ? '.5rem' : '.7rem' },
          }}
        />
      </Card>
    </ConditionalWrapper>
  );
}
