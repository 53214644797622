import { formatLessonName } from '@/functions/lessonsName';
import i18n from '@/i18n';
import { AlertTypeEnum } from '@/models/Alert';
import ScheduledLesson from '@/models/ScheduledLesson';
import moment from 'moment';

interface Message {
  createMessage(): string;
}

class MessageBuilder {
  private presentation: string = '';
  private body: string = '';
  private footer: string = '';
  private question: string = '';
  private link: string = '';

  withAppresentation({ sender }: { sender: string }): this {
    this.presentation = i18n.t('alerts.messages.presentation', {
      sender,
    });
    return this;
  }

  withLatenessBody({
    scheduledLesson,
    studentName,
  }: {
    scheduledLesson?: ScheduledLesson;
    studentName: string;
  }): this {
    if (!scheduledLesson) {
      this.body = i18n.t('alerts.messages.simpleBody', {
        studentName,
      });
      return this;
    }
    const lessonDate = moment(scheduledLesson.datetime).format('HH:mm');
    const lessonName = formatLessonName(
      scheduledLesson.lesson,
      scheduledLesson,
    );
    this.body = i18n.t('alerts.messages.latelessBody', {
      studentName,
      lessonName,
      lessonDate,
    });
    return this;
  }

  withAbsenceBody({
    scheduledLesson,
    studentName,
  }: {
    scheduledLesson?: ScheduledLesson;
    studentName: string;
  }): this {
    if (!scheduledLesson) return this;
    const lessonDate = moment(scheduledLesson.datetime).format('HH:mm');
    const lessonName = formatLessonName(
      scheduledLesson.lesson,
      scheduledLesson,
    );
    this.body = i18n.t('alerts.messages.absenceBody', {
      studentName,
      lessonName,
      lessonDate,
    });
    return this;
  }

  withNotDoneHomework({
    scheduledLesson,
    studentName,
  }: {
    scheduledLesson?: ScheduledLesson;
    studentName: string;
  }): this {
    if (!scheduledLesson) return this;
    const lessonName = formatLessonName(
      scheduledLesson.lesson,
      scheduledLesson,
    );
    this.body = i18n.t('alerts.messages.homeworkNotDoneBody', {
      studentName,
      lessonName,
    });
    return this;
  }

  withLink(link?: string) {
    if (link)
      this.link = i18n.t('alerts.messages.link', {
        link,
      });
    return this;
  }

  withQuestion(question?: string) {
    this.question = question || i18n.t('alerts.messages.question');
    return this;
  }

  withFooter(footer?: string): this {
    this.footer = footer || i18n.t('alerts.messages.footer');
    return this;
  }

  build(): string {
    return `${this.presentation}\n${this.body}\n${this.link}\n${this.question}\n${this.footer}`;
  }
}

class LatenessMessage implements Message {
  constructor(
    private sender: string,
    private recipient: string,
    private scheduledLesson?: ScheduledLesson,
  ) {}

  createMessage(): string {
    const builder = new MessageBuilder()
      .withAppresentation({ sender: this.sender })
      .withLatenessBody({
        scheduledLesson: this.scheduledLesson,
        studentName: this.recipient,
      })
      .withLink(this.scheduledLesson?.url)
      .withFooter();
    return builder.build();
  }
}

class AbsenceMessage implements Message {
  constructor(
    private sender: string,
    private recipient: string,
    private body?: ScheduledLesson,
  ) {}

  createMessage(): string {
    const builder = new MessageBuilder()
      .withAppresentation({ sender: this.sender })
      .withAbsenceBody({
        studentName: this.recipient,
        scheduledLesson: this.body,
      })
      .withFooter();
    return builder.build();
  }
}

class HomeworkNotDoneMessage implements Message {
  constructor(
    private sender: string,
    private recipient: string,
    private body?: ScheduledLesson,
  ) {}

  createMessage(): string {
    const builder = new MessageBuilder()
      .withAppresentation({ sender: this.sender })
      .withNotDoneHomework({
        studentName: this.recipient,
        scheduledLesson: this.body,
      })
      .withFooter();
    return builder.build();
  }
}

type CreateLatelessMessageArgs = {
  type: AlertTypeEnum.LATENESS;
  body: ScheduledLesson;
};

type CreateAbsenceMessageArgs = {
  type: AlertTypeEnum.ABSENCE;
  body: ScheduledLesson;
};

type CreateHomeworkNotDoneMessageArgs = {
  type: AlertTypeEnum.HOMEWORK_NOT_DONE;
  body: ScheduledLesson;
};

type CreateMessageArgs = {
  type: AlertTypeEnum;
  sender: string;
  recipient: string;
} & (
  | CreateLatelessMessageArgs
  | CreateAbsenceMessageArgs
  | CreateHomeworkNotDoneMessageArgs
);

export const createMessage = ({
  type,
  sender,
  recipient,
  body,
}: CreateMessageArgs): string => {
  let message: Message;

  if (type === AlertTypeEnum.LATENESS) {
    message = new LatenessMessage(sender, recipient, body);
  } else if (type === AlertTypeEnum.ABSENCE) {
    message = new AbsenceMessage(sender, recipient, body);
  } else if (type === AlertTypeEnum.HOMEWORK_NOT_DONE) {
    message = new HomeworkNotDoneMessage(sender, recipient, body);
  } else {
    throw new Error('Invalid alert type');
  }

  return message.createMessage();
};
