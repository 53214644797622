import { BaseFilters } from '@/models/Service';
import requester from '@/axios';
import HomeworkActivity from '@/models/HomeworkActivity';
import JsonApiResponse from '@/models/JsonApiResponse';

export const getHomeworkActivity = async (id: number) => {
  const { data: homeworkActivity } = await requester().get<HomeworkActivity>(
    `homework-activity/${id}/`,
  );
  return homeworkActivity;
};

export interface ListHomeworkActivities extends BaseFilters {
  activity?: number;
}

export const listHomeworkActivities = async (
  params: ListHomeworkActivities = {},
) => {
  const { data } = await requester().get<JsonApiResponse<HomeworkActivity>>(
    `homework-activity/`,
    { params },
  );

  return data;
};

type createHomeworkActivityType = Omit<HomeworkActivity, 'id' | 'activity'> & {
  activity: number;
};
export const createHomeworkActivity = async (
  body: createHomeworkActivityType,
) => {
  const { data: homeworkActivity } = await requester().post<HomeworkActivity>(
    `homework-activity/`,
    body,
  );
  return homeworkActivity;
};

export const updateHomeworkActivity = async (
  id: number,
  changes: Partial<HomeworkActivity>,
) => {
  const { data: homeworkActivity } = await requester().patch<HomeworkActivity>(
    `homework-activity/${id}/`,
    changes,
  );
  return homeworkActivity;
};
