import { GetBookParams } from '@/data/services/bookServices';
import {
  createChapter,
  deleteChapter,
  updateChapter,
} from '@/data/services/chapterServices';
import {
  createSection,
  updateSectionsOrder,
} from '@/data/services/sectionServices';
import { Section } from '@/models/Book';
import Chapter from '@/models/Chapter';
import { buildChangedObject } from '@/utils/buildChangedObject';
import { getErrorMessage } from '@/utils/getErrorMessage';
import alert from '@/utils/UseAlert';
import { useMutation } from '@tanstack/react-query';
import { debounce, isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import ChapterForm from './ChapterForm';

type ChapterServiceProps = {
  chapter: Chapter;
  isUnique: boolean;
  onSubmit: (chapter: Chapter) => void;
  onDelete?: (chapter: Chapter) => void;
} & GetBookParams;
const ChapterService = ({
  chapter,
  onSubmit,
  isUnique,
  onDelete,
  ...bookParams
}: ChapterServiceProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chapter.form.alerts',
  });

  const chapterParams = {
    ...bookParams,
    chapterId: chapter.id,
  };
  const handleCreate = async () => {
    return createChapter(bookParams);
  };

  const handleUpdate = async (changes: Partial<Chapter>) => {
    return updateChapter({ params: chapterParams, body: changes });
  };

  const handleEdit = async ({
    chapter: chapterChanged,
    validated,
  }: {
    chapter: Partial<Chapter>;
    validated: boolean;
  }) => {
    if (!validated) return;
    const changes = buildChangedObject(chapter, chapterChanged);
    return chapter.id ? handleUpdate(changes) : handleCreate();
  };
  const { mutate: onEdit } = useMutation(handleEdit, {
    onSuccess: chapter => {
      if (!chapter) return;
      onSubmit(chapter);
    },
    onError: error => {
      const errorMessage = getErrorMessage(error);
      alert.error(errorMessage);
    },
  });

  const handleAddSection = async () => {
    const sections = chapter.sections || [];
    const newSection = await createSection(
      { ...bookParams, chapterId: chapter.id },
      new Section({ order: sections.length + 1 }),
    );
    sections.push(newSection);
    onSubmit({ ...chapter, sections });
  };

  const handleDeleteChapter = async () => {
    if (isUnique) {
      alert.warning(t('deleteUnique'));
      return Promise.reject();
    }
    return deleteChapter({ ...bookParams, chapterId: chapter.id });
  };

  const { mutate: onDeleteChapter, isLoading: isDeletingChapter } = useMutation(
    handleDeleteChapter,
    {
      onSuccess: () => onDelete?.(chapter),
      onError: error => {
        if (isUnique) return;
        const errorMessage = getErrorMessage(error);
        alert.error(errorMessage);
      },
    },
  );

  const { mutate: onAddSection, isLoading: isAddingSection } = useMutation(
    handleAddSection,
    {
      onError: err => {
        const errMessage = getErrorMessage(err);
        alert.error(errMessage);
      },
    },
  );

  const handleDeleteSection = (deleteSection: Section) => {
    const sections = chapter.sections || [];
    const newSections = sections.filter(
      section => !isEqual({ ...section }, { ...deleteSection }),
    );
    onSubmit({ ...chapter, sections: newSections });
  };

  const handleUpdateSection = (updatedSection: Section) => {
    const sections = chapter.sections || [];
    const newSections = sections.map(section =>
      section.id === updatedSection.id ? updatedSection : section,
    );
    onSubmit({ ...chapter, sections: newSections });
  };

  const handleOnReorderSections = (sections: Section[]) => {
    updateSectionsOrder({
      params: { ...bookParams, chapterId: chapter.id },
      sections: sections.map(({ id }) => ({ id })),
    });
    onSubmit({ ...chapter, sections });
  };

  return (
    <ChapterForm
      chapter={chapter}
      onSubmit={debounce(onEdit, 500)}
      onDeleteSection={handleDeleteSection}
      onUpdateSection={handleUpdateSection}
      onAddSection={onAddSection}
      onDeleteChapter={onDeleteChapter}
      isDeletingChapter={isDeletingChapter}
      isAddingSection={isAddingSection}
      onReorderSections={handleOnReorderSections}
      {...bookParams}
    />
  );
};

export default ChapterService;
