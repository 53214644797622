import { Section } from '@/models/Book';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import IconButton from '../../../../buttons/IconButton';
import EditorJs from '../../../../dataInput/EditorJS';
import SectionTitle from './SectionTitle';
import SectionContainer from './SectionContainer';
import { ClassNameComponent } from '@/utils/ClassNameComponent';
import { twMerge } from 'tailwind-merge';
import { mountKey } from '@/utils/material/chapter/chapterLayout';
import ComponentGuard from '@/components/common/ComponentGuard';
import { UserTypeEnum } from '@/models/User';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';

export type SectionViewProps = {
  section: Section;
  className?: ClassNameComponent;
};

export default function SectionView({ section, className }: SectionViewProps) {
  const StartsHiddenIcon = section.startsHidden ? EyeOffIcon : EyeIcon;
  return (
    <SectionContainer className={className?.base}>
      <main className={className?.main} data-testid={mountKey(section)}>
        <header
          className={twMerge(
            'flex items-center gap-2.5 flex-wrap',
            className?.section,
          )}
        >
          <SectionTitle title={section.title} icon={section.icon} />
          {/* TODO: enable when has web socket back impl */}
          <ConditionalRenderer condition={false}>
            <ComponentGuard roles={[UserTypeEnum.TEACHER]}>
              <IconButton
                icon={<StartsHiddenIcon className="w-4 h-4 text-secondary" />}
                type="button"
              />
            </ComponentGuard>
          </ConditionalRenderer>
        </header>
        <EditorJs readOnly value={section.content} minHeight={0} />
      </main>
    </SectionContainer>
  );
}
