import { CourseStatistics } from '@/models/Course';
import attendancePercentage from './attendencePercentage';
import churnRate from './churnPercentage';

type CalculatorResult = {
  studentsCount: number;
  churnRate: number;
  attendancePercentage: number;
};

function initialCourseStatistic(): CalculatorResult {
  return {
    studentsCount: 0,
    churnRate: 0,
    attendancePercentage: 0,
  };
}

const courseCalculator = (
  courseStatistics: CourseStatistics,
): CalculatorResult => {
  const courseStatisticCalculated = initialCourseStatistic();
  const {
    activeEnrollmentsCount,
    previousActiveEnrollmentsCount,
    canceledEnrollmentsCount,
    reportsCount,
    presencesCount,
  } = courseStatistics;

  courseStatisticCalculated.studentsCount = activeEnrollmentsCount;
  courseStatisticCalculated.churnRate = churnRate({
    previousActiveEnrollments: previousActiveEnrollmentsCount,
    canceledEnrollments: canceledEnrollmentsCount,
  });

  courseStatisticCalculated.attendancePercentage = attendancePercentage({
    presences: presencesCount,
    totalReports: reportsCount,
  });

  return courseStatisticCalculated;
};

export default courseCalculator;
