import IconButton from '@/components/common/buttons/IconButton';
import Popup from '@/components/common/Popup';
import { forwardRef } from 'react';
import SectionIcon from '../view/SectionIcon';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { SectionIcons, SectionIconsType } from '@/models/Book';

const IconSelect = forwardRef<
  HTMLUListElement,
  { onChange?: (value: string) => void; value: string }
>(({ onChange, value }, ref) => {
  return (
    <Popup
      placement="bottom-end"
      offset={[10, 2]}
      reference={
        <div className="flex gap-0.5 items-center cursor-pointer">
          <IconButton
            color="custom"
            type="button"
            icon={<SectionIcon icon={value as SectionIconsType} />}
            className="flex-row-reverse gap-1"
          />
          <ChevronDownIcon className="text-base-content w-3 h-3" />
        </div>
      }
    >
      <div className="p-2.5 bg-base-100 rounded-md shadow-default border border-neutral-content">
        <ul className="flex gap-1.5 flex-col" ref={ref}>
          {Object.keys(SectionIcons).map(type => {
            return (
              <IconButton
                type="button"
                key={type}
                testId={type}
                icon={<SectionIcon icon={type as SectionIconsType} />}
                onClick={() => onChange?.(type)}
              />
            );
          })}
        </ul>
      </div>
    </Popup>
  );
});

export default IconSelect;
