import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import HeadTitle from '@/components/common/HeadTitle';
import Text from '@/components/common/dataDisplay/Text';
import Layout from '@/components/template/Layout';
import useCourseEditing from '@/data/hook/useCourseEditing';
import {
  createChapter,
  deleteChapter,
  updateChapter,
  updateChaptersOrder,
} from '@/data/services/chapterServices';
import { formatLessonNamePrefix } from '@/functions/lessonsName';
import Chapter from '@/models/Chapter';
import LoadingView from '@/pages/courses/LoadingView';
import alert from '@/utils/UseAlert';
import { Chapters } from './Chapters';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { ICoursePath } from '@/models/Course';
import ErrorComponent from '@/components/common/ErrorComponent';
import { BookErrorDispatcher } from '@/errors/book';
import { ErrorCapturer } from '@/adapters/ErrorCapturer';
import ChapterContent from './ChapterContent';
import EditMaterialHeader from './EditMaterialHeader';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import { getErrorMessage } from '@/utils/getErrorMessage';
import {
  booksQueryKeys,
  chaptersQueryKeys,
  coursePathsQueryKeys,
  lessonsQueryKeys,
} from '@/data/services/querykeys';
import { ApiError } from '@/models/Errors';

export default function EditMaterialPage() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'editMaterialPage',
  });

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { setCourseStatus } = useCourseEditing();

  const [uploadingImage, setUploadigImage] = useState(false);

  const [bookChapterId, setBookChapterId] = useState(0);
  const [chapters, setChapters] = useState<Chapter[]>([]);
  const [chapterIndex, setChapterIndex] = useState(0);

  const { lessonId, slugCourseName, bookId, chapterId } = useParams();

  const hasSlug = !!slugCourseName;

  const hasLessonId = !!lessonId && !isNaN(Number(lessonId));

  const hasBookId = !!bookId && !isNaN(Number(bookId));

  const hasChapterId = !!chapterId && !isNaN(Number(chapterId));

  const {
    data: coursePath,
    isInitialLoading: loadingCoursePath,
    error: coursePathError,
  } = useQuery({
    ...coursePathsQueryKeys.get(slugCourseName ?? ''),
    enabled: hasSlug,
  });

  const coursePathErrorDetail = getErrorMessage(coursePathError);

  const {
    data: lesson,
    isInitialLoading: loadingLesson,
    error: lessonError,
  } = useQuery({
    ...lessonsQueryKeys.get(Number(lessonId)),
    enabled: hasLessonId,
  });

  const lessonErrorDetail = getErrorMessage(lessonError);

  const { queryFn: bookQueryFn, queryKey: bookQueryKey } = booksQueryKeys.get(
    Number(bookId),
  );
  const {
    data: book,
    isInitialLoading: loadingBook,
    error: bookError,
  } = useQuery({
    queryFn: bookQueryFn,
    queryKey: bookQueryKey,
    enabled: hasBookId,
    keepPreviousData: true,
  });

  const invalidate = async () => {
    return await queryClient.invalidateQueries(bookQueryKey);
  };

  const bookErrorDetail = getErrorMessage(bookError);

  const hasLessonAndCourse = !!lesson && !!coursePath;

  const pageTitle = t('editMaterial');

  const title = hasLessonAndCourse
    ? `${pageTitle} ${formatLessonNamePrefix({
        lessonOrder: lesson?.order,
      })} - ${coursePath?.course.abbreviation}`
    : pageTitle;

  const { mutate: onCreate, isLoading: creatingChapter } = useMutation(
    createChapter,
    {
      onSuccess: async chapter => {
        alert.success(t('createChapterMessage'));
        await invalidate();

        navigate(url + chapter.id);

        setChapterIndex(chapters.length + 1);
      },
      onError: (error: any, variables) => {
        alert.error(getErrorMessage(error));

        const updateError = new BookErrorDispatcher('create', {
          lessonId: variables.lessonId,
          bookId: variables.bookId,
          apiError: new ApiError(error),
        });

        const errorCapturer = new ErrorCapturer(updateError);
        errorCapturer.dispatchError();
      },
    },
  );

  const url = `/admin/courses/${slugCourseName}/lessons/${lessonId}/books/${bookId}/chapter/`;

  const backUrl = `/admin/courses/${slugCourseName}/lessons/${courseUrl(
    coursePath,
  )}`;

  const handleAfterDelete = async (chapterId: number) => {
    const findIndex = chapters.findIndex(chapter => chapter.id === chapterId);

    alert.success(t('deletedChapterMessage'));

    if (findIndex > -1) {
      if (findIndex === chapterIndex) {
        if (findIndex === 0) {
          navigate(url + chapters[findIndex + 1].id);
        } else {
          navigate(url + chapters[findIndex - 1].id);
        }
      }
      await invalidate();
    }
  };

  const { mutate: onDelete, isLoading: deletingChapter } = useMutation(
    deleteChapter,
    {
      onSuccess: async (_, variables) =>
        await handleAfterDelete(variables.chapterId),
      onError: (error: any, variables) => {
        alert.error(getErrorMessage(error));

        const updateError = new BookErrorDispatcher('delete', {
          lessonId: variables.lessonId,
          bookId: variables.bookId,
          chapterId: variables.chapterId,
          apiError: new ApiError(error),
        });

        const errorCapturer = new ErrorCapturer(updateError);
        errorCapturer.dispatchError();
      },
    },
  );

  const { mutate: onUpdate, isLoading: updatingChapter } = useMutation(
    updateChapter,
    {
      onSuccess: async data => {
        alert.success(t('saveChapterMessage'));
        await invalidate();
        const chaptersQueryKey = chaptersQueryKeys.get({
          bookId: data.book,
          bookType: book?.bookType ?? 'STUDENT',
          chapterId: data.id,
          lessonId: lesson?.id ?? 0,
        });
        queryClient.invalidateQueries(chaptersQueryKey);
      },
      onError: (error: any, variables) => {
        const { params } = variables;
        alert.error(getErrorMessage(error));

        const updateError = new BookErrorDispatcher('update', {
          lessonId: params.lessonId,
          bookId: params.bookId,
          chapterId: params.chapterId,
          apiError: new ApiError(error),
        });

        const errorCapturer = new ErrorCapturer(updateError);
        errorCapturer.dispatchError();
      },
    },
  );

  const { mutate: onUpdateOrder, isLoading: updatingOrder } = useMutation(
    updateChaptersOrder,
    {
      onSuccess: async () => {
        alert.success(t('saveOrderMessage'));
        await invalidate();
      },
      onError: (error: any, variables) => {
        const { params } = variables;
        alert.error(getErrorMessage(error));

        const updateError = new BookErrorDispatcher('updateOrder', {
          lessonId: params.lessonId,
          bookId: params.bookId,
          apiError: new ApiError(error),
        });

        const errorCapturer = new ErrorCapturer(updateError);
        errorCapturer.dispatchError();
      },
    },
  );

  const updatingBookChapter =
    creatingChapter || updatingChapter || updatingOrder || deletingChapter;

  const updatingBook = updatingBookChapter || loadingBook || uploadingImage;

  const loading = loadingCoursePath || loadingLesson || loadingBook;

  const errorDetail =
    coursePathErrorDetail || lessonErrorDetail || bookErrorDetail;

  useEffect(() => {
    if (coursePath) {
      setCourseStatus(coursePath.status);
    }
  }, [coursePath, setCourseStatus]);

  useEffect(() => {
    if (book) {
      const { chapters } = book;
      setChapters(chapters);

      if (chapters.length) {
        if (hasChapterId) {
          const findChapter = chapters.find(
            chapter => chapter.id === Number(chapterId),
          );

          const findIndex = chapters.findIndex(
            chapter => chapter.id === Number(chapterId),
          );

          if (findChapter && findIndex > -1) {
            setBookChapterId(findChapter.id);
            setChapterIndex(findIndex);
          }
        } else {
          const chapter = chapters[chapterIndex];
          setBookChapterId(chapter.id);
        }
      }
    }
    return () => setBookChapterId(0);
  }, [book, chapterId, chapterIndex, chapters, hasChapterId]);

  if (loading) {
    return (
      <LayoutContainer>
        <LoadingView />
      </LayoutContainer>
    );
  }

  if (errorDetail) {
    return (
      <LayoutContainer>
        <ErrorComponent errorTextTitle={errorDetail} />
      </LayoutContainer>
    );
  }

  if (coursePath && lesson && book) {
    return (
      <LayoutContainer>
        <Text
          text={pageTitle}
          format="poppins-600"
          className="text-primary text-24"
        />

        <ConditionalRenderer condition={loadingBook}>
          <LoadingView />
        </ConditionalRenderer>

        <ConditionalRenderer condition={bookErrorDetail}>
          <Text
            text={bookErrorDetail}
            format="rubik-500"
            className="text-primary text-24"
          />
        </ConditionalRenderer>

        <ConditionalRenderer condition={book}>
          <HeadTitle routeInfo={title} />
          <div className="flex flex-col gap-3.5">
            <EditMaterialHeader
              lesson={lesson}
              loading={loadingLesson}
              backUrl={backUrl}
            />
            <div className="flex w-full gap-4">
              <ChapterContent
                lessonId={lesson?.id}
                book={book}
                chapterId={bookChapterId}
                requesting={updatingBook}
                setUploadigImage={setUploadigImage}
                onUpdate={onUpdate}
                backUrl={backUrl}
                invalidate={invalidate}
              />

              <Chapters
                editMode
                chapterIndex={chapterIndex}
                setChapterIndex={setChapterIndex}
                chapters={chapters}
                updatingBook={updatingBook}
                onCreate={onCreate}
                onDelete={onDelete}
                onUpdateOrder={onUpdateOrder}
                book={book}
                lessonId={lesson.id}
                courseSlug={coursePath.slug}
              />
            </div>
          </div>
        </ConditionalRenderer>
      </LayoutContainer>
    );
  }

  return (
    <LayoutContainer>
      <ErrorComponent />
    </LayoutContainer>
  );
}

type LayoutContainerProps = {
  children: ReactNode;
};

function LayoutContainer({ children }: LayoutContainerProps) {
  return <Layout className="overflow-visible">{children}</Layout>;
}

function courseUrl(coursePath?: ICoursePath) {
  if (coursePath) {
    switch (coursePath.status) {
      case VersioningStatusEnum.VERSIONING:
        return coursePath.version === 1.0 ? 'draft' : 'versioning';
      case VersioningStatusEnum.EDITING:
        return 'editing';
      default:
        return undefined;
    }
  }
}
