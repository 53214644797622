import { useState } from 'react';
import { bookType } from '@/models/Book';
import CoursePath from '@/models/Course';
import { Lesson } from '@/models/Lesson';
import LoadingView from '@/pages/courses/LoadingView';
import MaterialMode from './MaterialMode';
import ViewMaterial from './ViewMaterial';
import { Player } from '@lottiefiles/react-lottie-player';
import Text from '@/components/common/dataDisplay/Text';
import { useTranslation } from 'react-i18next';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { useQuery } from '@tanstack/react-query';
import { booksQueryKeys } from '@/data/services/querykeys';
import BookService from '@/components/common/material/slides/book/BookService';
import { useSingleQuery } from '@/data/hook/query/useGetManualQuery';
import { useMaterialVersioning } from './Material.hooks';

interface MaterialContainerProps {
  course?: CoursePath;
  lesson: Lesson;
  updateLessons?: () => Promise<void>;
  bookType: bookType;
  readOnly?: boolean;
}

export default function MaterialContainer({
  lesson,
  updateLessons,
  bookType,
  course,
  readOnly,
}: MaterialContainerProps) {
  const isStudentBook = bookType === 'STUDENT';
  const bookId = isStudentBook ? lesson.book : lesson.teacherBook;
  const [isUploadingChapters, setIsUploadingChapters] = useState(false);
  const { t } = useTranslation('translation', {
    keyPrefix: 'adminPage.lesson',
  });

  const { data: book, isInitialLoading: loadingBook } = useQuery({
    ...booksQueryKeys.get(Number(bookId)),
    enabled: !!bookId,
    structuralSharing: false,
    refetchInterval(book, query) {
      const count = query.state.dataUpdateCount;
      if (!book || book.chapters.length || count >= 7) {
        setIsUploadingChapters(false);
        return false;
      }
      if (!isUploadingChapters) {
        setIsUploadingChapters(true);
      }
      return 5000;
    },
  });

  const { onEditBook, isLoading: isEditing } = useMaterialVersioning({
    lesson: lesson,
    book: book,
    course: course,
  });

  const { setData } = useSingleQuery(booksQueryKeys.get(Number(bookId)));

  if (loadingBook) {
    return <LoadingView />;
  }

  if (isUploadingChapters) {
    return (
      <div className="flex flex-col gap-4">
        <Text
          text={t('addMaterial.waitComplete')}
          as="h4"
          className="text-primary"
        />
        <Player
          className="w-full md:w-[80%]"
          autoplay
          loop
          src="/images/ongoing-construction.json"
        />
      </div>
    );
  }

  if (book?.chapters[0]?.content) {
    return (
      <ViewMaterial
        book={book}
        course={course}
        lesson={lesson}
        bookType={bookType}
        readOnly={readOnly}
        onEditBook={onEditBook}
        isEditing={isEditing}
        updateLessons={updateLessons}
      />
    );
  }

  if (book)
    return (
      <BookService
        key={book.id}
        book={book}
        readOnly
        isEditing={isEditing}
        bookType={book.bookType}
        bookId={book.id}
        lessonId={lesson.id}
        onSubmit={setData}
        onDeleteBook={updateLessons}
        onEditBook={onEditBook}
      />
    );

  return (
    <ConditionalRenderer condition={course && !readOnly}>
      {course && (
        <MaterialMode
          course={course}
          lesson={lesson}
          updateLessons={updateLessons}
          bookType={bookType}
          isLoadingContainer={loadingBook}
        />
      )}
    </ConditionalRenderer>
  );
}
