import { REQUEST_STALE_TIME_1_DAY_IN_MS } from '@/constants';
import useListService from '@/data/hook/useListService';
import {
  areasQueryKeys,
  studentTagScoreQueryKeys,
} from '@/data/services/querykeys';
import { ListStudentTagScoreParams } from '@/data/services/studentTagScoreServices';
import Area from '@/models/Area';
import { StudentTagScore } from '@/models/StudentTagScore';
import { useQueries, UseQueryResult } from '@tanstack/react-query';

export type AreaScore = {
  area: Area;
  tagScore: StudentTagScore;
};

export function useAreaScore(
  filters: ListStudentTagScoreParams = {},
  enabled: boolean = true,
) {
  const { results: tagScores, isInitialLoading: isLoading } = useListService({
    ...studentTagScoreQueryKeys.list(filters),
    staleTime: REQUEST_STALE_TIME_1_DAY_IN_MS,
    enabled,
  });
  const areasQueries = useQueries({
    queries: tagScores.map(({ areaId }) => ({
      ...areasQueryKeys.get(areaId),
      staleTime: Infinity,
      enabled: !!areaId,
    })),
  });
  const areas = areasQueries.reduce(createAreaList, []);

  function joinAreaTagScore(
    areaScores: AreaScore[],
    tagScore: StudentTagScore,
  ): AreaScore[] {
    const area = areas.find(({ id }) => tagScore.areaId === id);
    if (area) {
      areaScores.push({ area, tagScore });
    }
    return areaScores;
  }
  const isLoadingAreas = areasQueries.some(({ isLoading }) => isLoading);
  const areaScores = isLoadingAreas
    ? []
    : tagScores.reduce(joinAreaTagScore, []);

  return { areaScores, isLoading, isLoadingAreas };
}

function createAreaList(
  areaList: Area[],
  queryResult: UseQueryResult<Area, unknown>,
) {
  return queryResult.data ? [...areaList, queryResult.data] : areaList;
}
