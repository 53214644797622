import { Fragment, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Controls from '@/components/common/Controls';
import ErrorComponent from '@/components/common/ErrorComponent';
import ElementPreviewFactory from '@/components/activities/elements/preview/ElementPreviewFactory';
import useActivityView from '@/data/hook/useActivityView';
import HeadTitle from '@/components/common/HeadTitle';
import { makeLessonHeadTitle } from '@/utils/lessonHeadTitle';
import useLessonContext from '@/data/hook/lesson';
import useActivities from '@/data/hook/useActivities';
import LessonViewContainer from '@/components/lessons/LessonViewContainer';
import Steps, { Step, StepStatus } from '@/components/common/Steps/Steps';
import getKlassViewBaseUrl from '@/utils/getKlassViewBaseUrl';
import { LoadingIcon } from '../icons';
import { STAFF_CURRENT_QUESTION } from '@/constants';
import {
  correctAnswersQueryKeys,
  coursePathsQueryKeys,
} from '@/data/services/querykeys';

export default function AdminStepperContainer() {
  const { activeStep, setActiveStep } = useActivityView();
  const { lesson, scheduledLesson } = useLessonContext();
  const {
    activityId: activityIdParam,
    slugCourseName = '',
    lessonId,
    klassId,
    resourceId,
  } = useParams();
  const { t } = useTranslation('translation', { keyPrefix: 'lesson.activity' });
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const activityId = Number(activityIdParam);

  const hasActivityId = !isNaN(activityId);

  const { activities } = useActivities({
    activityIds: hasActivityId ? [activityId] : [],
  });

  const activity = activities.length ? activities[0] : undefined;

  const { data: answers } = useQuery({
    ...correctAnswersQueryKeys.get(activityId),
    enabled: hasActivityId,
  });

  const { data: coursePath } = useQuery({
    ...coursePathsQueryKeys.get(slugCourseName),
  });

  const title = `${activity?.data?.name ?? t('titleHeader')} - ${t(
    'titleHeader',
  )} - ${makeLessonHeadTitle({
    lessonOrder: lesson?.order || 0,
    coursePath,
    lessonType: scheduledLesson?.type,
  })}`;

  type ActivitySession = {
    activityId: number;
    index: number;
  };

  const session = sessionStorage.getItem(STAFF_CURRENT_QUESTION);

  const onClickStep = useCallback(
    (index: number) => {
      if (!activityId) throw new Error('activity not found');

      sessionStorage.setItem(
        STAFF_CURRENT_QUESTION,
        JSON.stringify({ activityId: Number(activityId), index }),
      );

      setActiveStep(index);
    },
    [activityId, setActiveStep],
  );

  useEffect(() => {
    if (session) {
      const activitySession = JSON.parse(session) as ActivitySession;

      if (hasActivityId && activitySession.activityId !== activityId) {
        onClickStep(0);
      }
    } else onClickStep(0);
  }, [activityId, hasActivityId, onClickStep, session]);

  function close() {
    const isResourceActivity = pathname.includes('resources');

    navigate(
      `${getKlassViewBaseUrl({
        slugCourseName,
        lessonId: Number(lessonId),
        klassId: Number(klassId),
      })}${isResourceActivity ? `/resources/${resourceId}` : '/activities'}`,
    );

    window.scroll({ top: 0, behavior: 'smooth' });
  }

  function onNavigation(direction: 1 | -1) {
    let nextStep = activeStep ? activeStep + direction : 1;
    onClickStep(nextStep);

    window.scroll({ top: 0, behavior: 'smooth' });
    setActiveStep(nextStep);
  }

  if (activity?.status === 'loading' || activity?.isPlaceholderData) {
    return (
      <div className="flex w-full justify-center h-72">
        <LoadingIcon className="w-40 text-primary/40" />
      </div>
    );
  }

  if (activity?.status === 'error') {
    return (
      <Fragment>
        <HeadTitle routeInfo={title} />
        <ErrorComponent
          errorTextTitle={t('notFoundTitle')}
          errorTextSubTitle={t('notFoundSubtitle')}
        />
      </Fragment>
    );
  }

  if (activity?.data?.questions?.length) {
    const question = activity.data.questions[activeStep];

    if (question) {
      const questionAnswer = answers?.questions?.find(
        ({ order, type }) => order === question.order && question.type === type,
      );

      const stepStatus = (index: number): StepStatus => {
        if (index < activeStep) return 'done';
        if (index === activeStep) return 'active';
        return 'todo';
      };

      const steps = activity.data.questions.map<Step>((question, index) => {
        return {
          id: question.order,
          status: stepStatus(index),
          onClick: target => onClickStep(target),
        };
      });

      return (
        <Fragment>
          <HeadTitle routeInfo={title} />
          <LessonViewContainer className="flex flex-col gap-4" close={close}>
            <Steps steps={steps} activeStep={activeStep} />
            <ElementPreviewFactory
              activityMode="answerkey"
              isAdmin
              activityId={Number(activityId)}
              answer={questionAnswer}
              {...{
                ...question,
                isDone: true,
                progressId: 0,
                questionId: question.id,
                isCurrentQuestion: false,
              }}
            />
            <Controls
              isOnLast={activeStep === steps.length - 1}
              hidePrev={activeStep === 0}
              nextText={t('activityNext')}
              prevText={t('activityPrev')}
              buttonColor="text-primary"
              goNext={() => onNavigation(1)}
              goBack={() => onNavigation(-1)}
              onFinish={close}
              isNextRequest={false}
              isPrevRequest={false}
            />
          </LessonViewContainer>
        </Fragment>
      );
    }
  }

  return <Fragment />;
}
