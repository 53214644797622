import { isSuperAdmin } from '@/functions/auth';
import useAuth from './useAuth';

type ConectaHook = {
  studentUnitIds?: number[];
  unitKlassId?: number;
};

export function useConecta(props: ConectaHook) {
  const { user } = useAuth();

  const memberUnitIds = user?.unitsIds ?? [];

  const { studentUnitIds = [], unitKlassId = 0 } = props;

  const isConectaStudent = studentUnitIds.some(
    unit => !memberUnitIds.includes(unit),
  );

  const isConectaStudentSeat = !studentUnitIds.includes(unitKlassId);

  return {
    isConectaStudent: isSuperAdmin(user?.userType) ? false : isConectaStudent,
    isConectaStudentSeat: isSuperAdmin(user?.userType)
      ? false
      : isConectaStudentSeat,
  };
}
