import { SaveIcon, XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import MainButton from './MainButton';
import { twMerge } from 'tailwind-merge';

export interface Button {
  onClick?: () => void;
  text?: string;
  icon?: React.ReactElement;
  className?: string;
  testId?: string;
  disabled?: boolean;
  type?: 'reset' | 'button' | 'submit' | undefined;
}

export default function SaveCancelGroup({
  save,
  cancel,
  loading,
  disable,
  className,
}: {
  cancel?: Button;
  save?: Button;
  loading?: boolean;
  disable?: boolean;
  className?: string;
}) {
  const { t } = useTranslation();
  return (
    <span className={twMerge('flex self-end gap-x-2.5', className)}>
      <MainButton
        type="button"
        dataTestId={cancel?.testId}
        onClick={cancel?.onClick}
        icon={cancel?.icon || <XIcon />}
        color="neutral"
        text={cancel?.text || t('manageCourse.addCourse.cancelButton')}
        disabled={cancel?.disabled || disable}
        className={cancel?.className}
      />

      <MainButton
        type={save?.type ?? 'submit'}
        dataTestId={save?.testId}
        onClick={save?.onClick}
        icon={save?.icon || <SaveIcon />}
        loading={loading}
        disabled={save?.disabled || disable}
        className={save?.className}
        text={save?.text || t('manageCourse.addCourse.saveButton')}
      />
    </span>
  );
}
