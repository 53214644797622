import LineCard, { LineCardColors } from '../common/LineCard';
import Text from '../common/dataDisplay/Text';
import ConditionalRenderer from '../common/ConditionalRenderer';
import ConditionalWrapper from '../common/ConditionalWrapper';
import { ClassNameComponent } from '@/utils/ClassNameComponent';
import { twMerge } from 'tailwind-merge';
import { DifficultyStage } from '@/models/StudentTagScore';
import StageTag from './StageTag';

type AreaLineCardProps = {
  title: string;
  color?: LineCardColors;
  score?: number;
  onClick?(): void;
  className?: Pick<ClassNameComponent, 'base' | 'button' | 'tag'>;
  difficultyStage?: DifficultyStage;
};

export default function AreaLineCard({
  title,
  score,
  color = 'base',
  onClick,
  className,
  difficultyStage,
}: AreaLineCardProps) {
  return (
    <ConditionalWrapper
      condition={!!onClick}
      wrapper={
        <button
          className={twMerge(
            'w-full transition-all hover:opacity-80 active:scale-[0.98] duration-150 ease-in-out',
            className?.button,
          )}
          onClick={onClick}
        />
      }
    >
      <LineCard
        className={twMerge('items-center justify-between', className?.base)}
        color={color}
      >
        <Text text={title} format="rubik-500" size="text-16" />
        <div className="flex items-center gap-4">
          <StageTag
            difficultyStage={difficultyStage}
            className={className?.tag}
          />
          <ConditionalRenderer condition={typeof score === 'number'}>
            <Text text={`${score} xp`} format="rubik-500" size="text-16" />
          </ConditionalRenderer>
        </div>
      </LineCard>
    </ConditionalWrapper>
  );
}
