import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EyeIcon } from '@heroicons/react/outline';
import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';

import Text from '@/components/common/dataDisplay/Text';
import {
  ColorTypes,
  Rating,
  RatingTypes,
} from '@/components/common/dataDisplay/Rating';
import {
  AssignmentType,
  HomeworkActivityProgress,
} from '@/models/HomeworkActivity';
import IconButton from '@/components/common/buttons/IconButton';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import Modal from '@/components/common/modals/Modal';
import { HtmlPreview } from '@/components/common/dataDisplay/HtmlPreview';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import RewardInput from './RewardInput';
import { ReportFields } from '../scheduledLessonReport/StudentReportForm';
import { GradeEnum } from '@/models/ScheduledLessonReport';

interface StudentRatingReportProps {
  homeworkProgress?: HomeworkActivityProgress;
  control: Control<ReportFields>;
  rewardAmount: number;
  locked?: boolean;
  setValue: UseFormSetValue<ReportFields>;
}

export function StudentRatingReport({
  homeworkProgress,
  control,
  rewardAmount,
  locked,
  setValue,
}: StudentRatingReportProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { notDone } = useWatch({
    control,
  });

  const setAllValues = (value: number) => {
    setValue('effort', value);
    setValue('challenge', value);
    setValue('behavior', value);
    setValue('homeworkGrade', value);
    setValue('allValues', value);
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });
  const defaultspanClassname =
    'flex gap-1 justify-between lg:justify-start md:flex-col w-full ';

  function handleNotDone(value?: number) {
    if (value === 0 || !value) {
      setValue('notDone', true);
    } else {
      setValue('notDone', false);
    }
  }

  return (
    <div className="grid md:grid-cols-2 items-center gap-2">
      {homeworkProgress && (
        <HomeworkSubmissionModal
          homeworkProgress={homeworkProgress}
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
        />
      )}
      {!homeworkProgress ? (
        <Text
          testId="notHomeworkPhrase"
          text={t('notHomeworkPhrase')}
          format="rubik-400"
          className="text-start select-none"
          size="text-14"
        />
      ) : (
        <span className={defaultspanClassname}>
          <div className="flex gap-2 items-center">
            <Text
              testId="homeworkText"
              text={t('homework')}
              format="rubik-400"
              className="text-start select-none"
            />
            {homeworkProgress.homework.assignmentType !==
              AssignmentType.NOTHING && (
              <Tooltip text={t('seeHomework')}>
                <IconButton
                  type="button"
                  onClick={() => setIsModalVisible(true)}
                  icon={<EyeIcon className="w-4 text-primary" />}
                />
              </Tooltip>
            )}
          </div>
          <div className="flex items-center gap-1.5">
            <Controller
              control={control}
              name="homeworkGrade"
              render={({ field: { value, onChange } }) => (
                <Rating
                  testId="homework"
                  type={RatingTypes.BOOK}
                  color={ColorTypes.PURPLE}
                  length={5}
                  value={notDone ? 0 : value || 0}
                  isDisabled={notDone}
                  onClickRating={value => {
                    onChange(value);
                    handleNotDone(value);
                  }}
                  className={`${notDone ? 'opacity-25' : ''}`}
                />
              )}
            />
            <div className="form-control">
              <label className="cursor-pointer label">
                <Controller
                  control={control}
                  name="notDone"
                  render={({ field: { value, onChange, ref, onBlur } }) => (
                    <input
                      ref={ref}
                      disabled={!!homeworkProgress.answer}
                      type="checkbox"
                      checked={value}
                      className="checkbox checkbox-primary checkbox-xs rounded-sm mr-1"
                      onChange={e => {
                        onChange(e.target.checked);
                        if (e.target.checked) {
                          setValue('homeworkGrade', GradeEnum.ZERO);
                        }
                      }}
                      onBlur={onBlur}
                    />
                  )}
                />
                <Text text={t('notDone')} className="label-text text-14" />
              </label>
            </div>
          </div>
        </span>
      )}
      <span className={defaultspanClassname}>
        <ConditionalRenderer
          condition={!locked}
          fallback={
            <Text
              text={t('notCtrlCashPhrase')}
              format="rubik-400"
              className="text-end select-none self-end"
              size="text-14"
            />
          }
        >
          <span className="flex items-center gap-2.5 justify-end">
            <Tooltip text={t('cashMessage')}>
              <Text text="Ctrl+Cash" />
            </Tooltip>
            <Controller
              control={control}
              defaultValue={rewardAmount}
              name="presenceReward"
              render={({ field: { value, onChange } }) => {
                const valueNotNull = value || 0;
                return (
                  <RewardInput
                    className="text-start select-none self-end"
                    lessCoins={() => {
                      if (valueNotNull && valueNotNull !== 1) {
                        const newValue = valueNotNull - 1;
                        onChange(newValue);
                      }
                    }}
                    moreCoins={() => {
                      if (valueNotNull && valueNotNull !== rewardAmount) {
                        const newValue = valueNotNull + 1;
                        onChange(newValue);
                      }
                    }}
                    coins={valueNotNull}
                    maxCoins={rewardAmount}
                  />
                );
              }}
            />
          </span>
        </ConditionalRenderer>
      </span>
      <span className={defaultspanClassname}>
        <Text
          text={t('allValues')}
          format="rubik-400"
          className="text-start select-none "
        />
        <Controller
          control={control}
          name="allValues"
          render={({ field: { value, onChange } }) => {
            return (
              <Rating
                type={RatingTypes.LIKE}
                color={ColorTypes.GREEN}
                length={5}
                value={value || 0}
                onClickRating={value => {
                  setAllValues(value);
                  setValue('notDone', value > 0 ? false : true);
                  onChange(value);
                }}
              />
            );
          }}
        />
      </span>
      <span className={defaultspanClassname}>
        <Text
          testId="practicalChallengeText"
          text={t('practicalChallenge')}
          format="rubik-400"
          className="text-start select-none self-end"
        />
        <Controller
          control={control}
          name="challenge"
          render={({ field: { value, onChange } }) => (
            <Rating
              testId="challenge"
              className="self-end"
              type={RatingTypes.LIGHTNING}
              color={ColorTypes.ACCENT}
              length={5}
              value={value || 0}
              onClickRating={onChange}
            />
          )}
        />
      </span>
      <span className={defaultspanClassname}>
        <Text
          testId="effortText"
          text={t('effort')}
          format="rubik-400"
          className="text-start select-none "
        />
        <Controller
          control={control}
          name="effort"
          render={({ field: { value, onChange } }) => (
            <Rating
              testId="effort"
              type={RatingTypes.STAR}
              color={ColorTypes.YELLOW}
              length={5}
              value={value || 0}
              onClickRating={onChange}
            />
          )}
        />
      </span>
      <span className={defaultspanClassname}>
        <Text
          testId="behaviorText"
          text={t('behavior')}
          format="rubik-400"
          className="text-start select-none self-end"
        />
        <Controller
          control={control}
          name="behavior"
          render={({ field: { value, onChange } }) => (
            <Rating
              testId="behavior"
              className="self-end"
              type={RatingTypes.HAPPY}
              color={ColorTypes.BLUE}
              length={5}
              value={value || 0}
              onClickRating={onChange}
            />
          )}
        />
      </span>
    </div>
  );
}

interface HomeworkSubmissionModalProps {
  visible: boolean;
  homeworkProgress: HomeworkActivityProgress;
  onClose(): void;
}

const HomeworkSubmissionModal: React.FC<HomeworkSubmissionModalProps> = ({
  homeworkProgress,
  onClose,
  visible,
}) => {
  const answer = homeworkProgress.answer;
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });
  return (
    <Modal visible={visible} onClose={onClose}>
      <div className="flex flex-col gap-5">
        <Text
          size="text-18"
          text={t('submittion')}
          format="rubik-500"
          className="self-center text-primary"
        />
        {answer ? (
          <HtmlPreview html={answer} />
        ) : (
          <Text text={t('noSubmittion')} />
        )}
      </div>
    </Modal>
  );
};
